import {
  Burger,
  Group,
  Header as MHeader,
  Popover,
  UnstyledButton,
  Image,
} from "@mantine/core";
import { Text } from "../";
import { useState } from "react";
import { useRecoilState } from "recoil";
import {
  ButtonAccount,
  ButtonTheme,
  DrawerSettings,
  navBarToggleAtom,
} from "../";
import useStyles from "./HeaderStyles";
import { Versions } from "./_versions";
import { useThemeColors } from "../../hooks/useThemeColors";

export const Header = () => {
  const { classes } = useStyles();
  const [navBarToggle, SetNavbarToggle] = useRecoilState(navBarToggleAtom);
  const [versOpened, setVersOpened] = useState(false);
  const { headerIconColor } = useThemeColors();

  const title = navBarToggle ? "Close navigation" : "Open navigation";

  const handleBurgerClick = (event: any) => {
    SetNavbarToggle(!navBarToggle);
  };

  return (
    <MHeader height={50} className={classes.header}>
      <Group className={classes.leftSection} spacing="xs">
        <Group position="left">
          <Burger
            opened={navBarToggle}
            onClick={handleBurgerClick}
            size="sm"
            title={title}
            color={headerIconColor}
            data-testid=":Header-BurgerMenu"
          />
          <Popover
            opened={versOpened}
            onClose={() => setVersOpened(false)}
            width={260}
            position="bottom"
            withArrow
          >
            <Popover.Target>
              <UnstyledButton onClick={() => setVersOpened((o) => !o)}>
                <Image
                  src="/foundry-logo-header.svg"
                  width={25}
                  data-testid=":Icon-mainHeaderTitleIcon"
                />
              </UnstyledButton>
            </Popover.Target>
            <Popover.Dropdown>
              <Versions />
            </Popover.Dropdown>
          </Popover>
          <Text
            weight={700}
            style={{ fontFamily: "Greycliff CF, sans-serif" }}
            testid="mainHeaderTitle"
          >
            Promo Exchange
          </Text>
        </Group>
      </Group>
      <Group className={classes.leftSection} spacing="xs">
        <ButtonTheme testid="mainHeaderButtonTheme" />
        <DrawerSettings testid="mainHeaderButtonTheme" />
        <ButtonAccount testid="mainHeaderButtonAccount" />
      </Group>
    </MHeader>
  );
};
