import { useEffect } from "react";
import * as xmlToJson from "xml-js";
import { b64toBlob } from "./b64toBlob";
import { mapToPolicyCheckResult } from "./mediaconchHelper";
import { getMediaconchProfile } from "../../services/mediaconchProfile.service";
import { useRecoilValue } from "recoil";
import {
  simulatedDragDropAtom,
} from "../../recoil/bulkupload/atoms";
import { configAtom } from "../../app/ConfigRecoil";

let checker = {};
let getJsonMetadata = {};
let parseFile = {};
let parseFileWithAsperaBuffer = {};
let checkPolicyWithAsperaBuffer = {};

export const useMediaconch = (
  asperaWeb,
  setLoadingNeededData,
  setUnexpectedError
) => {
  const MediaConchJs = document.createElement("script");
  const MediaInfoJs = document.createElement("script");
  const PolicyCheckerJs = document.createElement("script");
  const IMSC1PluginJs = document.createElement("script");
  const simulatedDragDrop = useRecoilValue(simulatedDragDropAtom);
  const config = useRecoilValue(configAtom);

  useEffect(() => {
    const getUserProfile = async () => {
      const devMediaconchProfileMode = config.devMediaconchProfileMode;
      const profile = await getMediaconchProfile(devMediaconchProfileMode);
      return profile.data;
    };

    if (asperaWeb) {
      MediaConchJs.src = "/mediaconch/MediaConch.js";
      // MediaInfo JS
      if ("WebAssembly" in window) {
        MediaInfoJs.src = "/mediaconch/MediaInfoWasm.js";
        PolicyCheckerJs.src = "/mediaconch/PolicyCheckerWasm.js";
        IMSC1PluginJs.src = "/mediaconch/IMSC1PluginWasm.js";
      } else {
        MediaInfoJs.src = "/mediaconch/MediaInfo.js";
        PolicyCheckerJs.src = "/mediaconch/PolicyChecker.js";
        IMSC1PluginJs.src = "/mediaconch/IMSC1Plugin.js";
      }

      document.body.appendChild(MediaInfoJs);

      MediaInfoJs.onload = function () {
        document.body.appendChild(PolicyCheckerJs);

        // eslint-disable-next-line no-undef
        let MediaInfoModule = MediaInfoLib({
          postRun: function () {
            if (
              typeof Promise !== "undefined" &&
              MediaInfoModule instanceof Promise
            ) {
              MediaInfoModule.then(function (module) {
                MediaInfoModule = module;
              });
            } else {
              console.log("ERROR creating MediaInfoModule");
            }
          },
        });

        let MI,
          processing = false,
          CHUNK_SIZE = 1024 * 1024;

        const getReadSize = function(file, offset) {
          let readSize;
          if (file.size - offset > CHUNK_SIZE) {
            readSize = CHUNK_SIZE;
          } else {
            readSize = file.size - offset;
          }

          if (readSize <= 0) {
            readSize = CHUNK_SIZE;
          }

          return readSize;
        }

        const finish = function () {
          MI.Close();
          MI.delete();
          processing = false;
        };

        getJsonMetadata = function () {
          MI.Option("Complete", "1");
          MI.Option("Inform", "JSON");
          return JSON.parse(MI.Inform());
        };

        parseFile = function (file) {
          return new Promise(function (resolve, reject) {
            if (processing) {
              return;
            }
            processing = true;
            var offset = 0;

            // Initialize MediaInfo
            MI = new MediaInfoModule.MediaInfo();

            // Init buffer
            MI.Option("File_FileName", file.name);
            MI.Open_Buffer_Init(file.size, 0);

            var loop = function (length) {
              if (processing) {
                var r = new FileReader();
                var blob = file.slice(offset, offset + length);
                r.onload = processChunk;
                r.readAsArrayBuffer(blob);
              } else {
                finish();
              }
            };

            var processChunk = function (e) {
              if (e.target.error === null) {
                // Send the buffer to MediaInfo
                var state = MI.Open_Buffer_Continue(e.target.result);

                //Test if there is a MediaInfo request to go elsewhere
                var seekTo = MI.Open_Buffer_Continue_Goto_Get();
                if (seekTo === -1) {
                  offset += e.target.result.byteLength;
                } else {
                  offset = seekTo;
                  MI.Open_Buffer_Init(file.size, seekTo); // Inform MediaInfo we have seek
                }
              } else {
                finish();
                reject("An error happened reading your file!");
                return;
              }

              // Bit 3 set means finalized
              if (state & 0x08 || e.target.result.byteLength < 1) {
                MI.Open_Buffer_Finalize();
                processing = false;
                resolve();
                return;
              }

              loop(CHUNK_SIZE);
            };

            // Start
            loop(CHUNK_SIZE);
          });
        };

        parseFileWithAsperaBuffer = async function (file) {
          return new Promise(function (resolve, reject) {
            if (processing) {
              return;
            }
            processing = true;
            let offset = 0;

            // Initialize MediaInfo
            MI = new MediaInfoModule.MediaInfo();

            // Init buffer
            MI.Option("File_FileName", file.name);
            MI.Open_Buffer_Init(file.size, 0);

            const loop = async function () {
              if (processing) {
                const reader = new FileReader();

                const readSize = getReadSize(file, offset);

                //Aspera code
                const asperaOptions = {
                  path: file.name,
                  offset: offset,
                  chunkSize: readSize,
                };
                let encodedData = await asperaWeb.readChunkAsArrayBuffer(
                  asperaOptions
                );
                const blob = b64toBlob(encodedData.data, encodedData.type);

                //Process chunk
                reader.onload = processChunk;
                reader.readAsArrayBuffer(blob);
              } else {
                finish();
              }
            };

            const processChunk = function (e) {
              if (e.target.error === null) {
                // Send the buffer to MediaInfo
                var state = MI.Open_Buffer_Continue(e.target.result);

                //Test if there is a MediaInfo request to go elsewhere
                let seekTo = MI.Open_Buffer_Continue_Goto_Get();
                if (seekTo === -1) {
                  offset += e.target.result.byteLength;
                } else {
                  offset = seekTo;
                  MI.Open_Buffer_Init(file.size, seekTo); // Inform MediaInfo we have seek
                }
              } else {
                finish();
                reject("An error happened reading your file!");
                return;
              }

              // Bit 3 set means finalized
              if (state & 0x08 || e.target.result.byteLength < 1) {
                MI.Open_Buffer_Finalize();
                processing = false;
                resolve();
                return;
              }

              loop(CHUNK_SIZE);
            };

            // Start
            loop(CHUNK_SIZE);
          });
        };

        PolicyCheckerJs.onload = function () {
          document.body.appendChild(MediaConchJs);

          MediaConchJs.onload = function () {
            // eslint-disable-next-line no-undef
            checker = new MediaConchPolicyChecker();
            checker
              .init()
              .then(function () {
                // Activate profile policy
                const activateProfilePolicy = async function () {
                  try {
                    const profile = await getUserProfile();
                    addPolicy(profile.mediaConchProfile);
                  } catch (error) {
                    setLoadingNeededData(false);
                    setUnexpectedError(true);
                  }
                };

                // Add policy
                const addPolicy = function (policy) {
                  const id = checker.addPolicy(policy);
                  if (id !== null) {
                    listActivePolicies();
                  } else {
                    console.log("addPolicy ERROR");
                  }
                };

                // List active policies
                const listActivePolicies = function () {
                  let policies = "";
                  checker.listPolicies().forEach(function (policy) {
                    policies += `PolicyID:${policy.id} - Name:${policy.name}`;
                  });
                  console.log(policies);
                  setLoadingNeededData(false);
                };

                // File check function
                checkPolicyWithAsperaBuffer = async function (file, workaroundFiles) {
                  return new Promise(function (resolve, reject) {
                    let offset = 0;

                    const normalizedFileName = file.name.replaceAll('\\', '/');
                    const fileName = normalizedFileName.substring(normalizedFileName.lastIndexOf('/') + 1);

                    let htmlFile;

                    for (let i = 0; i < workaroundFiles.length; i++) {
                      let f = workaroundFiles[i];
                      if (f.name === fileName) {
                        htmlFile = f;
                      }
                    }

                    if (htmlFile) {
                      checker.checkBufferInit(htmlFile.size, 0, htmlFile.name);
                    } else {
                      checker.checkBufferInit(file.size, 0, file.name);
                    }

                    const loop = async function () {
                      var r = new FileReader();

                      let blob;

                      if (htmlFile) {
                        /* This part is a workaround until the aspera bug is resolved
                        https://github.com/IBM/aspera-connect-sdk-js/issues/49
                        The workaround is to handle a second drag and drop event in a div surrounding the Aspera drag and drop
                        area and parse through the files dropped from that event instead of the Aspera files.
                        */
                        blob = htmlFile.slice(offset, offset + CHUNK_SIZE);
                      } else {
                        const readSize = getReadSize(file, offset);

                        //Aspera code
                        const asperaOptions = {
                          path: file.name,
                          offset: offset,
                          chunkSize: readSize,
                        };
                        let encodedData = await asperaWeb.readChunkAsArrayBuffer(
                            asperaOptions
                        );
                        blob = b64toBlob(
                            encodedData.data,
                            encodedData.type
                        );
                      }
                      
                      r.onload = processChunk;
                      r.readAsArrayBuffer(blob);
                    };

                    const processChunk = function (e) {
                      // Send the buffer to MediaInfo
                      var state = checker.checkBufferContinue(e.target.result);

                      //Test if there is a MediaInfo request to go elsewhere
                      var seekTo = checker.checkBufferGoToGet();
                      if (seekTo === -1) {
                        offset += e.target.result.byteLength;
                      } else {
                        offset = seekTo;
                        checker.checkBufferGoTo(file.size, seekTo); // Inform MediaInfo we have seek
                      }

                      // Bit 3 set means finalized
                      if (state & 0x08 || e.target.result.byteLength < 1) {
                        checker.checkBufferFinalize();
                        resolve();
                        return;
                      }

                      loop(CHUNK_SIZE);
                    };

                    // Start
                    loop(CHUNK_SIZE);
                  });
                };

                activateProfilePolicy();
              })
              .catch(function (error) {
                console.log("ERROR checker");
                console.log(error);
              });
          };
        };
      };
    }

    return () => {
      MediaConchJs.remove();
      MediaInfoJs.remove();
      PolicyCheckerJs.remove();
      IMSC1PluginJs.remove();
      setLoadingNeededData(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asperaWeb]);

  const mediaInfoReport = async (receivedFile) => {
    try {
      let jsonMetadata;
      //Automation flow receive the file as a blob
      //Normal flow use an array buffer
      //For more information read AUTOMATION.MD in root folder
      if (simulatedDragDrop) {
        await parseFile(receivedFile);
        jsonMetadata = getJsonMetadata();
      } else {
        await parseFileWithAsperaBuffer(receivedFile);
        jsonMetadata = getJsonMetadata();
      }

      return jsonMetadata;
    } catch (error) {
      return undefined;
    }
  };

  const mediaconchPolicyCheck = async (asperaFile, workaroundFiles) => {
    let result;
    //Automation flow receive the file as a blob
    //Normal flow use an array buffer
    //For more information read AUTOMATION.MD in root folder
    if (simulatedDragDrop) {
      result = checker.checkFile(asperaFile);
    } else {
      await checkPolicyWithAsperaBuffer(asperaFile, workaroundFiles);
      result = checker.checkBufferResult();
    }

    return result
      .then(function () {
        return checker.getReport().then((xmlReport) => {
          const report = xmlToJson.xml2json(xmlReport);
          return mapToPolicyCheckResult(
            JSON.parse(report),
            asperaFile,
            simulatedDragDrop
          );
        });
      })
      .catch((error) => {
        return {
          accepted: false,
          rejectedReasons: ["An error happened generating the policy report"],
        };
      });
  };

  return {
    mediaconchPolicyCheck,
    mediaInfoReport,
  };
};
