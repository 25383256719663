import { forwardRef } from "react";
import {
  CircleCheck,
  Clock,
  Clock2,
  RefreshDot,
  Archive,
  AlertTriangle,
  AlertOctagon,
  History,
  CircleOff,
  CircleDotted,
  DiscountCheck,
  Send,
  FileUpload,
  FilePlus,
  FileCheck,
  PlayerPlay,
  Checkbox,
} from "tabler-icons-react";
import { StatusColor } from "./StatusColor";
import { StatusNames } from "./index";
import { useTID } from "../../hooks/useTestId";
import { useMantineTheme } from "@mantine/core";

export interface StatusIconProps {
  status: StatusNames;
  color?: string;
  size?: number;
  white?: boolean;
  testid?: string;
}

export const StatusIcon = forwardRef<HTMLDivElement, StatusIconProps>(
  (props, ref) => {
    const statusColors = StatusColor();
    const TID = useTID("StatusIcon", props.testid || "undefined");
    const theme = useMantineTheme();

    const status: Record<string, React.FC> = {
      completed: CircleCheck,
      error: AlertOctagon,
      expired: Archive,
      failure: AlertTriangle,
      pending: Clock,
      waiting: Clock2,
      processing: RefreshDot,
      skipped: DiscountCheck,
      queued: History,
      inactive: CircleOff,
      deleted: CircleDotted,
      created: FilePlus,
      uploaded: FileUpload,
      delivered: Send,
      registered: FileCheck,
      process_start: PlayerPlay,
      process_end: Checkbox,
      pending_upload:RefreshDot,
      delivery_complete:CircleCheck,
      qc_failed:AlertOctagon,
    };

    const Icon: React.FC<StatusIconProps> = status[props.status];
    const Color: string = props.white
      ? "#fff"
      : theme.colorScheme === "dark"
      ? theme.fn.lighten(statusColors[props.status], 0.25)
      : statusColors[props.status];
    const Size: number = props.size || props.status === "inactive" ? 24 : 28;
    return <Icon {...TID} status={props.status} color={Color} size={Size} />;
  }
);
