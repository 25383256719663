import { Text } from "../../components-ui";

export default function IdOnlyOneVideoHelp({ master }: { master: boolean }) {
  const masterText = master ? "Master" : "Submaster";

  return (
    <>
      <Text
        size="sm"
        testid={"Help-CidOnlyOneVideo-" + masterText + "-Description1"}
      >
        Only 1 video is allowed to be uploaded and associated to a specific ID
        and {masterText}.
      </Text>
      <Text
        size="sm"
        testid={"Help-CidOnlyOneVideo-" + masterText + "-Description2"}
      >
        To proceed, a user must remove all videos with this rejection reason
        from the Rejected column, pick just one video for the related ID/
        {masterText} and try adding it again.
      </Text>
    </>
  );
}
