import { useMantineTheme } from "@mantine/core";
import { Badge } from "../../../components-ui";
import { useRecoilValue } from "recoil";
import { FileTime, FileUpload, Icon as TablerIcon } from "tabler-icons-react";
import {
  filesRejectedAtom,
  filesValidateAtom,
} from "../../../recoil/bulkupload/atoms";
import useStyles from "../BulkUploadStyles";

export const ImageUploadIcon = ({
  ...props
}: React.ComponentProps<TablerIcon>) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const filesValidate = useRecoilValue(filesValidateAtom);
  const filesRejected = useRecoilValue(filesRejectedAtom);

  return filesValidate.length === 0 ? (
    filesRejected.length > 0 ? (
      <></>
    ) : (
      <FileUpload strokeWidth={1} {...props} />
    )
  ) : (
    <>
      <FileTime strokeWidth={1} color={theme.colors.blue[6]} {...props} />
      <Badge
        variant="filled"
        color="blue"
        size="sm"
        className={classes.BadgePlacer}
        testid="imageUploadIcon"
      >
        {filesValidate.length}
      </Badge>
    </>
  );
};

export default ImageUploadIcon;
