import { Badge } from "../../../components-ui";
import { useRecoilValue } from "recoil";
import { FileDislike, Icon as TablerIcon } from "tabler-icons-react";
import { filesRejectedAtom } from "../../../recoil/bulkupload/atoms";
import useStyles from "../BulkUploadStyles";
import { useMantineTheme } from "@mantine/core";

export const FileDislikeIcon = ({
  ...props
}: React.ComponentProps<TablerIcon>) => {
  const filesRejected = useRecoilValue(filesRejectedAtom);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const dislikeColor =
    theme.colorScheme === "dark" ? theme.colors.red[7] : theme.colors.red[6];

  if (filesRejected.length > 0) {
    const allDeliveryFileRejected = filesRejected.map((rejected) => {
      return rejected.files;
    });

    return (
      <>
        <FileDislike
          strokeWidth={1}
          color={dislikeColor}
          size={80}
          {...props}
        />
        <Badge
          variant="filled"
          color="red"
          size="sm"
          className={classes.BadgePlacer}
          testid="fileDislikeIcon"
        >
          {allDeliveryFileRejected.flat().length}
        </Badge>
      </>
    );
  }

  return <></>;
};

export default FileDislikeIcon;
