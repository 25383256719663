import { AppShell } from "@mantine/core";
import { Route, Routes } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import useStyles from "../theme/globalStyles";
import { useEffect } from "react";
import { authTokenInterceptorInjector, httpServices, responseErrorInterceptorInjector, responseInterceptorInjector } from "../services/httpCommon";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  AsideAccount,
  AsideSettings,
  Footer,
  Header,
  Navbar,
  navBarToggleAtom,
  showAsideAtom,
} from "../components-ui";
import { OktaAuth } from "@okta/okta-auth-js";
import { configAtom } from "./ConfigRecoil";
import MaintenancePage from "../pages/MaintenancePage";
import AppRoutes from "./AppRoutes";
import { userDataAtom } from "./state";
import { unexpectedErrorAtom } from "../recoil/bulkupload/atoms";
import { useLoadAppData } from "../hooks/useLoadAppData";

export const AppInnerRouter = () => {
  const config = useRecoilValue(configAtom);

  switch (config.maintenance) {
    case "ON":
      return (
        <Routes>
          <Route element={<MaintenancePage />} />
        </Routes>
      );
    default:
      return <AppRoutes />;
  }
};

export const AppWithRouterAccess = ({ oktaAuth }: { oktaAuth: OktaAuth }) => {
  const setUnexpectedError = useSetRecoilState(unexpectedErrorAtom);
  const { classes } = useStyles();
  const showAside = useRecoilValue(showAsideAtom);
  const navbarOpened = useRecoilValue(navBarToggleAtom);
  const config = useRecoilValue(configAtom);
  const [userData] = useRecoilState(userDataAtom);
  const { authState } = useOktaAuth();

  useLoadAppData();

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      //Add a request interceptor for Auth
      const token = authState?.accessToken
        ? authState?.accessToken.accessToken
        : "";
      
        httpServices.interceptors.request.use(authTokenInterceptorInjector(token, config.servicesBaseUrl));
    }
    httpServices.interceptors.response.use(responseInterceptorInjector(), responseErrorInterceptorInjector(setUnexpectedError));

  }, [authState, config.servicesBaseUrl, setUnexpectedError]);

  const displayAsides = () => {
    switch (showAside) {
      case "AsideSettings":
        return <AsideSettings testid="asideSettings" />;
      case "AsideAccount":
        return (
          <AsideAccount
            oktaAuth={oktaAuth}
            testid="aside-account"
            userData={userData}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <AppShell
      classNames={{
        body: classes.body,
      }}
      navbarOffsetBreakpoint="xl"
      fixed
      aside={displayAsides()}
      padding={10}
      header={<Header />}
      footer={<Footer testid="footer" />}
      navbar={navbarOpened ? <Navbar /> : <></>}
    >
      <AppInnerRouter />
    </AppShell>
  );
};
