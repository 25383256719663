import { Headerbar } from "../components-ui";
import HelpContainer from "../containers/HelpContainer/HelpContainer";

export default function HelpInfoPage() {
  const crumbs = [
    { title: "Promo Upload", path: "/" },
    { title: "Help", path: "/help" },
  ];

  return (
    <>
      <Headerbar crumbs={crumbs} testid="headerBar" rightSection={<></>} />
      <HelpContainer />
    </>
  );
}
