import { createStyles } from "@mantine/core";

export default createStyles((theme) => {
  return {
    AcceptFileItem: {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.green[5]
          : theme.colors.green[0],
      border:
        "1px solid" +
        (theme.colorScheme === "dark"
          ? theme.colors.green[8]
          : theme.colors.green[2]),
      borderRadius: 8,
      marginBottom: -10,
      padding: 8,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : theme.colors.dark[8],
    },
    PendingFileItem: {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.blue[8]
          : theme.colors.blue[0],
      border:
        "1px solid" +
        (theme.colorScheme === "dark"
          ? theme.colors.blue[6]
          : theme.colors.blue[2]),
      borderRadius: 8,
      marginBottom: -10,
      padding: 8,
    },
    RejectedFileItem: {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.red[9]
          : theme.colors.red[0],
      border:
        "1px solid" +
        (theme.colorScheme === "dark"
          ? theme.colors.red[8]
          : theme.colors.red[2]),
      borderRadius: 8,
      marginBottom: -10,
      padding: 8,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.gray[3]
          : theme.colors.dark[8],
    },
    BadgePlacer: {
      marginLeft: -36,
      marginTop: -48,
    },
    DropZone: {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.blue[9]
          : theme.colors.blue[0],
      border:
        "2px dashed" +
        (theme.colorScheme === "dark"
          ? theme.colors.blue[5]
          : theme.colors.blue[2]),
      borderRadius: 8,
    },
  };
});
