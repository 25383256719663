import { Divider, Group, Radio } from "@mantine/core";
import { Tooltip } from "../../../components-ui";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedSlateProfileAtom,
  slateProfilesAtom,
} from "../../../recoil/bulkupload/atoms";
import { useThemeColors } from "../../../hooks/useThemeColors";
import { find } from "lodash";

export const SlateProfileBox = () => {
  const slateProfiles = useRecoilValue(slateProfilesAtom);
  const [selectedSlateProfile, setSelectedSlateProfile] = useRecoilState(
    selectedSlateProfileAtom
  );
  const { dividerColor } = useThemeColors();

  const setFullSlateProfile = (slateProfileCode: string) => {
    const slateProfile = find(slateProfiles, function(slateProfile) {
      return slateProfile.code === slateProfileCode;
    });
    if (slateProfile) {
      setSelectedSlateProfile(slateProfile);
    }
  };

  return slateProfiles.length > 0 ? (
    <>
      <Divider my="sm" color={dividerColor} />
      <Radio.Group
        value={selectedSlateProfile.code}
        onChange={setFullSlateProfile}
        label="Select your Slate Profile"
        description="This is required to enable Upload"
        spacing="xs"
        size="sm"
        required
      >
        {slateProfiles.map((profile) => {
          return (
            <Tooltip
              label={profile.description}
              color="blue"
              position="top"
              withArrow
              key={profile.code}
            >
              <Group>
                <Radio
                  key={profile.code}
                  value={profile.code}
                  data-testid={":Radio-SlateProfile-" + profile.code}
                  label={profile.label}
                />
              </Group>
            </Tooltip>
          );
        })}
      </Radio.Group>
    </>
  ) : (
    <></>
  );
};
