import { createStyles } from '@mantine/core';

export default createStyles((theme) => {
  return {
    listSortable: {
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.titan[2],
      border: `1px solid ${theme.colors.titan[3]}`,
      borderRadius: theme.radius.sm,
      marginBottom: theme.radius.sm,
      padding: 6,
      paddingLeft: 12,
      cursor: 'move'
    },
    ghost: {
      opacity: 0.5,
      background: theme.colors.blue[3]
    },
    chosen: {
      color: theme.white,
      background: theme.colors.blue[2]
    },
    handle: {
      cursor: '-webkit-grabbing'
    }
  };
});
