import { atom, selector } from 'recoil';

export const showAsideAtom = atom({
  key: 'app/showAside',
  default: ''
});

export const previousAsideStateAtom = atom({
  key: 'app/previousAsideState',
  default: ''
});

export const showAsideSelector = selector({
  key: 'app/showAsideSelector',
  get: ({ get }) => {
    return get(showAsideAtom);
  },
  set: ({ get, set }, newValue) => {
    const showAside = get(showAsideAtom);
    if (showAside === newValue) {
      set(showAsideAtom, '');
    } else {
      set(showAsideAtom, newValue);
    }
  }
});
