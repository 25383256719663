import { DeliveryFileRejection } from "../../../helpers/upload/deliveryHelperModels";
import { IdNotFoundMessage } from "./IdNotFoundMessage";
import { FileRejectionMessage } from "./FileRejectionMessage";

export const RejectedMessage = ({
  fileRejection,
  id,
  customMessage,
  linkIcon,
}: {
  fileRejection: DeliveryFileRejection;
  id: string;
  customMessage?: boolean;
  linkIcon?: boolean;
}) => {
  return customMessage ? (
    <IdNotFoundMessage id={id} />
  ) : (
    <FileRejectionMessage fileRejection={fileRejection} linkIcon={linkIcon} />
  );
};
