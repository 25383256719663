import { Box, Center, Space, Text } from "@mantine/core";
import { Aside, Button } from "../../components-ui/";
import { useTID } from "../../hooks/useTestId";
import { Logout } from "tabler-icons-react";
import { useThemeColors } from "../../hooks/useThemeColors";

interface IUserData {
  name?: string;
  surName?: string;
  email?: string;
}

export const AsideAccount = ({
  oktaAuth,
  testid,
  userData,
}: {
  oktaAuth: any;
  testid: string;
  userData: IUserData;
}) => {
  const logout = async () => {
    await oktaAuth.signOut();
  };

  const TID = useTID("AsideAccount", testid);
  const { accountBoxColor, dividerColor } = useThemeColors();

  return (
    <Aside
      {...TID}
      testid="AsideAccount"
      p="md"
      hiddenBreakpoint="sm"
      width={{ sm: 200, lg: 300 }}
    >
      <Box
        style={{
          backgroundColor: `${accountBoxColor}`,
          border: `1px solid ${dividerColor}`,
          borderRadius: 6,
          padding: 8,
          margin: -12,
          marginBottom: 12,
        }}
      >
        <Text>User Account</Text>
      </Box>
      <Box key={userData?.name} style={{ padding: 8 }}>
        <Center>
          <Text>
            {userData?.name} {userData?.surName}
          </Text>
        </Center>
        <Center>
          <Text> {userData?.email} </Text>
        </Center>
        <Space h="md" />
        <Center>
          <Button
            testid="AsideAccount"
            onClick={() => logout()}
            leftIcon={<Logout />}
          >
            LogOut
          </Button>
        </Center>
      </Box>
    </Aside>
  );
};

export default AsideAccount;
