import { useOktaAuth } from "@okta/okta-react";
import { getUserRoles, UserRole } from "../helpers/authHelper";

const isMetadataAuth = (roles: string[]): boolean => {
  //Constains Role
  return roles.indexOf(UserRole.Internal) > -1;
};

export const useAuthRestrictions = () => {
  const { authState } = useOktaAuth();
  let metadataAuth = false;

  if (authState && authState.isAuthenticated) {
    const userRoles = getUserRoles(authState.accessToken?.claims!);

    metadataAuth = isMetadataAuth(userRoles);
  }
  return { metadataAuth };
};
