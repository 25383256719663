import { Switch as MSwitch, SwitchProps } from "@mantine/core";
import { useTID } from "../../hooks/useTestId";

interface ISwitchProps extends SwitchProps {
  testid: string;
}

export const Switch = (props: ISwitchProps) => {
  const TID = useTID("Switch", props.testid);
  return (
    <MSwitch
      sx={(theme) => ({
        input: {
          "&:checked": {
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.blue[9]
                : theme.colors.blue[6],
            borderColor:
              theme.colorScheme === "dark"
                ? theme.colors.blue[6]
                : theme.colors.gray[4],
            // border: '2px solid',
          },
        },
      })}
      {...TID}
      {...props}
    >
      {props.children}
    </MSwitch>
  );
};
