import { Text } from "../../components-ui";

export default function IdNotExistsHelp() {
  return (
    <>
      <Text size="sm" testid={"Help-CidNotExists-Description1"}>
        The ID present in your file name was <b>not found in CAT</b>.
      </Text>
      <Text size="sm" testid={"Help-CidNotExists-Description2"}>
        Make sure the ID was correctly generated in CAT before uploading
        associated assets.
      </Text>
    </>
  );
}
