import { Group } from "@mantine/core";
import { Text } from "../../../../components-ui";
import { ValidationErrorType } from "../../../../helpers/filesValidation/validationHelper";
import ErrorModal from "./ErrorModal";
import GoToHelpButton from "./GoToHelpButton";

const CidNotInFileModal = ({
  modalOpened,
  filename,
  setModalOpen,
}: {
  modalOpened: boolean;
  filename: string;
  setModalOpen: any;
}) => {
  return (
    <ErrorModal
      modalOpened={modalOpened}
      setModalOpen={setModalOpen}
      filename={filename}
      errorType={ValidationErrorType.IdNotFoundInFile}
    >
      <Group position="apart" style={{ padding: 16, marginTop: 5 }}>
        <Text size="sm" testid={"Modal-CidNotInFile"}>
          CID was not found in the filename, the naming convention is the
          following:{" "}
          <b>
            <i>filename_CID-xxxx.ext</i>
          </b>
        </Text>
        <Text size="sm" testid={"Modal-CidNotInFile-info"}>
          The <b>filename</b> followed by <b>"_CID-"</b>, where "xxxx" is the
          <b> corresponding CID</b> and ".ext" the <b>extension</b> of the file.
        </Text>
        <Text size="sm" testid={"Modal-CidNotInFile-example"}>
          For example, if your filename is <i>"Batman"</i> the CID is{" "}
          <i>"A1234"</i> and the asset is a <i>".mov"</i> file, the correct
          naming is{" "}
          <b>
            <i>Batman_CID-A1234.mov</i>
          </b>
        </Text>
        <GoToHelpButton tab="rejected" item="CidNotFoundInFile" />
      </Group>
    </ErrorModal>
  );
};

export default CidNotInFileModal;
