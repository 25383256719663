import { Group, Stack, useMantineTheme } from "@mantine/core";
import { Text } from "../../../components-ui";
import FileDislikeIcon from "../StatusIcons/FileDislikeIcon";
import FileLikeIcon from "../StatusIcons/FileLikeIcon";
import ImageUploadIcon from "../StatusIcons/ImageUploadIcon";

export const DropZoneStatusInfo = ({
  dragDropAvailable,
  dropZoneMinHeight,
}: {
  dragDropAvailable: boolean;
  dropZoneMinHeight: number;
}) => {
  const theme = useMantineTheme();

  return (
    <Stack
      align="center"
      justify="center"
      spacing="lg"
      style={{ minHeight: dropZoneMinHeight, pointerEvents: "none" }}
    >
      <Group position="apart">
        <FileLikeIcon size={80} />
        <ImageUploadIcon size={80} />
        <FileDislikeIcon size={80} />
      </Group>
      <Text size="xl" inline testid="drag-drop-availability">
        {"Drag files here to select files"
          /* Uncomment when Aspera bug is fixed https://github.com/IBM/aspera-connect-sdk-js/issues/49

          dragDropAvailable
          ? "Drag files here or click to select files"
          : "Click here to select files"*/}
      </Text>
      <Text
        align="center"
        size="sm"
        color={
          theme.colorScheme === "dark"
            ? theme.colors.gray[4]
            : theme.colors.dark[5]
        }
        inline
        testid="drag-drop-attach"
      >
        Attach as many files as you like,
        <br /> but more than 100 is NOT recommended.
      </Text>
    </Stack>
  );
};
