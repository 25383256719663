import { Divider, Group } from "@mantine/core";
import { Text } from "../../../components-ui";
import { useRecoilValue } from "recoil";
import { ClipboardX } from "tabler-icons-react";
import { filesRejectedAtom } from "../../../recoil/bulkupload/atoms";
import useStyles from "../BulkUploadStyles";
import { RejectedFileBox } from "../ContentBoxes/RejectedFileBox";

export const DisplayRejectedFiles = ({ ascending }: { ascending: boolean }) => {
  const filesRejected = useRecoilValue(filesRejectedAtom);
  const { classes } = useStyles();

  if (filesRejected.length < 1) {
    return <></>;
  }

  let orderRejectedFiles = filesRejected.slice();
  let sortedRejectedFiles = orderRejectedFiles.sort((a, b) => {
    if (a.id < b.id) {
      return ascending ? -1 : 1;
    }
    if (a.id > b.id) {
      return ascending ? 1 : -1;
    }
    /* istanbul ignore next */ 
    // This should be ignored from coverage, because if there are
    // files with the same ID, it produces a unique key error
    return 0;
  });

  return (
    <>
      {sortedRejectedFiles.map((rejected) => {
        let id = rejected.id;

        return (
          <div key={"rejected" + id} style={{ marginBottom: "25px" }}>
            <Divider
              my="xs"
              color="red"
              label={
                <Group style={{ position: "relative" }}>
                  <ClipboardX
                    size={25}
                    color="red"
                    style={{ marginRight: -14 }}
                  />{" "}
                  {id === "" ? (
                    <Text weight={700} testid="rejected-no-cid">
                      <span style={{ color: "red" }}>No ID</span> on file
                    </Text>
                  ) : (
                    <Text weight={700} testid={"rejected-cid-" + id}>
                      ID <span style={{ color: "red" }}>{id}</span>
                    </Text>
                  )}
                </Group>
              }
            />

            {rejected.files.map((file) => (
              <RejectedFileBox
                key={"rejected" + file.file.name}
                className={classes.RejectedFileItem}
                file={file}
                id={id}
              ></RejectedFileBox>
            ))}
          </div>
        );
      })}
    </>
  );
};
