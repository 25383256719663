import { createStyles } from '@mantine/core';

export default createStyles((theme) => ({
  detail: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    border: '1px solid',
    borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
    borderRadius: theme.radius.md,
    padding: 8,
    margin: -4,
    height: '100%'
  }
}));
