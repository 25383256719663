import { DeliveryFileObject } from "../upload/deliveryHelperModels";

export const isSubmaster = (file: DeliveryFileObject): boolean => {
  const lastIndex = file.name.lastIndexOf(".");
  const fileName = file.name.slice(0, lastIndex);
  const lowercaseSplit = fileName.split("_sub");
  const uppercaseSplit = fileName.split("_SUB");

  if (lowercaseSplit.length === 1 && uppercaseSplit.length === 1) {
    return false;
  } else {
    return true;
  }
};
