import { Group, Modal } from "@mantine/core";
import { Text, Title } from "../../../components-ui";
import { useRecoilState } from "recoil";
import { selectedFileMetadataAtom } from "../../../recoil/metadata/atoms";
import { emptyPromoMetadata } from "../../../recoil/metadata/defaults";
import { useThemeColors } from "../../../hooks/useThemeColors";

export const DisplayFileMetadataModal = ({
  modalOpened,
  filename,
  setModalOpen,
}: {
  modalOpened: boolean;
  filename: string;
  setModalOpen: any;
}) => {
  const { modalOverlayColor } = useThemeColors();
  const [selectedFileMetadata, setSelectedFileMetadata] = useRecoilState(
    selectedFileMetadataAtom
  );

  const closeModal = () => {
    setSelectedFileMetadata(emptyPromoMetadata);
    setModalOpen(false);
  };

  return (
    <Modal
      onClose={closeModal}
      opened={modalOpened}
      trapFocus={true}
      closeOnEscape={true}
      closeOnClickOutside={true}
      withCloseButton={true}
      title={
        <Title
          order={3}
          key={"modal_metadata" + filename}
          testid="metadata-modal"
        >
          Metadata
        </Title>
      }
      overlayColor={modalOverlayColor}
      size="lg"
      overflow="inside"
    >
      <Group position="apart" style={{ padding: 16, marginTop: 5 }}>
        <Group style={{ marginTop: -15, display: "block" }}>
          <Text size="sm" testid={"Modal-CID-" + selectedFileMetadata.id}>
            <b>ID:</b> {selectedFileMetadata.id}
          </Text>
          <Text size="sm" testid={"Modal-Name-" + selectedFileMetadata.id}>
            <b>Name:</b> {selectedFileMetadata.name}
          </Text>
          <Text
            size="sm"
            testid={"Modal-Description-" + selectedFileMetadata.id}
          >
            <b>Description:</b> {selectedFileMetadata.description}
          </Text>
          <Text size="sm" testid={"Modal-Version-" + selectedFileMetadata.id}>
            <b>Version:</b> {selectedFileMetadata.versionName}
          </Text>
          <Text size="sm" testid={"Modal-Duration-" + selectedFileMetadata.id}>
            <b>Duration:</b> {selectedFileMetadata.durationInSeconds} seconds
          </Text>
          <Text
            size="sm"
            testid={"Modal-FlightDateBegin-" + selectedFileMetadata.id}
          >
            <b>Flight Date Begin:</b> {selectedFileMetadata.flightDateBegin}
          </Text>
        </Group>
      </Group>
    </Modal>
  );
};
