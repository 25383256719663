import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppWithRouterAccess } from "./AppRouter";
import AuthRequiredModal from "./AuthRequiredModal";

export const AppWithSecurity = ({ config }: { config: any }) => {
  const [authRequiredModalOpen, setAuthRequiredModalOpen] = useState(false);
  const navigate = useNavigate();

  const oktaAuth = new OktaAuth({
    issuer: "https://" + config.oktaDomain + ".com/oauth2/default",
    clientId: config.oktaClientId,
    redirectUri: window.location.origin + "/login",
    postLogoutRedirectUri: window.location.origin + "/logout",
  });

  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };

  useEffect(() => {
    return () => {
      oktaAuth.options.restoreOriginalUri = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      setAuthRequiredModalOpen(true);
    }
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <AuthRequiredModal
        {...{ authRequiredModalOpen, setAuthRequiredModalOpen, triggerLogin }}
      />
      <AppWithRouterAccess oktaAuth={oktaAuth} />
    </Security>
  );
};

export default AppWithSecurity;
