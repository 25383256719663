// @types.common.ts
export enum MessageType {
  Error = "error",
  Warning = "warning",
  Info = "info",
  Success = "success",
}

export interface IUserInfo {
  name: string;
  email: string;
  avatar: string;
  roles: string[];
}

export enum LocalStorageKeys {
  MyUploadsLastVisited = "last-date-my-uploads-page-visited"
}