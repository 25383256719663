import { useMantineTheme } from '@mantine/core';

export const StatusColor = () => {
  const theme = useMantineTheme();

  const colors: Record<string, string> = {
    completed: theme.colorScheme === 'dark' ? theme.colors.success[9] : theme.colors.success[7],
    error: theme.colorScheme === 'dark' ? theme.colors.danger[8] : theme.colors.danger[7],
    warning: theme.colorScheme === 'dark' ? theme.colors.warning[9] : theme.colors.warning[7],
    expired: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[5],
    failure: theme.colorScheme === 'dark' ? '#e6c044' : '#FFA117', //theme.colorScheme === 'dark' ? theme.colors.orange[9] : theme.colors.orange[7],
    pending: theme.colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[6],
    waiting: theme.colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[6],
    processing: theme.colorScheme === 'dark' ? theme.colors.blue[9] : theme.colors.blue[7],
    skipped: theme.colorScheme === 'dark' ? theme.colors.success[9] : theme.colors.success[7],
    queued: theme.colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[6],
    inactive: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.gray[8],
    pending_upload: theme.colorScheme === 'dark' ? theme.colors.orange[9] : theme.colors.orange[7],
    delivery_complete: theme.colorScheme === 'dark' ? theme.colors.success[9] : theme.colors.success[7],
    qc_failed: theme.colorScheme === 'dark' ? theme.colors.danger[9] : theme.colors.danger[7],
  };

  return colors;
};
