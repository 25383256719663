import { Navigate, Route, Routes } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";
import { SecureRoute } from "./SecureRoute";
import MaintenancePage from "../pages/MaintenancePage";
import ErrorPage from "../pages/ErrorPage";
import NotFoundPage from "../pages/NotFoundPage";
import BulkUploadPage from "../pages/BulkUploadPage";
import HelpInfoPage from "../pages/HelpInfoPage";
import MyUploadsPage from "../pages/MyUploadsPage";
import AllUploadsPage from "../pages/AllUploadsPage";
import NotAuthorizedPage from "../pages/NotAuthorizedPage";

const AppRoutes = () => {
    return (
      <Routes>
        <Route path="/login" element={<LoginCallback />} />
        <Route path="/logout" element={<Navigate to="/" />} />
        <Route path="/maintenance" element={<MaintenancePage />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/" element={<SecureRoute />}>
          <Route path="" element={<BulkUploadPage />} />
        </Route>
        <Route path="/help" element={<SecureRoute />}>
          <Route path="" element={<HelpInfoPage />} />
        </Route>
        <Route path="/my-uploads" element={<SecureRoute />}>
          <Route path="" element={<MyUploadsPage />} />
        </Route>
        <Route path="/all-uploads" element={<SecureRoute />}>
          <Route path="" element={<AllUploadsPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/not-authorized" element={<NotAuthorizedPage />} />
      </Routes>
    );
};

export default AppRoutes;