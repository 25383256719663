import { useSetRecoilState, useRecoilCallback, useRecoilState } from "recoil";
import { appLoadedAtom, userDataAtom } from "../app/state";
import { IAlerts, ILanguage, ISlateProfile } from "../models/referenceData";
import { alertsAtom, languagesAtom, slateProfilesAtom } from "../recoil/bulkupload/atoms";
import { ReferenceDataService } from "../services/referenceData.service";
import { LocalStorageService } from "../services/localStorage.service";
import { LocalStorageKeys } from "../models/common";
import moment, { Moment } from "moment";
import { myFailedUploadsAtom } from "../recoil/uploads/atoms";
import { DeliveryService } from "../services/delivery.service";
import { IDelivery } from "../models/myUploads";
import { showNotification } from "@mantine/notifications";

const getDeliveriesRequest = async (): Promise<IDelivery[]> => {
    let date = LocalStorageService.getItem<Moment>(LocalStorageKeys.MyUploadsLastVisited);
    if (!date) {
        date = moment().utc().add(-30, 'days');
    }
    const request = {
        startRow: 0,
        endRow: 50,
        filterModel: {
            date: {
                dateFrom: date,
                dateTo: null,
                filterType: 'date',
                type: 'greaterThan'
            },
            state: {
                filter: 'qc_failed',
                filterType: 'text',
                type: 'equals'
            }
        },
        groupKeys: [],
        pivotCols: [],
        pivotMode: false,
        rowGroupCols: [],
        sortModel: [],
        valueCols: []
    };

    return (await DeliveryService.getDeliveriesWithParams(request, false, false)).data;
}

export const useLoadAppData = (): void => {
    const [appLoaded, setAppLoaded] = useRecoilState(appLoadedAtom);
    const setLanguages = useSetRecoilState(languagesAtom);
    const setSlateProfiles = useSetRecoilState(slateProfilesAtom);
    const setAlerts = useSetRecoilState(alertsAtom);
    const setMyFailedUploads = useSetRecoilState(myFailedUploadsAtom);

    const callback = useRecoilCallback(({snapshot}) => async () => {
        const userData = await snapshot.getPromise(userDataAtom);
        if (userData.email && !appLoaded) {
            setLanguages(await ReferenceDataService.getReferenceData<ILanguage>("languages"));
            setSlateProfiles(await ReferenceDataService.getReferenceData<ISlateProfile>("slate-profiles"));

            const alerts = (await ReferenceDataService.getReferenceData<IAlerts>("alerts"))
              .filter(alert => {
                return !alert.metadata?.expirationDate || moment().utc().diff(moment(alert.metadata.expirationDate).local(), 'days', true) < 0
              });
            setAlerts(alerts);
            
            const failedUploads = await getDeliveriesRequest();
            if (failedUploads.length) {
                showNotification({
                    id: 'qc-failures-notification',
                    message: `There are ${failedUploads.length} new delivery failures. Please look at the My Uploads page for details.`,
                    autoClose: false,
                    color: 'red'
                });
                setMyFailedUploads(failedUploads);
            }
            setAppLoaded(true);
        }
        
    }, [appLoaded]);

    callback();
}