import { Stack } from "@mantine/core";
import { Button, Text, Title } from "../components-ui";
import { useNavigate } from "react-router-dom";
import { Home } from "tabler-icons-react";

export default function ErrorPage() {
  let navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <Stack
      align="center"
      justify="center"
      spacing="xl"
      style={{
        minHeight: 600,
      }}
    >
      <Title order={1} testid="error">
        Something went wrong...
      </Title>
      <Text size="md" testid="error-working-to-resolve-it">
        We are working to resolve it, please try again later.
      </Text>
      <Button leftIcon={<Home />} onClick={goHome} testid="error-go-home">
        Go Home
      </Button>
    </Stack>
  );
}
