import { RecoilRoot } from "recoil";
import { ColorScheme, ColorSchemeProvider } from "@mantine/core";
import { useHotkeys, useLocalStorage } from "@mantine/hooks";
import { NotificationsProvider } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { BlueprintProvider } from "../theme";

export const AppProviders = (props: any) => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "light",
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  useHotkeys([["mod+J", () => toggleColorScheme()]]);

  return (
    <RecoilRoot>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <BlueprintProvider colorScheme={colorScheme}>
          <NotificationsProvider position="top-right" zIndex={2077}>
            <ModalsProvider>{props.children}</ModalsProvider>
          </NotificationsProvider>
        </BlueprintProvider>
      </ColorSchemeProvider>
    </RecoilRoot>
  );
};

export default AppProviders;
