import { Divider, Group } from "@mantine/core";
import { Text } from "../../../components-ui";
import { InfoCircle } from "tabler-icons-react";
import { useAuthRestrictions } from "../../../hooks/useAuthRestrictions";
import { useThemeColors } from "../../../hooks/useThemeColors";

export const MetadataInfoBox = () => {
  const { metadataAuth } = useAuthRestrictions();
  const { dividerColor } = useThemeColors();

  return metadataAuth ? (
    <>
      <Divider my="sm" color={dividerColor} />
      <Group style={{ position: "relative" }}>
        <InfoCircle color="#228BE6" size={16} style={{ marginRight: -12 }} />
        <Text size="sm" weight={500} testid="MetadataInfoBox">
          Metadata
        </Text>
      </Group>
      <Text size="xs" color="#868e96 " testid="metadata-box-info">
        Click on Accepted file to display Metadata.
      </Text>
    </>
  ) : (
    <></>
  );
};
