import { Group, ScrollArea, useMantineTheme } from "@mantine/core";
import { useRecoilState } from "recoil";
import { TrashX } from "tabler-icons-react";
import { Text } from "../../../components-ui";
import { DisplayRejectedFiles } from "../../../components/BulkUpload/DisplayInfo/DisplayRejectedFiles";
import { useThemeColors } from "../../../hooks/useThemeColors";
import { filesRejectedAtom } from "../../../recoil/bulkupload/atoms";
import { useState } from "react";
import { SortFilesButton } from "../../../components/BulkUpload/DisplayInfo/SortFilesButton";

export default function RightColumnContainer({
  height,
  className,
  wrapperStyle,
}: {
  height: number;
  className: string;
  wrapperStyle: React.CSSProperties;
}) {
  const theme = useMantineTheme();
  const { rejectedIconColor } = useThemeColors();
  const [filesRejected, setFilesRejected] = useRecoilState(filesRejectedAtom);
  const [ascending, setAscending] = useState<boolean>(true);

  const removeAllRejectedFile = (): void => {
    setFilesRejected([]);
  };

  const toggleOrder = (): void => {
    if (ascending) {
      setAscending(false);
    } else {
      setAscending(true);
    }
  };

  return (
    <div className={className} style={wrapperStyle}>
      <Group position="apart" mb={5}>
        <Group>
          <Text
            size="md"
            color={
              theme.colorScheme === "dark"
                ? theme.colors.red[7]
                : theme.colors.red[8]
            }
            weight={700}
            testid="rejected-items"
          >
            Rejected Items
          </Text>
          {filesRejected.length > 0 && (
            <SortFilesButton
              ascending={ascending}
              acceptedColumn={false}
              color={rejectedIconColor}
              toggleOrder={toggleOrder}
            />
          )}
        </Group>
        {filesRejected.length > 0 && (
          <Group
            onClick={() => removeAllRejectedFile()}
            style={{ cursor: "pointer" }}
            spacing="xs"
          >
            <TrashX
              color={rejectedIconColor}
              data-testid={":Icon-RemoveAllFiles"}
            />
            <Text
              testid="remove-all-rejected"
              color={rejectedIconColor}
              weight="bold"
            >
              Remove All
            </Text>
          </Group>
        )}
      </Group>
      <ScrollArea
        style={{ height: height - 55 }}
        scrollHideDelay={0}
        offsetScrollbars
      >
        <DisplayRejectedFiles ascending={ascending} />
      </ScrollArea>
    </div>
  );
}
