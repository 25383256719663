import { atom } from "recoil";
import { IPromoMetadata } from "../../models/metadata";
import { emptyPromoMetadata } from "./defaults";

export const bulkMetadataAtom = atom<IPromoMetadata[]>({
  key: "metadata/bulk",
  default: [],
});

export const selectedFileMetadataAtom = atom<IPromoMetadata>({
  key: "metadata/selected",
  default: emptyPromoMetadata,
});
