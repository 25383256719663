import { Connect } from "@ibm-aspera/connect-sdk-js";
import { AllTransfersInfo } from "@ibm-aspera/connect-sdk-js/dist/esm/core/types";
import { showNotification } from "@mantine/notifications";
import { asperaWeb } from "../Aspera/AsperaConnect";
import { Check, X } from "tabler-icons-react";

export const useAsperaNotifications = () => {
  const timeInterval = 2000; // 2 seconds

  const checkAsperaUploadStatus = () => {
    asperaWeb.getAllTransfers({ success: successInitFn, error: errorFn });
  };

  //Get latest iteration token to start checking after that
  const successInitFn = (info: AllTransfersInfo) => {
    checkAsperaForCompleteStatus(info.iteration_token);
  };

  const errorFn = () => {
    showUploadCompleteNotification(false);
  };

  //Checks until a completeness status is reached
  const checkAsperaForCompleteStatus = (iterationToken: number) => {
    asperaWeb.getAllTransfers(
      { success: successCheckStatusFn, error: errorFn },
      iterationToken
    );
  };

  const successCheckStatusFn = (info: AllTransfersInfo) => {
    if (info.transfers.length === 0 || !isFinalState(info)) {
      setTimeout(
        () => checkAsperaForCompleteStatus(info.iteration_token),
        timeInterval
      );
    }
  };

  const isFinalState = (info: AllTransfersInfo) => {
    const status = info.transfers[0].status;

    switch (status) {
      case Connect.TRANSFER_STATUS.COMPLETED:
        showUploadCompleteNotification(true);
        return true;
      case Connect.TRANSFER_STATUS.FAILED:
        let errorMessage = "";
        // @ts-ignore
        if (info.transfers[0].error_desc) {
          // @ts-ignore
          errorMessage = info.transfers[0].error_desc;
        }
        showUploadCompleteNotification(false, errorMessage);
        return true;
      default:
        return false;
    }
  };

  const showUploadCompleteNotification = (
    success: boolean,
    message?: string
  ) => {
    let notificationMessage = success
      ? "All files successfully uploaded"
      : "An error occurred, check Aspera Monitor";
    if (message) {
      notificationMessage = message;
    }

    showNotification({
      color: success ? "green" : "red",
      title: "Aspera Files Upload",
      message: notificationMessage,
      autoClose: false,
      icon: success ? <Check /> : <X />,
    });
  };

  return {
    checkAsperaUploadStatus,
  };
};
