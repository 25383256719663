import { Container, Grid, Group } from "@mantine/core";
import { Text } from "../../../components-ui";
import { useState } from "react";
import { FileDislike } from "tabler-icons-react";
import { filenameFromPath } from "../../../helpers/fileHelper";
import { ValidationErrorType } from "../../../helpers/filesValidation/validationHelper";
import { DeliveryFileRejection } from "../../../helpers/upload/deliveryHelperModels";
import { BulkInfoRemoveFile } from "../DisplayInfo/LoaderMessage/BulkInfoRemoveFile";
import CidNotInFileModal from "../RejectedFile/Modals/CidNotInFileModal";
import PolicyRejectionModal from "../RejectedFile/Modals/PolicyRejectionModal";
import { RejectedMessage } from "../RejectedFile/RejectedMessage";
import { useThemeColors } from "../../../hooks/useThemeColors";

export const RejectedFileBox = ({
  className,
  file,
  id,
}: {
  className: string;
  file: DeliveryFileRejection;
  id: string;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const filename = filenameFromPath(file.file.name);
  const { rejectedIconColor } = useThemeColors();

  const onClick = () => {
    setModalOpen(true);
  };

  const messageOrModal = () => {
    switch (file.error) {
      case ValidationErrorType.PolicyCheck:
        return (
          <>
            <PolicyRejectionModal
              modalOpened={modalOpen}
              rejectedReasons={file.errorReasons}
              filename={filename}
              setModalOpen={setModalOpen}
            />
            <Group
              onClick={onClick}
              style={{ cursor: "pointer" }}
              data-testid={":Group-RejectedFileBox-" + filename}
            >
              <RejectedMessage fileRejection={file} id={id} linkIcon={true} />
            </Group>
          </>
        );
      case ValidationErrorType.IdNotFoundInFile:
        return (
          <>
            <CidNotInFileModal
              modalOpened={modalOpen}
              filename={filename}
              setModalOpen={setModalOpen}
            />
            <Group
              onClick={onClick}
              style={{ cursor: "pointer" }}
              data-testid={":Group-RejectedFileBox-" + filename}
            >
              <RejectedMessage fileRejection={file} id={id} linkIcon={true} />
            </Group>
          </>
        );

      default:
        return <RejectedMessage fileRejection={file} id={id} />;
    }
  };

  return (
    <Container
      className={className}
      mt={15}
      data-testid={":Group-RejectedFileBox-" + id}
    >
      <Grid justify="space-between" align="center" columns={48}>
        <Grid.Col span={3}>
          <FileDislike color={rejectedIconColor} />
        </Grid.Col>
        <Grid.Col span={41}>
          <Text size="sm" testid={"rejected-filebox-" + filename}>
            {filename}
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <BulkInfoRemoveFile
            completeFileName={file.file.name}
            rejectedColumn={true}
          ></BulkInfoRemoveFile>
        </Grid.Col>
      </Grid>
      <Group mt={5}>{messageOrModal()}</Group>
    </Container>
  );
};
