import { Text } from "../../components-ui";

export default function PolicyCheckHelp() {
  return (
    <>
      <Text size="sm" testid={"Help-PolicyCheck-Description1"}>
        All files are validated to comply with our Policies and Standards.
      </Text>
      <Text size="sm" testid={"Help-PolicyCheck-Description2"}>
        There are different requirements depending on if the file is a Video,
        Image or Text.
      </Text>
      <Text size="sm" testid={"Help-PolicyCheck-Description3"}>
        In every file that fails the policy check and gets this alert, there is
        a link to a modal window where you will find specific information about
        what Policies were not met.
      </Text>
    </>
  );
}
