import { Group, Modal } from "@mantine/core";
import { Text, Title } from "../../components-ui";
import { useThemeColors } from "../../hooks/useThemeColors";

const UnexpectedErrorModal = ({ opened }: { opened: boolean }) => {
  const closeModal = () => {};
  const { modalOverlayColor } = useThemeColors();

  return (
    <Modal
      onClose={closeModal}
      opened={opened}
      trapFocus={true}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      title={
        <Title order={3} testid="unexpected-something-wrong">
          Something went wrong...
        </Title>
      }
      overlayColor={modalOverlayColor}
    >
      <Group position="center">
        <Text size="md" testid="unexpected-reload">
          Please Reload the page, or try again later
        </Text>
      </Group>
    </Modal>
  );
};
export default UnexpectedErrorModal;
