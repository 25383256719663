import { createStyles } from '@mantine/core';

export default createStyles((theme) => {
  return {
    navbar: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      paddingBottom: 0
    },

    links: {
      marginLeft: -theme.spacing.md,
      marginRight: -theme.spacing.md
    },

    linksInner: {
      paddingTop: theme.spacing.lg,
      paddingBottom: theme.spacing.lg
    }
  };
});
