import { Text } from "../../../components-ui";
import { FileSearch } from "tabler-icons-react";
import { useThemeColors } from "../../../hooks/useThemeColors";

export const IdNotFoundMessage = ({ id }: { id: string }) => {
  const { rejectedIconColor, grayColor } = useThemeColors();

  return (
    <>
      <FileSearch
        size={18}
        color={rejectedIconColor}
        style={{ marginRight: -12 }}
      />
      <Text size="xs" testid={"cid-not-found-" + id} color={grayColor}>
        ID:{" "}
        <b
          style={{
            color: grayColor,
          }}
        >
          {id}
        </b>
        not found!
      </Text>
    </>
  );
};
