import {useState} from 'react'
import { useMantineTheme } from '@mantine/core';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useDashboardContext } from './state';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { layoutsAtom } from './state/context'
import { useRecoilState } from 'recoil';
import { ILayoutItem, } from './state/models';


const ResponsiveGridLayout = WidthProvider(Responsive);

export interface IDashboardGridProps {
  name?: string;
}

const configureLayouts = (layouts: any, widgets: any) => {
  let copyLayout = Object.assign({}, layouts)

  for (const bp in copyLayout) {
    if (copyLayout[bp].length > 0){

      let nl = [] as ILayoutItem[]
      copyLayout[bp].forEach((item: ILayoutItem) => {

        let copyItem = Object.assign({}, item)
        if (widgets[item.i] !== undefined) {
          copyItem.h = widgets[item.i].h;
          copyItem.w = widgets[item.i].w;
          nl.push(copyItem)
        }
      });

      copyLayout[bp] = nl
    }
  }
 
  return copyLayout
}

export function DashboardGrid(props: IDashboardGridProps) {

  const theme = useMantineTheme();
  const [localLayouts, setLocalLayouts] = useRecoilState(layoutsAtom);
  const [layouts, setLayouts] = useState(JSON.parse(JSON.stringify(localLayouts)))

  const {
    state: { gridComponents, layoutLocked, widgets },
    actions: {SetBreakpoint}
  } = useDashboardContext();

  const onLayoutChange = (layout: any, layouts: any) => {
    if (Object.keys(widgets).length === 0) return
    let configuredLayouts = configureLayouts(layouts, widgets)
    setLocalLayouts(configuredLayouts);
    setLayouts(configuredLayouts)
  }

  return (
    <ResponsiveGridLayout
      style={{
        backgroundColor:
          theme.colorScheme === 'dark'
            ? layoutLocked
              ? '#030315'
              : '#0f0f35'
            : layoutLocked
            ? '#C7D9EE'
            : '#a5cddb',
        height: '100%'
      }}
      layouts={layouts}
      margin={[15, 5]}
      containerPadding={[15, 15]}
      onLayoutChange={(layout, layouts)=>onLayoutChange(layout,layouts)}
      breakpoints={{ lg: 1400, md: 996, sm: 768 }}
      onBreakpointChange={(bp: 'lg' | 'md' | 'sm') => SetBreakpoint(bp)}
      cols={{ lg: 8, md: 6, sm: 4 }}
      rowHeight={30}
      isResizable={false}
      isDraggable={!layoutLocked}
      // width={width}
    >
      {gridComponents.map((item) => {
        return <div key={item.key}>{item.component}</div>;
      })}
    </ResponsiveGridLayout>
  );
}
