import { Button } from "@mantine/core";
import { Link } from "react-router-dom";
import { Help } from "tabler-icons-react";

const GoToHelpButton = ({ tab, item }: { tab?: string; item?: string }) => {
  const itemParam = item ? `&item=${item}` : "";
  const gotourl = tab ? `/help?tab=${tab}${itemParam}` : "/help";

  return (
    <Button
      leftIcon={<Help />}
      component={Link}
      to={gotourl}
      target="_blank"
      data-testid="go-help"
    >
      Go to Help Page
    </Button>
  );
};

export default GoToHelpButton;
