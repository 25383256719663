import {
  Drawer,
  Group,
  Space,
  Stack,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useRecoilState } from "recoil";
import { Settings } from "tabler-icons-react";
import { Button } from "../Button";
import { Switch } from "../Switch";
import { showTooltipsAtom } from "../Tooltip/state";
import { navCloseOnSelectAtom } from "../Navbar/state";
import { Tooltip } from "../Tooltip";
import { useTID } from "../../hooks/useTestId";
import { useThemeColors } from "../../hooks/useThemeColors";

interface IDrawerSettings {
  testid: string;
}

export const DrawerSettings = (props: IDrawerSettings) => {
  const [opened, handlers] = useDisclosure(false);
  const [showTooltips, SetShowTooltips] = useRecoilState(showTooltipsAtom);
  const [navCloseOnSelect, SetNavCloseOnSelect] =
    useRecoilState(navCloseOnSelectAtom);
  const TID = useTID("DrawerSettings", props.testid);
  const theme = useMantineTheme();
  const { headerIconColor, headerTooltipColor } = useThemeColors();

  return (
    <>
      <Drawer
        closeOnClickOutside
        closeOnEscape
        onClose={() => handlers.close()}
        opened={opened}
        padding="xl"
        position="right"
        title="Settings"
        size={300}
        withCloseButton={false}
      >
        <Space h="xl" />
        <Stack>
          <Switch
            checked={navCloseOnSelect}
            label={`${
              navCloseOnSelect ? "" : "Do NOT "
            }Close Navbar when clicking a selection.`}
            testid="close-navbar"
            onChange={() => {
              SetNavCloseOnSelect(!navCloseOnSelect);
            }}
          />
        </Stack>
        <Space h="xl" />
        <Stack>
          <Switch
            checked={showTooltips}
            label={`${showTooltips ? "" : "Do NOT "}Show Tooltips.`}
            testid="show-tooltips"
            onChange={() => {
              SetShowTooltips(!showTooltips);
            }}
          />
        </Stack>
        <Space h="xl" />
        <Group position="right">
          <Button
            base="cancel"
            testid="DrawerSettingsCancel"
            onClick={() => {
              handlers.close();
            }}
          >
            Close
          </Button>
        </Group>
      </Drawer>

      <Group>
        <Tooltip
          label={`Toggle Settings panel`}
          withArrow={false}
          color={headerTooltipColor}
        >
          <Group>
            <UnstyledButton {...TID} onClick={() => handlers.open()} mr={40}>
              <Settings
                size={24}
                color={opened ? theme.colors.blue[5] : headerIconColor}
              />
            </UnstyledButton>
          </Group>
        </Tooltip>
      </Group>
    </>
  );
};

export default DrawerSettings;
