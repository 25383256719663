import { Upload, Help, AlertTriangle, InfoSquare } from "tabler-icons-react";
import { IDelivery } from "../../models/myUploads";

export const getLinks = (isAdmin: boolean, myFailedUploads: IDelivery[]) => {
  return isAdmin ? [
    { label: "Promo Upload", icon: Upload, path: "/" },
    { label: "My Uploads", icon: myFailedUploads.length ? AlertTriangle : InfoSquare, path: "/my-uploads" },
    { label: "All Uploads", icon: InfoSquare, path: "/all-uploads" },
    { label: "Help", icon: Help, path: "/help" },
  ] : [
    { label: "Promo Upload", icon: Upload, path: "/" },
    { label: "My Uploads", icon: myFailedUploads.length ? AlertTriangle : InfoSquare, path: "/my-uploads" },
    { label: "Help", icon: Help, path: "/help" },
  ];
};