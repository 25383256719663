import { Group, ScrollArea, useMantineTheme } from "@mantine/core";
import { Switch, Text } from "../../../components-ui";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import DisplayAcceptedFiles from "../../../components/BulkUpload/DisplayInfo/DisplayAcceptedFiles";
import { useAuthRestrictions } from "../../../hooks/useAuthRestrictions";
import { filesAcceptedAtom } from "../../../recoil/bulkupload/atoms";
import { SortFilesButton } from "../../../components/BulkUpload/DisplayInfo/SortFilesButton";

export default function LeftColumnContainer({
  height,
  className,
  wrapperStyle,
}: {
  height: number;
  className: string;
  wrapperStyle: React.CSSProperties;
}) {
  const filesAccepted = useRecoilValue(filesAcceptedAtom);
  const [expandedMetadata, setExpandedMetadata] = useState(true);
  const { metadataAuth } = useAuthRestrictions();
  const theme = useMantineTheme();
  const greenAcceptedColor =
    theme.colorScheme === "dark"
      ? theme.colors.green[6]
      : theme.colors.green[8];
  const [ascending, setAscending] = useState<boolean>(true);

  const toggleOrder = (): void => {
    if (ascending) {
      setAscending(false);
    } else {
      setAscending(true);
    }
  };

  return (
    <div className={className} style={wrapperStyle}>
      <Group position="apart" mb={5}>
        <Group>
          <Text
            size="md"
            color={greenAcceptedColor}
            weight={700}
            testid="accepted-items"
          >
            Accepted Items
          </Text>
          {filesAccepted.length > 0 && (
            <SortFilesButton
              ascending={ascending}
              acceptedColumn={true}
              color={greenAcceptedColor}
              toggleOrder={toggleOrder}
            />
          )}
        </Group>
        {metadataAuth && filesAccepted.length > 0 && (
          <Switch
            label="Expand Metadata"
            color="green"
            checked={expandedMetadata}
            onChange={() => setExpandedMetadata((expanded) => !expanded)}
            testid="Metadata-expand"
          />
        )}
      </Group>
      <ScrollArea
        style={{ height: height - 55 }}
        scrollHideDelay={0}
        offsetScrollbars
      >
        <DisplayAcceptedFiles
          expanded={expandedMetadata}
          ascending={ascending}
        />
      </ScrollArea>
    </div>
  );
}
