import { TransferSpec } from "@ibm-aspera/connect-sdk-js/dist/esm/core/types";

//Common
export interface File {
  path: string;
}

export interface AdditionalMetadata {
  videoType: string;
  slateProfileId: string;
}

export interface CreatingLibrary {
  name: string;
  version: string;
  url: string;
}

export interface Extra {
  CodecConfigurationBox: string;
}

export interface Track {
  "@type": string;
  VideoCount: string;
  FileExtension: string;
  Format: string;
  Format_Profile: string;
  CodecID: string;
  CodecID_Compatible: string;
  FileSize: string;
  Duration: string;
  OverallBitRate: string;
  FrameRate: string;
  FrameCount: string;
  StreamSize: string;
  HeaderSize: string;
  DataSize: string;
  FooterSize: string;
  IsStreamable: string;
  StreamOrder: string;
  ID: string;
  Format_Level: string;
  Format_Settings_CABAC: string;
  Format_Settings_RefFrames: string;
  BitRate: string;
  Width: string;
  Height: string;
  Sampled_Width: string;
  Sampled_Height: string;
  PixelAspectRatio: string;
  DisplayAspectRatio: string;
  Rotation: string;
  FrameRate_Mode: string;
  FrameRate_Mode_Original: string;
  ColorSpace: string;
  ChromaSubsampling: string;
  BitDepth: string;
  ScanType: string;
  extra: Extra;
  Format_AdditionalFeatures: string;
  BitRate_Mode: string;
  Channels: string;
  ChannelPositions: string;
  ChannelLayout: string;
  SamplesPerFrame: string;
  SamplingRate: string;
  SamplingCount: string;
  Compression_Mode: string;
  StreamSize_Proportion: string;
}

export interface Media {
  "@ref": string;
  //TODO: set correct Track interface
  //track: Track[];
  track: any[];
}

export interface MediaInfo {
  creatingLibrary: CreatingLibrary;
  media: Media;
}

export interface Metadata {
  additionalMetadata: AdditionalMetadata;
  mediaInfo: MediaInfo;
}

export interface Component {
  file: File;
  metadata: Metadata;
}

//Delivery
export interface DeliveryRequest {
  contentGroupingId: string;
  manifestationType: ManifestationType;
  languageCode: string;
}

export enum ManifestationType {
  Master = "master",
  Submaster = "submaster",
}

export interface DeliveryResponse {
  deliveryId: string;
  contentGroupingId: string;
  state: string;
  components: Component[];
}

//Component
export interface ComponentRequest {
  components: Component[];
}

export interface ComponentResponse {
  deliveryId: string;
  componentId: string;
  state: string;
  metadata: Metadata;
}

//Transfer
export interface DeliveryTransferRequest {
  componentIds: string[];
}

export interface DeliveryTransferResponse {
  transfer_spec: TransferSpec;
}

export interface Path {
  source: string;
  destination: string;
}

export interface Node {
  access_key: string;
}

export interface Aspera {
  node: Node;
}

export interface Tags {
  aspera: Aspera;
  DeliveryId: string;
}
