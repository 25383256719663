import { GridReadyEvent, IServerSideDatasource, LoadSuccessParams } from "ag-grid-community";
import { DeliveryService } from "../../services/delivery.service";
import { IDownloadDeliveries } from "../../components/Uploads/DownloadDeliveries";
import { IDelivery } from "../../models/myUploads";

export const getRowId = (data: any): string => {
    return data.data.cid + data.data.date;
};

export const gridReadyCallback = (params: GridReadyEvent, allUploads: boolean, setDeliveriesModel: (params: IDownloadDeliveries) => void, setError: (params: boolean) => void) => { 
    const dataSource: IServerSideDatasource = {
      getRows: async (params) => {
        try {
          const response = await DeliveryService.getDeliveriesWithParams(params.request, allUploads)
          setDeliveriesModel({ request: params.request, totalRows: response.total });
          const successParams: LoadSuccessParams = {
            rowData: response.data,
            rowCount: response.total,
          };
          params.success(successParams);
        } catch (error) {
          setError(true);
          params.fail();
        }
      },
    };

    params.api.setServerSideDatasource(dataSource);
  }

export const isRowMaster = (dataItem: IDelivery) => {
    return dataItem ? dataItem.components.length > 0 : false;
  };