import { Navbar, ScrollArea } from '@mantine/core';
import { iLinksGroupProps, LinksGroup } from './NavbarLinksGroup';
import { useViewportSize } from '@mantine/hooks';
// import {useTID} from '../../helpers/useTestId'
import useStyles from './NavbarNestedStyles';

interface INavbarNested {
  links: iLinksGroupProps[];
  testid: string;
}

export function NavbarNested(props: INavbarNested) {
  const { classes } = useStyles();
  const links = props.links.map((item) => <LinksGroup {...item} key={item.label} />);
  const { height } = useViewportSize();
  const h = height - 90;
  // const TID = useTID('NavbarNested', props.testid)

  return (
    <Navbar height={h} width={{ sm: 300 }} p="md" className={classes.navbar}>
      <Navbar.Section mt={-24} grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>
    </Navbar>
  );
}
