import { Stack } from "@mantine/core";
import { Text } from "../../../components-ui";
import { useThemeColors } from "../../../hooks/useThemeColors";

export const UploadingFilesBox = ({
  dropZoneMinHeight,
}: {
  dropZoneMinHeight: number;
}) => {
  const { dividerColor } = useThemeColors();

  return (
    <Stack
      align="center"
      justify="center"
      spacing="xl"
      style={{
        minHeight: dropZoneMinHeight,
        border: "1px solid #aaa",
        background: dividerColor,
        borderRadius: 8,
      }}
    >
      <Text size="xl" inline testid="uploading-files">
        Uploading Files...
      </Text>
      <Text
        align="center"
        size="sm"
        color="dimmed"
        inline
        mt={7}
        testid="adding-files-disabled"
      >
        Adding files disabled until Aspera upload begins.
      </Text>
    </Stack>
  );
};
