import {
  createStyles,
  Switch,
  Group,
  useMantineColorScheme,
} from "@mantine/core";
import { Sun, Moon } from "tabler-icons-react";
import { Tooltip } from "../Tooltip";
import { useTID } from "../../hooks/useTestId";

const useStyles = createStyles((theme) => ({
  root: {
    position: "relative",
    "& *": {
      cursor: "pointer",
    },
  },

  icon: {
    pointerEvents: "none",
    position: "absolute",
    zIndex: 1,
    top: 3,
  },

  iconLight: {
    left: 4,
    color: theme.white,
  },

  iconDark: {
    right: 4,
    color: theme.colors.gray[6],
  },
}));

interface ISwitchThemeProps {
  testid: string;
}

export function SwitchTheme(props: ISwitchThemeProps) {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  const { classes, cx } = useStyles();
  const TID = useTID("SwitchTheme", props.testid);

  return (
    <Group>
      <Tooltip label={`Toggle theme to ${dark ? "light" : "dark"}`} withArrow>
        <Group position="center" my={30} {...TID}>
          <div className={classes.root}>
            <Sun className={cx(classes.icon, classes.iconLight)} size={18} />
            <Moon className={cx(classes.icon, classes.iconDark)} size={18} />
            <Switch
              checked={dark}
              onChange={() => toggleColorScheme()}
              size="md"
            />
          </div>
        </Group>
      </Tooltip>
    </Group>
  );
}
