import { Anchor, Group, Stack, Table } from "@mantine/core";
import { Badge, Text, Title } from "../../../../components-ui";
import { ValidationErrorType } from "../../../../helpers/filesValidation/validationHelper";
import { allActualValuesEmptyNoWrapperCodec } from "../../../../helpers/mediaconch/mediaconchHelper";
import { IFileRejectedReason } from "../../../../models/bulkUpload";
import ErrorModal from "./ErrorModal";

const PolicyRejectionModal = ({
  modalOpened,
  rejectedReasons,
  filename,
  setModalOpen,
}: {
  modalOpened: boolean;
  filename: string;
  rejectedReasons: IFileRejectedReason[] | undefined;
  setModalOpen: any;
}) => {
  return (
    <ErrorModal
      modalOpened={modalOpened}
      setModalOpen={setModalOpen}
      filename={filename}
      errorType={ValidationErrorType.PolicyCheck}
    >
      <Stack align="flex-start" justify="flex-start">
        {rejectedReasons &&
        rejectedReasons.length > 0 &&
        !allActualValuesEmptyNoWrapperCodec(rejectedReasons) ? (
          <Table>
            <thead>
              <tr>
                <td>
                  <Title order={4} testid="rejected-policy">
                    Policy
                  </Title>
                </td>
                <td>
                  <Title order={4} testid="rejected-expected">
                    Expected
                  </Title>
                </td>
                <td>
                  <Title order={4} testid="actual">
                    Actual
                  </Title>
                </td>
              </tr>
            </thead>
            <tbody>
              {rejectedReasons.map((reason) => {
                return (
                  <tr key={filename + reason.title}>
                    <td>
                      <Text testid={reason.title}>{reason.title}</Text>
                    </td>
                    <td>
                      <Text testid={reason.title + "-expected"}>
                        {reason.expected &&
                          reason.expected.map((expected) => {
                            return (
                              <Badge
                                color="green"
                                size="lg"
                                testid={expected}
                                key={filename + expected}
                              >
                                {expected}
                              </Badge>
                            );
                          })}
                      </Text>
                    </td>
                    <td>
                      <Text testid={reason.title + "actual"}>
                        {reason.actual && (
                          <Badge color="red" size="lg" testid={reason.actual}>
                            {reason.actual}
                          </Badge>
                        )}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Group position="left" style={{ padding: 16, marginTop: 5 }}>
            <Text testid="rejected-policy-failed">
              Policy check failed, make sure your file meets the expected
              specifications.
            </Text>
            <Text testid="rejected-policy-detail1">
              You can check the requirements in the following{" "}
              <Anchor
                href="https://partnerhub.warnermediagroup.com/specifications-and-guides/promotionals"
                target="_blank"
              >
                link
              </Anchor>
              .
            </Text>
          </Group>
        )}
      </Stack>
    </ErrorModal>
  );
};

export default PolicyRejectionModal;
