import { osWindows } from "./dragAndDropHelper";

const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

export const formatBytes = (size: number) => {
  let unitsIndex = 0;

  while (size >= 1024 && ++unitsIndex) {
    size = size / 1024;
  }

  return (
    size.toFixed(size < 10 && unitsIndex > 0 ? 1 : 0) + " " + units[unitsIndex]
  );
};

export const filenameFromPath = (path: string): string => {
  const filenameSplit = path.split(getSplitSymbol());
  return filenameSplit[filenameSplit.length - 1];
};

const getSplitSymbol = (): string => {
  return osWindows() ? "\\" : "/";
};
