import { Badge as MBadge, BadgeProps } from "@mantine/core";
import { useTID } from "../../hooks/useTestId";

interface IBadgeProps extends BadgeProps {
  testid: string;
}

export const Badge = (props: IBadgeProps) => {
  const TID = useTID("Badge", props.testid);

  return (
    <MBadge {...TID} {...props}>
      {props.children}
    </MBadge>
  );
};
