import { asperaWeb } from "../Aspera/AsperaConnect";
import { TransferSpec } from "@ibm-aspera/connect-sdk-js/dist/esm/core/types";

export const startAsperaTransfer = async (transferSpec: TransferSpec) => {
  if (transferSpec) {
    await asperaWeb.startTransferPromise(transferSpec, {});
  } else {
    const error = "ERROR: Empty transferSpec";
    console.log(error);
    throw error;
  }
};
