import { base64StringToBlob } from "blob-util";

export const b64toBlob = (b64Data, contentType = "") => {
  return base64StringToBlob(b64Data, contentType);
};

export const b64toBlobCustom = (b64Data, contentType = "") => {
  var sliceSize = 1024;
  var byteCharacters = window.atob(b64Data);

  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    var begin = sliceIndex * sliceSize;
    var end = Math.min(begin + sliceSize, bytesLength);

    var bytes = new Array(end - begin);
    for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
};

export const b64toBlobSimple = (b64Data, contentType = "") => {
  const byteArray = Uint8Array.from(
    window
      .atob(b64Data)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
  return new Blob([byteArray], { type: contentType });
};
