import { Stack } from "@mantine/core";
import { Button, Text, Title } from "../components-ui";
import { useNavigate } from "react-router-dom";
import { Home } from "tabler-icons-react";

export default function NotAuthorizedPage() {
  let navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  return (
    <Stack
      align="center"
      justify="center"
      spacing="xl"
      style={{
        minHeight: 600,
      }}
    >
      <Title order={1} testid="401-not-authorized">
        401 Not Authorized
      </Title>
      <Text size="md" testid="401-sorry">
        You do not have permission to view this page.
      </Text>
      <Button leftIcon={<Home />} onClick={goHome} testid="401-go-home">
        Go Home
      </Button>
    </Stack>
  );
}
