import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";

export enum UserRole {
  Internal = "Internal User",
  External = "External User",
  NoRole = "",
  DeliveriesAdmin = "Deliveries Admin"
}

export const getUserRoles = (claims: UserClaims): string[] => {
  // @ts-ignore
  const groups: string[] = claims.DeliveryPortal_groups;
  let allRoles: string[] = [];

  if (groups && groups.length > 0) {
    const allRolesArray: string[][] = [];
    groups.forEach((group) => {
      const splitedGroup = group.split("|");
      allRolesArray.push(splitedGroup);
    });

    //Remove duplicates
    const roleSet = new Set(allRolesArray.flat());
    allRoles = Array.from(roleSet.values());
  }
  return allRoles;
};

const isAdminAuth = (roles: string[]): boolean => {
  //Constains Role
  return roles.indexOf(UserRole.DeliveriesAdmin) > -1;
};

export const useAdminAuthRestrictions = () => {
  const { authState } = useOktaAuth();
  let isAdmin = false;

  if (authState && authState.isAuthenticated) {
    const userRoles = getUserRoles(authState.accessToken?.claims!);

    isAdmin = isAdminAuth(userRoles);
  }
  return { isAdmin };
};