import { httpServices } from "./httpCommon";
import {
  ComponentRequest,
  ComponentResponse,
  DeliveryRequest,
  DeliveryResponse,
  DeliveryTransferRequest,
  DeliveryTransferResponse,
} from "../models/delivery";
import { IServerSideGetRowsRequest } from "ag-grid-community";
import { IMyUploads } from "../models/myUploads";
import { DeliveryFileObject, ValidatedFileResponse } from "../helpers/upload/deliveryHelperModels";
import { cloneDeep } from "lodash";
import { AxiosResponse } from "axios";
import { fixDateFilter, fixStateFilter } from "./deliveryServiceHelper";

export const DeliveryService = {
  async postCreateDelivery(request: DeliveryRequest) {
    return httpServices.post<DeliveryResponse>("/delivery", request);
  },

  async getDeliveryById(deliveryId: string) {
    return httpServices.get<DeliveryResponse>(`/delivery/${deliveryId}`);
  },

  async postAddInventoryComponent(
    deliveryId: string,
    request: ComponentRequest
  ) {
    return httpServices.post<ComponentResponse[]>(
      `/delivery/${deliveryId}/component`,
      request
    );
  },

  async postDeliveryFinalize(deliveryId: string) {
    return httpServices.post<ComponentResponse>(
      `/delivery/${deliveryId}/finalize`
    );
  },

  async postDeliveryTransfer(request: DeliveryTransferRequest) {
    return httpServices.post<DeliveryTransferResponse>(`/transfer`, request);
  },

  async validateIds(files: DeliveryFileObject[]): Promise<AxiosResponse<ValidatedFileResponse[]>> {
    return httpServices.post<ValidatedFileResponse[]>(
        `/validatefiles`, { files }
    ).catch(error => {
        return Promise.resolve(error.response);
    });
  },

  async getDeliveriesWithParams(requestParams: IServerSideGetRowsRequest, admin: boolean = false, shouldFixDateFilter: boolean = true): Promise<IMyUploads> {
    const params = admin ? {params: {'admin': true}} : undefined;
    const request = cloneDeep(requestParams); // We don't want updates to the model to bubble back to AG Grid
    if (shouldFixDateFilter && request.filterModel && request.filterModel.date) {
      fixDateFilter(request.filterModel.date)
    }

    if (request.filterModel && request.filterModel.state) {
      fixStateFilter(request.filterModel.state);
    }

    return (await httpServices.post<IMyUploads>(`/deliveries/search`, request, params)).data;
  },
};
