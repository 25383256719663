import { useMantineTheme } from "@mantine/core";
import { FileLike, FileTime } from "tabler-icons-react";

export const FileIcon = ({ pending }: { pending: boolean }) => {
  const theme = useMantineTheme();

  return pending ? (
    <FileTime
      color={
        theme.colorScheme === "dark"
          ? theme.colors.blue[4]
          : theme.colors.blue[6]
      }
    />
  ) : (
    <FileLike color="green" />
  );
};
