import { useRecoilValue } from "recoil";
import { useAdminAuthRestrictions } from "../../helpers/authHelper";
import { NavbarNested as BPNavbar } from "../NavbarNested";
import { getLinks } from "./links";
import { myFailedUploadsAtom } from "../../recoil/uploads/atoms";

export const Navbar = () => {
  const myFailedUploads = useRecoilValue(myFailedUploadsAtom);
  const { isAdmin } = useAdminAuthRestrictions();
  
  return <BPNavbar links={getLinks(isAdmin, myFailedUploads)} testid="Navbar" />;
};

export default Navbar;
