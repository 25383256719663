import { DeliveryFileObject } from "../upload/deliveryHelperModels";

export const idInFile = (file: DeliveryFileObject): string => {
  let fileId = "";

  // Extract the file name from the path
  let fileName = file.name.split(/[\\/]/).pop();

  // Remove the file extension
  fileName = fileName ? fileName.split(".").slice(0, -1).join(".") : "";

  // remove _SUB and _sub from the end of the file name
  const cleanFileName = fileName.replace(/_sub$|_SUB$/i, "");

  // if it has a _ then the id is the last part of the file name
  if (cleanFileName.includes("_")) {
    const lastUnderscoreIndex = cleanFileName.lastIndexOf("_");
    fileId = cleanFileName.substring(lastUnderscoreIndex + 1);
  } else {
    fileId = cleanFileName;
  }

  return fileId;
};
