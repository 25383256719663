import { List } from "@mantine/core";
import { Text } from "../../components-ui";

export default function FileFormatNotSupportedHelp() {
  return (
    <>
      <Text size="sm" testid={"Help-FileFormatNotSupported-Description1"}>
        Current accepted file types are <b>Video, Image and Text</b>.
      </Text>
      <List ml={25}>
        <List.Item data-testid={":Help-FileFormatNotSupported-Description2"}>
          Video: mov, mxf
        </List.Item>
        <List.Item data-testid={":Help-FileFormatNotSupported-Description3"}>
          Image: jpeg, jpg, png
        </List.Item>
        <List.Item data-testid={":Help-FileFormatNotSupported-Description4"}>
          Text: scc
        </List.Item>
      </List>
    </>
  );
}
