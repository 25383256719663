import {idInFile} from "./IdInFile";
import {
    IPromoMetadata,
    MapPromoMetadata,
} from "../../models/metadata";
import {DeliveryFileObject, ValidatedFileResponse} from "../upload/deliveryHelperModels";
import {addRejectedFile, validateContentLengthAgainstSlateProfile, ValidationErrorType} from "./validationHelper";
import {useMediaconch} from "../mediaconch/useMediaconch";
import {asperaWeb} from "../Aspera/AsperaConnect";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {
    droppedFilesWorkaroundAtom,
    filesAcceptedAtom,
    loadingNeededDataAtom,
    selectedSlateProfileAtom,
    unexpectedErrorAtom,
} from "../../recoil/bulkupload/atoms";
import {
    IPolicyCheckFiles,
    IPolicyCheckResult,
    IRejectedFiles,
} from "../../models/bulkUpload";
import { showNotification } from "@mantine/notifications";

export const useFilesValidationSteps = () => {
    const filesAccepted = useRecoilValue(filesAcceptedAtom);
    const setLoadingNeededData = useSetRecoilState(loadingNeededDataAtom);
    const setUnexpectedError = useSetRecoilState(unexpectedErrorAtom);
    const droppedFilesWorkaround = useRecoilValue(droppedFilesWorkaroundAtom);
    const slateProfile = useRecoilValue(selectedSlateProfileAtom);
    const {mediaconchPolicyCheck, mediaInfoReport} = useMediaconch(
        asperaWeb,
        setLoadingNeededData,
        setUnexpectedError
    );

    const initializeFilesById = (
        files: DeliveryFileObject[],
        validations: ValidatedFileResponse[],
        rejectedFiles: IRejectedFiles[],
        bulkMetadata: IPromoMetadata[]
    ): Map<string, DeliveryFileObject[]> => {
        let filesById: Map<string, DeliveryFileObject[]> = new Map();

        //Initialize accepted Map with exisiting Accepted files
        filesAccepted.forEach((accepted) => {
            filesById.set(accepted.id, [
                ...accepted.files,
                ...accepted.submasterFiles,
            ]);
        });

        files.forEach(async (file) => {
            let fileId = idInFile(file);

            if (fileId === "") {
                addRejectedFile(
                    rejectedFiles,
                    "",
                    file,
                    ValidationErrorType.IdNotFoundInFile
                );
            } else {
                const validation = validations.find(x => x.requestIdentifier.id === fileId);
                if(!validation || !validation.isValid) {
                    addRejectedFile(rejectedFiles, fileId, file, validation?.errorMessage || "Validation result not found")
                } else {
                    bulkMetadata.push(MapPromoMetadata(validation.title, validation.requestIdentifier))
                    if (filesById.has(fileId)) {
                        const existingFiles = filesById.get(fileId)!;
                        filesById.set(fileId, [...existingFiles, file]);
                    } else {
                        filesById.set(fileId, [file]);
                    }
                }
            }
        });

        return filesById;
    };

    const mediaconchAlreadyDone = (
        id: string,
        deliveryFile: DeliveryFileObject
    ) => {
        const acceptedForId = filesAccepted.filter((x) => x.id === id);
        if (acceptedForId.length > 0) {
            const fileExists = acceptedForId[0].files.filter(
                (x) => x.name === deliveryFile.name
            );
            return fileExists.length > 0;
        }
        return false;
    };

    const checkMediaconchPolicy = async (
        id: string,
        deliveryFiles: DeliveryFileObject[]
    ): Promise<IPolicyCheckFiles> => {
        let policyCheck: IPolicyCheckFiles = {
            accepted: [],
            rejected: [],
        };

        for await (const deliveryFile of deliveryFiles) {
            if (mediaconchAlreadyDone(id, deliveryFile)) {
                policyCheck.accepted.push(deliveryFile);
            } else {
                const policyCheckResult: IPolicyCheckResult = await mediaconchPolicyCheck(deliveryFile, droppedFilesWorkaround);
                policyCheckResult.accepted
                    ? policyCheck.accepted.push(deliveryFile)
                    : policyCheck.rejected.push({
                        file: deliveryFile,
                        rejectedReasons: policyCheckResult.rejectedReasons
                            ? policyCheckResult.rejectedReasons
                            : [],
                    });
            }
        }

        return policyCheck;
    };

    const addMediaInfoMetadata = async (
        accepted: DeliveryFileObject[],
        rejectedFiles: IRejectedFiles[],
        metadata: IPromoMetadata
    ): Promise<DeliveryFileObject[]> => {
        let filesWithMediaInfo: DeliveryFileObject[] = [];
        for await (const file of accepted) {
            if (mediaconchAlreadyDone(metadata.id, file)) {
                filesWithMediaInfo.push(file);
            } else {
                const mediainfoMetadata = await mediaInfoReport(file);

                if (mediainfoMetadata !== undefined) {
                    const result = validateContentLengthAgainstSlateProfile(mediainfoMetadata, metadata, slateProfile);
                    if (!result.isValid) {
                        showNotification({
                            color: "yellow",
                            title: "Check Your Slate Profile Selection",
                            message: result.message,
                            autoClose: false
                        });
                    }
                    const fileCopy = {
                        lastModifiedDate: file.lastModifiedDate,
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        mediaInfo: undefined,
                    };
                    fileCopy.mediaInfo = mediainfoMetadata;
                    filesWithMediaInfo.push(fileCopy);
                } else {
                    addRejectedFile(
                        rejectedFiles,
                        metadata.id,
                        file,
                        ValidationErrorType.MediaInfoNoMetadata
                    );
                }
            }
        }

        return filesWithMediaInfo;
    };

    return {
        initializeFilesById,
        checkMediaconchPolicy,
        addMediaInfoMetadata,
    };
};
