import { Anchor, Box, List, ThemeIcon, TypographyStylesProvider } from "@mantine/core";
import { Text } from "../../components-ui";
import { IconCircleCheck, IconCheckbox } from "@tabler/icons";
import { foundryColors } from "../../theme/globalStyles";

export default function GeneralInfoHelp() {
  return (
    <Box ml={15}>
      <Text size="md" testid={"Help-General-Troubleshooting"} mb={10}>
        If you are encountering general issues with the portal, its validation
        and uploading, please try the following troubleshooting steps before
        retrying your upload:
      </Text>
      <List
        withPadding
        spacing="xs"
        size="sm"
        icon={
          <ThemeIcon color={foundryColors.cornflowerBlue} size={24} radius="xl">
            <IconCircleCheck size={16} />
          </ThemeIcon>
        }
      >
        <List.Item data-testid={"Help-General-Troubleshooting-Description1"}>
          Clear browser cache, exit and restart browser
        </List.Item>
        <List.Item data-testid={"Help-General-Troubleshooting-Description2"}>
          Restart your computer or virtual instance
        </List.Item>
        <List.Item data-testid={"Help-General-Troubleshooting-Description3"}>
          Log out of CDP and log back in
        </List.Item>
        <List.Item data-testid={"Help-General-Troubleshooting-Description4"}>
          Please allow adequate time for file to go through validation process
          before drag and dropping/selecting another file for validation and
          upload
        </List.Item>
        <List.Item data-testid={"Help-General-Troubleshooting-Description5"}>
          Ensure that all requirements for portal access are met:
          <List
            withPadding
            spacing="xs"
            size="sm"
            icon={
              <ThemeIcon color={foundryColors.cornflowerBlue} size={20}>
                <IconCheckbox size={16} />
              </ThemeIcon>
            }
            mt={10}
          >
            <List.Item
              data-testid={"Help-General-Troubleshooting-Description6"}
            >
              Compatible browser
            </List.Item>
            <List.Item
              data-testid={"Help-General-Troubleshooting-Description7"}
            >
              Aspera installation
            </List.Item>
            <List.Item
              data-testid={"Help-General-Troubleshooting-Description8"}
            >
              Portal access permissions
            </List.Item>
            <List.Item
              data-testid={"Help-General-Troubleshooting-Description9"}
            >
              Correct URL
            </List.Item>
          </List>
        </List.Item>
      </List>
      <Text size="sm" testid={"Help-Content-Partner-Hub"}>
        <TypographyStylesProvider>
          <h4>Content Partner Hub</h4>
          <p>
            For more in depth guidance on file specifications or on how
            to use the Promo Exchange, please refer to the <Anchor href="https://partnerhub.warnermedia.com/specifications-and-guides/promotionals" target="_blank">
            Content Parner Hub. </Anchor>
          </p>
        </TypographyStylesProvider>
      </Text>
    </Box>
  );
}
