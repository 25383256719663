import { useEffect } from "react";
import { Headerbar } from "../components-ui";
import UploadsContainer from "../containers/UploadsContainer/UploadsContainer";
import { LocalStorageService } from "../services/localStorage.service";
import { LocalStorageKeys } from "../models/common";
import { useSetRecoilState } from "recoil";
import { myFailedUploadsAtom } from "../recoil/uploads/atoms";
import { hideNotification } from "@mantine/notifications";
import moment, { Moment } from "moment";

export default function MyUploadsPage() {
  const setMyFailedUploads = useSetRecoilState(myFailedUploadsAtom);
  const crumbs = [
    { title: "Promo Upload", path: "/" },
    { title: "My Uploads", path: "/my-uploads" },
  ];

  useEffect(() => {
    LocalStorageService.setItem<Moment>(LocalStorageKeys.MyUploadsLastVisited, moment().utc());
    setMyFailedUploads([]);
    hideNotification('qc-failures-notification');
  });

  return (
    <>
      <Headerbar crumbs={crumbs} testid="headerBar" rightSection={<></>} />
      <UploadsContainer title="My Uploads"/>
    </>
  );
}
