import { forwardRef, ReactNode } from "react";

type WrappedElementForTootltipProps = { children: ReactNode };

export const WrappedElementForTootltip = forwardRef<
  HTMLDivElement,
  WrappedElementForTootltipProps
>((props, ref) => (
  <div ref={ref} {...props}>
    {props.children}
  </div>
));
