import { atom } from 'recoil';

export const activeItemAtom = atom<string>({
  key: 'navbarnested2/activeItem',
  default: '/'
});

export const activeLinkAtom = atom<string>({
  key: 'navbarnested2/activeLink',
  default: '/'
});
