import { DeliveryFileObject } from "../upload/deliveryHelperModels";

export enum FileType {
  Video,
  Text,
  Image,
  Unknown,
}

export const isVideoFile = (type: string): boolean => {
  return type.includes("video") || type === "application/mxf";
};

export const getFileTypeFromFile = (
  file: DeliveryFileObject,
  simulatedDragDrop: boolean
): FileType => {
  let type = "";
  if (simulatedDragDrop) {
    type = getTypeFromFilenameExtension(file.name);
  } else {
    type = file.type;
  }

  return getFileType(type);
};

export const getFileType = (type: string): FileType => {
  if (type.includes("text")) {
    return FileType.Text;
  }
  if (isVideoFile(type)) {
    return FileType.Video;
  }
  if (type.includes("image")) {
    return FileType.Image;
  }
  return FileType.Unknown;
};

export const getTypeFromFilenameExtension = (filename: string): string => {
  const extension = getFileExtensionLowerCase(filename);
  let fileType = "";

  switch (extension) {
    case "mov":
    case "mxf":
    case "mp4":
    case "avi":
    case "wmv":
    case "mkv":
      fileType = "video";
      break;
    case "scc":
      fileType = "text";
      break;
    case "jpeg":
    case "jpg":
    case "png":
      fileType = "image";
      break;
  }

  return fileType;
};

export const isRelevantPolicyForType = (
  element: any,
  type: FileType,
  filename: string
): boolean => {
  let result: boolean = false;
  const policyName: string = element.attributes.name;
  const fileExtension = getFileExtensionLowerCase(filename);
  const textPolicies = ["SCC"];
  const imagePolicies = ["JPEG2000"];

  switch (type) {
    case FileType.Video:
      result = isRelevantPolicyForVideo(policyName, fileExtension);
      break;
    case FileType.Text:
      result = textPolicies.includes(policyName);
      break;
    case FileType.Image:
      result = imagePolicies.includes(policyName);
      break;
    case FileType.Unknown:
      break;
  }

  return result;
};

const isRelevantPolicyForVideo = (
  policyName: string,
  fileExtension: string
): boolean => {
  const videoPolicies: string[] = [];
  const videoMovPolicies = ["ProRes", "DNxHD MOV"];
  const videoMxfPolicies = ["DNxHD MXF"];
  let result: boolean = videoPolicies.includes(policyName);

  switch (fileExtension) {
    case "mxf":
      result = result || videoMxfPolicies.includes(policyName);
      break;
    case "mov":
      result = result || videoMovPolicies.includes(policyName);
      break;
    default:
      //allow all video policies for other file extensions
      result =
        result ||
        videoMxfPolicies.includes(policyName) ||
        videoMovPolicies.includes(policyName);
      break;
  }

  return result;
};

export const getFileExtensionLowerCase = (fileName: string): string => {
  let extension = fileName.split(".").pop();
  return extension ? extension.toLowerCase() : "";
};
