import { Stack } from "@mantine/core";
import { Text, Title } from "../components-ui";

export default function MaintenancePage() {
  return (
    <Stack
      align="center"
      justify="center"
      spacing="xl"
      style={{
        minHeight: 600,
      }}
    >
      <Title order={1} testid="maintenance">
        The site is currently down for maintenance
      </Title>
      <Text size="md" testid="maintenance-sorry">
        Sorry for the inconvenience but we're performing some maintenance at the
        moment.
      </Text>
      <Text size="md" testid="maintenance-be-back">
        We'll be back online shortly.
      </Text>
    </Stack>
  );
}
