import { Component, ManifestationType } from "../../models/delivery";
import { ISlateProfile } from "../../models/referenceData";
import { DeliveryService } from "../../services/delivery.service";
import { DeliveryFileObject } from "./deliveryHelperModels";
import { startAsperaTransfer } from "./uploadAsperaHelper";

const mapToComponents = (
  files: DeliveryFileObject[],
  slateProfileId: string
) => {
  const components: Component[] = files.map((file) => ({
    file: { path: file.name },
    metadata: {
      additionalMetadata: {
        videoType: "primary",
        slateProfileId: slateProfileId,
      },
      mediaInfo: file.mediaInfo,
    },
  }));
  return { components: components };
};

export const createDeliveryComponentsAndFinalize = async (
  contentGroupingId: string,
  slateProfile: ISlateProfile,
  deliveryFiles: DeliveryFileObject[],
  manifestationType: ManifestationType,
  languageCode: string
): Promise<string[]> => {
    //create delivery
    const delivery = await DeliveryService.postCreateDelivery({
        contentGroupingId: contentGroupingId,
        manifestationType: manifestationType,
        languageCode: languageCode
    });
    const deliveryId = delivery.data.deliveryId;

    //add inventory components
    const mappedComponents = mapToComponents(deliveryFiles, slateProfile.code);
    const inventoryComponent = await DeliveryService.postAddInventoryComponent(
        deliveryId,
        mappedComponents
    );

    //get componentIds
    const componentIds: string[] = inventoryComponent.data.map(
        (component) => component.componentId
    );

    //finalize deliveries
    await DeliveryService.postDeliveryFinalize(deliveryId);

    return componentIds;
};

export const startFilesUpload = async (componentIds: string[]) => {
    //get transferspec
    const spec = await DeliveryService.postDeliveryTransfer({
        componentIds: componentIds,
    });

    //call aspera with transfer spec
    await startAsperaTransfer(spec.data.transfer_spec);
};
