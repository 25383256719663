import { useRecoilValue } from "recoil";
import { Headerbar } from "../components-ui";
import UnexpectedErrorModal from "../components/UnexpectedErrorModal/UnexpectedErrorModal";
import { BulkUploadContainer } from "../containers";
import { alertsAtom, unexpectedErrorAtom } from "../recoil/bulkupload/atoms";
import { AlertMessage } from "../components";

export default function BulkUploadPage() {
  const crumbs = [{ title: "Promo Upload", path: "/" }];
  const unexpectedError = useRecoilValue(unexpectedErrorAtom);
  const alerts = useRecoilValue(alertsAtom);

  return (
    <>
      <Headerbar
        crumbs={crumbs}
        testid="bulkUploadHeaderBar"
        rightSection={<></>}
      />
      {alerts.map((alert, i) => {
        return <AlertMessage
          key={i} 
          title={alert.label}
          message={alert.description}
          status={alert.code}
        />
      })}
      <BulkUploadContainer />
      <UnexpectedErrorModal opened={unexpectedError} />
    </>
  );
}
