import { IPromoMetadata } from "../../models/metadata";

export const emptyPromoMetadata: IPromoMetadata = {
  id: "",
  name: "",
  contentType: "",
  isciCode: "",
  campaign: "",
  project: "",
  description: "",
  versionName: "",
  flightDateBegin: "",
  flightDateEnd: "",
  network: "",
  durationInSeconds: "",
  resolution: "",
  readyForDistribution: "",
};
