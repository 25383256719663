import { Box, Grid, useMantineTheme } from "@mantine/core";
import { Title } from "../../components-ui";
import { useElementSize } from "@mantine/hooks";
import LeftColumnContainer from "./ColumnsContainer/LeftColumnContainer";
import RightColumnContainer from "./ColumnsContainer/RightColumnContainer";
import CenterColumnContainer from "./ColumnsContainer/CenterColumnContainer";
import useStyles from "../../theme/globalStyles";

export const BulkUploadContainer = () => {
  const { classes } = useStyles();
  const { ref, height } = useElementSize();
  const columnWrapperStyle = { height: height - 10, width: "100%" };
  const theme = useMantineTheme();

  return (
    <Box ref={ref} style={{ height: "90%" }}>
      <Title
        order={4}
        style={{ marginLeft: 6, marginTop: -6, marginBottom: 6 }}
        testid="files-upload"
        color={
          theme.colorScheme === "dark"
            ? theme.colors.gray[2]
            : theme.colors.gray[8]
        }
      >
        Files Upload
      </Title>

      <Grid style={{ height: height - 10 }}>
        <Grid.Col span={4}>
          <LeftColumnContainer
            height={height}
            className={classes.baseBox}
            wrapperStyle={columnWrapperStyle}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CenterColumnContainer
            height={height}
            className={classes.baseBox}
            wrapperStyle={columnWrapperStyle}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <RightColumnContainer
            height={height}
            className={classes.baseBox}
            wrapperStyle={columnWrapperStyle}
          />
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default BulkUploadContainer;
