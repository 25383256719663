import { Center, Group, Select } from '@mantine/core';
import { useInputState } from '@mantine/hooks';
import { Button } from '../Button';
import { useCallback, useEffect } from 'react';
import { useDashboardContext } from './state';
import { ISelectData, IWidget } from './state/models';

export interface IWidgetManagerProps {
  name: string;
  widgets: IWidget[];
}

export const WidgetManager = (props: any) => {
  const {
    state: { widgets },
    actions: { AddWidgetToGrid, RemoveWidgetFromGrid }
  } = useDashboardContext();
  const [selectedWidget, setSelectedWidget] = useInputState('');

  const getNames = useCallback(() => {
    let data = [] as ISelectData[];
    if (widgets === undefined) return data;
    const keys = Object.keys(widgets);
    keys.forEach((key) => {
      let group;
      widgets[key].active ? (group = 'Active Widgets') : (group = 'Inactive Widgets');
      data.push({ value: widgets[key].i, label: widgets[key].name, group: group });
    });
    // console.log("data", data);
    return data;
  }, [widgets]);

  useEffect(() => {
    if (widgets === undefined || Object.keys(widgets).length > 0) return;
    getNames();
  }, [getNames, widgets]);

  function isActive() {
    if (widgets === undefined) return false;
    if (selectedWidget === '') return false;
    return widgets[selectedWidget].active;
  }

  function handleAddWidgetToGrid() {
    AddWidgetToGrid(selectedWidget);
  }

  function handleRemoveWidgetFromGrid() {
    RemoveWidgetFromGrid(selectedWidget);
  }

  return (
    <Center>
      <Group>
        <Select
          placeholder="Select a Widget"
          size="xs"
          data={getNames()}
          onChange={(value) => {
            setSelectedWidget(value);
          }}
          value={selectedWidget}
        />
        <Button
          testid='WidgetManagerAdd'
          base="add"
          label=""
          compact
          disabled={isActive()}
          ml={-8}
          // testid='Add-Widget'
          onClick={handleAddWidgetToGrid}
          tooltip={{
            label: 'Add widget to grid.',
            color: 'green'
          }}
        />
        <Button
          testid='WidgetManagerRemove'
          base="remove"
          label=""
          compact
          disabled={!isActive()}
          ml={-8}
          // testid='Remove-Widget'
          onClick={handleRemoveWidgetFromGrid}
          tooltip={{
            label: 'Remove widget from grid.',
            color: 'red'
          }}
        />
      </Group>
    </Center>
  );
};
