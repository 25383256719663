import { List } from "@mantine/core";
import { Title, Text } from "../../components-ui";

export default function NamingConventionHelp() {
  return (
    <>
      <Title order={5} testid="filenaming" mb={15}>
        File name convention
      </Title>
      <Text size="sm" testid={"Help-CidNotInFile"}>
        The Portal accepts 3 different types of IDs: CIDs, Gabriel/House IDs,
        and ISCI IDs:{" "}
      </Text>
      <Text size="sm" testid={"Help-Submaster-exampleInfo"} mt={5}>
        If you want to upload a <b>submaster</b>, you need to add <b>_SUB</b> at
        the end of the file. Example: <i>7SDFGSDFGDFG851_SUB.mov</i>
      </Text>

      <Title order={5} testid="filenaming-CID" mt={15} mb={1}>
        CID
      </Title>
      <List withPadding spacing="xs" size="sm">
        <List.Item
          data-testid={"Help-CID-General-Troubleshooting-Description1"}
        >
          <b>Length:</b> 10 characters max
        </List.Item>
        <List.Item
          data-testid={"Help-CID-General-Troubleshooting-Description1"}
        >
          <b>Filename examples:</b>
        </List.Item>
        <List withPadding spacing="xs" size="sm" mt={10}>
          <List.Item
            data-testid={"Help-CID-General-Troubleshooting-Description1"}
          >
            optionalfilename_7HI851.mov
          </List.Item>
          <List.Item
            data-testid={"Help-CID-General-Troubleshooting-Description1"}
          >
            7HI851.mov
          </List.Item>
        </List>
      </List>

      <Title order={5} testid="filenaming-GabrielId" mt={15} mb={1}>
        Gabriel / House ID
      </Title>
      <List withPadding spacing="xs" size="sm">
        <List.Item
          data-testid={"Help-GabrielId-General-Troubleshooting-Description1"}
        >
          <b>Length:</b> contains decimal
        </List.Item>
        <List.Item
          data-testid={"Help-GabrielId-General-Troubleshooting-Description1"}
        >
          <b>Filename examples:</b>
        </List.Item>
        <List withPadding spacing="xs" size="sm" mt={10}>
          <List.Item
            data-testid={"Help-GabrielId-General-Troubleshooting-Description1"}
          >
            <b>Master:</b> 55555551.443.mov
          </List.Item>
          <List.Item
            data-testid={"Help-GabrielId-General-Troubleshooting-Description1"}
          >
            <b>Submaster:</b> 55555551.443_SUB.mov
          </List.Item>
        </List>
      </List>

      <Title order={5} testid="filenaming-Isci" mt={15} mb={1}>
        ISCI
      </Title>
      <List withPadding spacing="xs" size="sm">
        <List.Item
          data-testid={"Help-Isci-General-Troubleshooting-Description1"}
        >
          <b>Length:</b> 10-32 characters
        </List.Item>
        <List.Item
          data-testid={"Help-Isci-General-Troubleshooting-Description1"}
        >
          <b>Filename examples:</b>
        </List.Item>
        <List withPadding spacing="xs" size="sm" mt={10}>
          <List.Item
            data-testid={"Help-Isci-General-Troubleshooting-Description1"}
          >
            <b>Master:</b> 7SDFGSDFGDFG851.mov
          </List.Item>
          <List.Item
            data-testid={"Help-Isci-General-Troubleshooting-Description1"}
          >
            <b>Submaster:</b> 7SDFGSDFGDFG851_SUB.mov
          </List.Item>
        </List>
      </List>
    </>
  );
}
