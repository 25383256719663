import { createStyles, Switch, Group, SwitchProps } from '@mantine/core';
import { Bulb, BulbOff } from 'tabler-icons-react';
import { Tooltip } from '../Tooltip';
import { useDisclosure } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    '& *': {
      cursor: 'pointer'
    }
  },

  icon: {
    pointerEvents: 'none',
    position: 'absolute',
    zIndex: 1,
    top: 3
  },

  labelOn: {
    left: 4,
    color: theme.white
  },

  labelOff: {
    right: 4,
    color: theme.colors.gray[7]
  }
}));

interface iSwitchProps extends SwitchProps {
  checked: boolean;
  testid?: string;
}

export function SwitchOnOff(props: iSwitchProps) {
  const [checked, checkedHandlers] = useDisclosure(props.checked);
  const { classes, cx } = useStyles();

  return (
    <Group>
      <Tooltip label={`Toggle ${checked ? 'Off' : 'On'}`} withArrow>
        <Group position="center" my={30}>
          <div className={classes.root}>
            <Bulb className={cx(classes.icon, classes.labelOn)} size={18} />
            <BulbOff className={cx(classes.icon, classes.labelOff)} size={18} />
            <Switch checked={checked} onChange={() => checkedHandlers.toggle()} size="md" />
          </div>
        </Group>
      </Tooltip>
    </Group>
  );
}
