import { Box, Space, Tabs, useMantineTheme } from "@mantine/core";
import { Title } from "../../components-ui";
import useStyles from "../../theme/globalStyles";
import { IconCircleX, IconInfoSquare } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import FileRejectionContainer from "./FileRejectionContainer";
import { useQueryString } from "../../hooks/useQueryString";
import GeneralInfoHelp from "../../components/Help/GeneralInfo";

export default function HelpContainer() {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  let queryString = useQueryString();
  const tabSelection = queryString.get("tab");
  const tabValue = tabSelection ? tabSelection : "general";
  const rejectedItem = queryString.get("item");

  return (
    <Box className={classes.base} style={{ width: "100%" }}>
      <Title
        order={4}
        style={{ marginLeft: 6, marginTop: -6 }}
        testid="helpInfo"
      >
        Help
      </Title>
      <Space h="lg" />
      <Box className={classes.baseBox} pb={20} mb={30}>
        <Tabs
          color="yellow"
          value={tabValue}
          onTabChange={(value) => navigate(`/help?tab=${value}`)}
        >
          <Tabs.List>
            <Tabs.Tab
              value="general"
              icon={<IconInfoSquare size={20} color={theme.colors.blue[6]} />}
              color={theme.colors.blue[6]}
            >
              General
            </Tabs.Tab>
            <Tabs.Tab
              value="rejected"
              icon={<IconCircleX size={20} color="red" />}
              color="red"
            >
              File Rejection
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="general" pt="xs">
            <Title
              order={4}
              testid="generalTroubleshooting"
              mt={10}
              ml={10}
              mb={15}
            >
              General Troubleshooting
            </Title>
            <GeneralInfoHelp />
          </Tabs.Panel>
          <Tabs.Panel value="rejected" pt="xs">
            <Title order={4} testid="fileRejection" mt={10} ml={10} mb={15}>
              File Rejection Reasons
            </Title>
            <FileRejectionContainer item={rejectedItem} />
          </Tabs.Panel>
        </Tabs>
      </Box>
    </Box>
  );
}
