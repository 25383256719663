import { Title as MTitle, TitleProps } from "@mantine/core";
import { useTID } from "../../hooks/useTestId";

interface ITitleProps extends TitleProps {
  testid: string;
}

export const Title = (props: ITitleProps) => {
  const TID = useTID("Title", props.testid);

  return (
    <MTitle {...TID} {...props}>
      {props.children}
    </MTitle>
  );
};
