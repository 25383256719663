import { useMantineTheme } from "@mantine/core";
import { Button } from "../../components-ui";
import { AgGridReact } from "ag-grid-react";
import { useRef, useCallback, useMemo, useState } from "react";
import {
  GridOptions,
  GridReadyEvent
} from "ag-grid-community";
import {
  adminDeliveriesColumnDefs,
  deliveriesColumnDefs,
  deliveryDetailsColumnDefs,
} from "./deliveriesColumnDefs";
import { LicenseManager, ModuleRegistry } from "ag-grid-enterprise";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { configAtom } from "../../app/ConfigRecoil";
import { ClearAll } from "tabler-icons-react";
import DownloadDeliveries, { IDownloadDeliveries } from "./DownloadDeliveries";
import { getRowId, gridReadyCallback, isRowMaster } from "../../helpers/agGrid/grid";
import { unexpectedErrorAtom } from "../../recoil/bulkupload/atoms";

// @ts-ignore
ModuleRegistry.registerModules([ServerSideRowModelModule]);
interface DeliveriesGridProps {
  type: string
}
export default function DeliveriesGrid(props: DeliveriesGridProps) {
  const setUnexpectedError = useSetRecoilState(unexpectedErrorAtom);
  const config = useRecoilValue(configAtom);
  const gridRef = useRef<any>();
  const theme = useMantineTheme();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridCSS =
    theme.colorScheme === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine";
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      resizable: true,
      width: 100,
    };
  }, []);
  const pageSize = 10;
  const [downloadDeliveriesModel, setDownloadDeliveriesModel] = useState({} as IDownloadDeliveries);
  const allUploads = props.type === 'All Uploads';
  //AG Grid Enterprise License
  LicenseManager.setLicenseKey(config.agGridLicense);

  const sortGrid = useCallback((params: GridReadyEvent) => {
    const sortedColumn = params.columnApi.getColumnState().find(s => s.sort != null);
    if (!sortedColumn) {
      params.columnApi.applyColumnState({ state: [{colId: 'date', sort: 'desc'}]});
    }
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => { 
    sortGrid(params);
    gridReadyCallback(params, allUploads, setDownloadDeliveriesModel, setUnexpectedError)
  }, [allUploads, sortGrid, setUnexpectedError]);

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: deliveryDetailsColumnDefs,
        defaultColDef: {
          flex: 1,
          filter: false,
          sortable: false,
        },
      },
      getDetailRowData: function (params: any) {
        params.successCallback(params.data.components);
      },
    };
  }, []);

  const gridOptions: GridOptions = {
    columnDefs: allUploads ? [...deliveriesColumnDefs, ...adminDeliveriesColumnDefs] : deliveriesColumnDefs,
    defaultColDef: defaultColDef,
    rowModelType: "serverSide",
    getRowId: getRowId,
    pagination: true,
    paginationPageSize: pageSize,
    cacheBlockSize: pageSize,
    onGridReady: onGridReady,
    headerHeight: 32,
    masterDetail: true,
    isRowMaster: isRowMaster,
    detailCellRendererParams: detailCellRendererParams,
    rowHeight: 30,
    rowSelection: "single",
    suppressCellFocus: true,
    suppressRowTransform: true,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
            suppressColumnExpandAll: true,
          },
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
    },
  };

  const resetGrid = () => {
    gridRef.current.api.forEachNode((node: any) =>{
      node.setExpanded(false);
    });
    gridRef.current.api.setFilterModel(null);
    gridRef.current.columnApi.resetColumnState();
    gridRef.current.columnApi.applyColumnState({ state: [{colId: 'date', sort: 'desc'}]});
  };

  return (
    <>
      <div>
        <DownloadDeliveries model={downloadDeliveriesModel} admin={allUploads}/>
        <Button testid="resetGrid" onClick={resetGrid} leftIcon={<ClearAll />} size="xs" mb={2} style={{float: 'right'}}>
          Reset Grid
        </Button>
      </div>
      <div style={gridStyle} className={gridCSS}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          {...gridOptions}
        ></AgGridReact>
      </div>
    </>
  );
}
