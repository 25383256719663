// modules/Dashboard/state/context.ts
import {
  createContext, useContext
} from 'react';
import { IDashboardState, initialDashboardState } from './state';
import { IDashboardActions } from './actions';
import { IGridComponent, IWidgets, ILayout } from './models';
import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist()

interface IDashboardContext {
  state: IDashboardState;
  actions: IDashboardActions;
}

const defaultLayout = {
  lg: [],
  md: [],
  sm: []
};

export const layoutsAtom = atom<ILayout>({
  key: 'app/layouts',
  default: defaultLayout,
  effects_UNSTABLE: [persistAtom],
});

export const DashboardContext = createContext<IDashboardContext>({
  state: initialDashboardState,
  actions: {
    AddWidgetToGrid: (value: string) => undefined,
    SetBreakpoint: (value: string) => undefined,
    SetGridComponent: (value: IGridComponent) => undefined,
    SetLayout: (value: ILayout) => undefined,
    SetLayoutLocked: (value: boolean) => undefined,
    SetOnLoad: (value: boolean) => undefined,
    SetWidgets: (value: IWidgets) => undefined,
    RemoveWidgetFromGrid: (value: string) => undefined
  }
});

export const useDashboardContext = () => useContext(DashboardContext);
