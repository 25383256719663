export const getMainElement = (jsonReport: any) => {
  return jsonReport.elements[0].elements[0].elements[0];
};

export const validReportFormat = (jsonReport: any): boolean => {
  return (
    jsonReport &&
    jsonReport.elements &&
    jsonReport.elements.length > 0 &&
    jsonReport.elements[0].elements &&
    jsonReport.elements[0].elements.length > 0 &&
    jsonReport.elements[0].elements[0].elements &&
    jsonReport.elements[0].elements[0].elements.length > 0 &&
    jsonReport.elements[0].elements[0].elements[0].attributes &&
    jsonReport.elements[0].elements[0].elements[0].attributes.outcome
  );
};

export const isPolicyOrRuleNode = (element: any): boolean => {
  return element.attributes && element.attributes.outcome;
};

export const policyDefinitionOk = (mainElement: any): boolean => {
  let result = false;
  if (mainElement && mainElement.elements && mainElement.elements.length > 0) {
    mainElement.elements.forEach((element: any) => {
      if (
        element.attributes &&
        element.attributes.name &&
        element.attributes.name === "Definition" &&
        element.attributes.outcome &&
        element.attributes.outcome === "pass"
      ) {
        result = true;
      }
    });
  }

  return result;
};
