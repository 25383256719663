import { useCallback, useEffect, useState } from "react";
import { asperaWeb, initAspera } from "../../helpers/Aspera/AsperaConnect";
import useStyles from "./BulkUploadStyles";
import { DropZoneStatusInfo } from "./DisplayInfo/DropZoneStatusInfo";
import { Box, LoadingOverlay } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { dragDropAvailable } from "../../helpers/dragAndDropHelper";
import { useValidateFiles } from "./Validation/ValidateFiles";
import { getFilesRepeteadAndNot } from "../../helpers/filesValidation/validationHelper";
import { filenameFromPath } from "../../helpers/fileHelper";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  droppedFilesWorkaroundAtom,
  filesAcceptedAtom,
  filesRejectedAtom,
  filesValidateAtom,
  loadingNeededDataAtom,
  simulatedDragDropAtom,
  unexpectedErrorAtom,
  uploadingFilesInProgressAtom,
} from "../../recoil/bulkupload/atoms";
import { useEnvHelper } from "../../hooks/useEnvHelper";
import { UploadingFilesBox } from "./DisplayInfo/UploadingFilesBox";
import { dragDropCallback } from "../../helpers/dropEventHandler";


export const BulkUploadDropZone = () => {
  const uploadingFilesInProgress = useRecoilValue(uploadingFilesInProgressAtom);
  const loadingNeededData = useRecoilValue(loadingNeededDataAtom);
  const unexpectedError = useRecoilValue(unexpectedErrorAtom);
  const setUnexpectedError = useSetRecoilState(unexpectedErrorAtom);
  const [filesValidate, setFilesValidate] = useRecoilState(filesValidateAtom);
  const filesAccepted = useRecoilValue(filesAcceptedAtom);
  const filesRejected = useRecoilValue(filesRejectedAtom);
  const { classes } = useStyles();
  const { validateFiles } = useValidateFiles();
  const [validateFromDd, setValidateFromDd] = useState([]);
  const dropZoneMinHeight = 200;
  const { notProdEnvironments } = useEnvHelper();
  const [simulatedDragDrop, setSimulatedDragDrop] = useRecoilState(simulatedDragDropAtom);
  const [droppedFilesWorkaround, setDroppedFilesWorkaround] = useRecoilState(droppedFilesWorkaroundAtom);

  useEffect(() => {
    initAspera();
  }, []);

  const filesValidation = useCallback(async () => {
    const files = [...filesValidate];
    await validateFiles(files);
  }, [filesValidate, validateFiles]);

  useEffect(() => {
    if (filesValidate.length > 0) {
      filesValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesValidate]);

  //Add non repeated files for validation
  const addFilesToValidate = useCallback(
    (files: any) => {
      //If file already added, ignore
      let repeatedAndNot = getFilesRepeteadAndNot(
        files,
        filesAccepted,
        filesRejected
      );
      setFilesValidate(repeatedAndNot.nonRepeated);

      //Repetead files notifications
      repeatedAndNot.repeated.forEach((repeteadFile) => {
        showNotification({
          title: "File already added",
          message: filenameFromPath(repeteadFile),
        });
      });
    },
    [filesAccepted, filesRejected, setFilesValidate]
  );

  //Add files by click
  const handleAddFiles = async () => {
    /* Uncomment when Aspera bug is fixed https://github.com/IBM/aspera-connect-sdk-js/issues/49


    const options = {
      allowMultipleSelection: true,
    };

    try {
      let response = await asperaWeb.showSelectFileDialogPromise(options);
      if (response.dataTransfer.files.length > 0) {
        addFilesToValidate(response.dataTransfer.files);
      }
    } catch (error) {
      throw new Error(`Unable to select files: ${error}`);
    }*/
  };

  //Needed to update state properly when using Drag and Drop
  useEffect(() => {
    if (
        (validateFromDd.length > 0 && droppedFilesWorkaround?.length > 0) ||
        (validateFromDd.length > 0 && !droppedFilesWorkaround?.length && simulatedDragDrop) // remove this case after aspera bug is fixed
    ) {
      addFilesToValidate(validateFromDd);
      setValidateFromDd([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateFromDd, droppedFilesWorkaround, simulatedDragDrop]);

  const isSimulatedDragDrop = (event: any): boolean => {
    const automationFlag = "automation#dragandrop";
    return (
      event &&
      event.dataTransfer &&
      event.dataTransfer.testAutomate &&
      event.dataTransfer.testAutomate === automationFlag
    );
  };


  //Drag and drop
  useEffect(() => {
    if (!uploadingFilesInProgress) {
      asperaWeb.setDragDropTargets(
        "#dragdroparea",
        {
          dragEnter: true,
          dragLeave: false,
          dragOver: false,
          drop: true,
          allowPropagation: true,
        },
        dragDropCallback(notProdEnvironments, isSimulatedDragDrop, setSimulatedDragDrop, setValidateFromDd, setUnexpectedError)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingFilesInProgress]);

  // eslint-disable-next-line no-lone-blocks
  {/*enableDropOuter can be removed once the Aspera bug is resolved
      https://github.com/IBM/aspera-connect-sdk-js/issues/49*/}
  function enableDropOuter(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
  }

  // eslint-disable-next-line no-lone-blocks
  {/*droppedOuter can be removed once the Aspera bug is resolved
      https://github.com/IBM/aspera-connect-sdk-js/issues/49*/}
  function droppedOuter(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();

    setDroppedFilesWorkaround([...event.dataTransfer.files]);
  }

  return uploadingFilesInProgress ? (
    <UploadingFilesBox dropZoneMinHeight={dropZoneMinHeight} />
  ) : (
    <div style={{ position: "relative" }}>
      <LoadingOverlay
        visible={
          (loadingNeededData || filesValidate.length > 0) && !unexpectedError
        }
        data-testid=":Loading-BulkUploadDropZone"
      />
      {/*outerdragdroparea can be removed once the Aspera bug is resolved
      https://github.com/IBM/aspera-connect-sdk-js/issues/49*/}
      <div
          id="outerdragdroparea"
          onDragOver={enableDropOuter}
          onDrop={droppedOuter}
          style={{width: '100%', height: '100%'}}>
        <Box
          id="dragdroparea"
          data-testid=":dropdragBox"
          className={classes.DropZone}
          onClick={handleAddFiles}
          /* Uncomment when Aspera bug is fixed https://github.com/IBM/aspera-connect-sdk-js/issues/49
style={{ cursor: "pointer"}} */
        >
            <DropZoneStatusInfo
              dragDropAvailable={dragDropAvailable()}
              dropZoneMinHeight={dropZoneMinHeight}
            />
        </Box>
      </div>
    </div>
  );
};

export default BulkUploadDropZone;
