import { Tooltip } from "../../../../components-ui";
import { useRecoilState, useRecoilValue } from "recoil";
import { TrashX } from "tabler-icons-react";
import { filenameFromPath } from "../../../../helpers/fileHelper";
import { WrappedElementForTootltip } from "../../../../helpers/tooltipHelper";
import {
  filesAcceptedAtom,
  filesRejectedAtom,
  filesValidateAtom,
} from "../../../../recoil/bulkupload/atoms";
import { useThemeColors } from "../../../../hooks/useThemeColors";

export const BulkInfoRemoveFile = ({
  completeFileName,
  rejectedColumn,
}: {
  completeFileName: string;
  rejectedColumn: boolean;
}) => {
  const [filesRejected, setFilesRejected] = useRecoilState(filesRejectedAtom);
  const [filesAccepted, setFilesAccepted] = useRecoilState(filesAcceptedAtom);
  const filesValidate = useRecoilValue(filesValidateAtom);
  const filename = filenameFromPath(completeFileName);
  const { rejectedIconColor } = useThemeColors();

  const removeRejectedFile = () => {
    const fileRemoved = filesRejected.map((item) => {
      const rejectedfileFiltered = item.files.filter(
        (deliveryRejectionFile) =>
          deliveryRejectionFile.file.name !== completeFileName
      );
      return { id: item.id, files: rejectedfileFiltered };
    });
    const filesFiltered = fileRemoved.filter((item) => item.files.length > 0);
    setFilesRejected(filesFiltered);
  };

  const removeAcceptedFile = () => {
    const fileRemoved = filesAccepted.map((item) => {
      const acceptedfileFiltered = item.files.filter(
        (deliveryFile) => deliveryFile.name !== completeFileName
      );
      const submasterAcceptedfileFiltered = item.submasterFiles.filter(
        (deliveryFile) => deliveryFile.name !== completeFileName
      );
      return {
        id: item.id,
        files: acceptedfileFiltered,
        submasterFiles: submasterAcceptedfileFiltered,
      };
    });
    const filesFiltered = fileRemoved.filter(
      (item) => item.files.length > 0 || item.submasterFiles.length > 0
    );
    setFilesAccepted(filesFiltered);
  };

  return filesValidate.length > 0 ? (
    <></>
  ) : (
    <Tooltip
      label="Remove this file."
      color={rejectedColumn ? rejectedIconColor : "green"}
      position="left"
      withArrow
    >
      <WrappedElementForTootltip>
        <TrashX
          color={rejectedColumn ? rejectedIconColor : "green"}
          onClick={() =>
            rejectedColumn ? removeRejectedFile() : removeAcceptedFile()
          }
          style={{ cursor: "pointer" }}
          data-testid={":Icon-RemoveFile-" + filename}
        />
      </WrappedElementForTootltip>
    </Tooltip>
  );
};
