export const dragDropAvailable = (): boolean => {
  return osWindows() || osMac();
};

export const osWindows = () => {
  return navigator.platform.indexOf("Win") !== -1;
};
const osMac = () => {
  return navigator.platform.indexOf("Mac") !== -1;
};
// const osLinux = () => {
//   return navigator.platform.indexOf("Linux") !== -1;
// };
