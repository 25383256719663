import { Loader } from "@mantine/core";
import { Tooltip } from "../../../../components-ui";
import { WrappedElementForTootltip } from "../../../../helpers/tooltipHelper";
import { useThemeColors } from "../../../../hooks/useThemeColors";

export const BulkInfoLoader = () => {
  const { blueColor } = useThemeColors();

  return (
    <Tooltip
      label="Validating file."
      color={blueColor}
      position="left"
      withArrow
    >
      <WrappedElementForTootltip>
        <Loader size="sm" color={blueColor} />
      </WrappedElementForTootltip>
    </Tooltip>
  );
};
