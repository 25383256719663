
export const DevMediaconchProfile = {
  "data": {
    "mediaConchProfile": "",
    "slateProfiles": [
        {
            "slateStyle": "Legacy Turner (Slate)",
            "slateDescription": "5 seconds slate, 5 seconds black, media, 2 seconds black",
            "slateProfileId": "DMI"
        },
        {
            "slateStyle": "Legacy Discovery (No Slate)",
            "slateDescription": "1 second black, media, 1 second black",
            "slateProfileId": "Discovery"
        },
        {
            "slateStyle": "Content Only (No Slate)",
            "slateDescription": "media",
            "slateProfileId": "None"
        }
    ]
  }
}

fetch('/PromoContributionSpec.xml') //Developer must put this file in /public folder
  .then(response => response.text())
  .then(data => {
    DevMediaconchProfile.data.mediaConchProfile = data; // Assign the fetched XML data to mediaConchProfile
  })
  .catch(error => {
    console.error('Error fetching dev mediaconch profile xml:', error);
  });
