import { Alert, Box } from "@mantine/core";
import { MessageType } from "../../models/common";
import { AlertIcon, CheckCircleIcon, InfoIcon } from "@primer/octicons-react";
import { ReactElement, useState } from "react";
import { AlertVariant } from "@mantine/core/lib/Alert/Alert.styles";

interface ColorAndIcon {
  color: string;
  icon: ReactElement;
}

export default function AlertMessage({
  status,
  title,
  message,
  variant,
}: {
  status: MessageType;
  key?: number
  title?: string;
  message?: string;
  variant?: AlertVariant;
}) {
  const [opened, setOpened] = useState(true);
  const defaultErrorMsg = "We got an issue, please try again later";
  const defaultMsg = status === MessageType.Error ? defaultErrorMsg : "";

  const statusColorAndIcon = (status: MessageType): ColorAndIcon => {
    const iconSize = 16;
    let config: ColorAndIcon = {
      color: "blue",
      icon: <InfoIcon size={iconSize} />,
    };
    switch (status) {
      case MessageType.Error:
        config.color = "red";
        config.icon = <AlertIcon size={iconSize} />;
        break;
      case MessageType.Success:
        config.color = "green";
        config.icon = <CheckCircleIcon size={iconSize} />;
        break;
      case MessageType.Warning:
        config.color = "yellow";
        config.icon = <AlertIcon size={iconSize} />;
        break;
      default:
        break;
    }
    return config;
  };
  const { color, icon } = statusColorAndIcon(status);

  return (
    <Box>
      {opened && <Alert
        title={title ? title : ""}
        icon={icon}
        color={color}
        variant={variant ? variant : "filled"}
        withCloseButton
        onClose={() => setOpened(false)}
      >
        {message && message !== "" ? message : defaultMsg}
      </Alert>}
    </Box>
  );
}
