import { Box, Space } from "@mantine/core";
import { Title } from "../../components-ui";
import useStyles from "../../theme/globalStyles";
import DeliveriesGrid from "../../components/Uploads/DeliveriesGrid";
interface UploadsContainerProps {
  title: string
}
export default function UploadsContainer(props: UploadsContainerProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.base} style={{ width: "100%", height: "80%" }}>
      <Title
        order={4}
        style={{ marginLeft: 6, marginTop: -6 }}
        testid="my-uploads"
      >
        {props.title}
      </Title>
      <Space h="md" />
      <DeliveriesGrid type={props.title}/>
    </Box>
  );
}
