import { Headerbar } from "../components-ui";
import UploadsContainer from "../containers/UploadsContainer/UploadsContainer";
import { useAdminAuthRestrictions } from "../helpers/authHelper";
import { useNavigate } from "react-router";

export default function AllUploadsPage() {
  const crumbs = [
    { title: "Promo Upload", path: "/" },
    { title: "All Uploads", path: "/all-uploads" },
  ];
  const navigate = useNavigate();
  const {isAdmin} = useAdminAuthRestrictions();
  if(!isAdmin) {
    navigate('/not-authorized', { replace: true })
  }

  return (
    <>
      <Headerbar crumbs={crumbs} testid="headerBar" rightSection={<></>} />
      <UploadsContainer title="All Uploads"/>
    </>
  );
}
