import { AcceptedColumnFileBox } from "../ContentBoxes/AcceptedFileBox";
import useStyles from "../BulkUploadStyles";
import { Divider, Group, useMantineTheme, Loader, Paper } from "@mantine/core";
import { Collapse, Text } from "../../../components-ui";
import { ClipboardCheck } from "tabler-icons-react";
import { useRecoilValue } from "recoil";
import {
  filesAcceptedAtom,
  filesValidateAtom,
} from "../../../recoil/bulkupload/atoms";
import { bulkMetadataAtom } from "../../../recoil/metadata/atoms";
import { useAuthRestrictions } from "../../../hooks/useAuthRestrictions";
import { useThemeColors } from "../../../hooks/useThemeColors";

export const DisplayAcceptedFiles = ({
  expanded,
  ascending,
}: {
  expanded: boolean;
  ascending: boolean;
}) => {
  const theme = useMantineTheme();
  const filesValidate = useRecoilValue(filesValidateAtom);
  const filesAccepted = useRecoilValue(filesAcceptedAtom);
  const { classes } = useStyles();
  const bulkMetadata = useRecoilValue(bulkMetadataAtom);
  const { metadataAuth } = useAuthRestrictions();
  const { greenColor, validatingColor } = useThemeColors();

  const getMetadataById = (id: string) => {
    return bulkMetadata.filter((x) => x.id === id)[0];
  };

  let orderAceptedFiles = filesAccepted.slice();
  let sortedAcceptedFiles = orderAceptedFiles.sort((a, b) => {
    if (a.id < b.id) {
      return ascending ? -1 : 1;
    }
    if (a.id > b.id) {
      return ascending ? 1 : -1;
    }
    /* istanbul ignore next */ 
    // This should be ignored from coverage, because if there are
    // files with the same ID, it produces a unique key error
    return 0;
  });

  return (
    <>
      {filesValidate.length > 0 && (
        <div style={{ marginBottom: "25px" }}>
          <Divider
            my="xs"
            color={validatingColor}
            label={
              <Group style={{ position: "relative" }}>
                <Loader
                  size={25}
                  color={validatingColor}
                  style={{ marginRight: -14 }}
                />
                <Text weight={700} testid="Validating">
                  Validating...
                </Text>
              </Group>
            }
          />
          {filesValidate.map((file, index) => (
            <AcceptedColumnFileBox
              key={file.name + index}
              className={classes.PendingFileItem}
              id={""}
              file={file}
              pending={true}
            />
          ))}
        </div>
      )}

      {sortedAcceptedFiles.map((accepted, index) => {
        let id = accepted.id;
        let metadataById = getMetadataById(id);

        return (
          <div key={id} style={{ marginBottom: "25px" }}>
            <Divider
              my="xs"
              color={
                theme.colorScheme === "dark"
                  ? theme.colors.green[6]
                  : theme.colors.green[7]
              }
              label={
                <Group style={{ position: "relative" }}>
                  <ClipboardCheck
                    size={25}
                    color={greenColor}
                    style={{ marginRight: -14 }}
                  />
                  <Text weight={700} testid={"CID-" + id}>
                    ID{" "}
                    <span
                      style={{
                        color: greenColor,
                      }}
                    >
                      {id}
                    </span>
                  </Text>
                </Group>
              }
            />
            {metadataAuth && (
              <Collapse in={expanded} testid={"Collapse" + id}>
                <Text size="xs" ml={5} testid={"Description-" + id}>
                  <b>Description:</b> {metadataById && metadataById.description}
                </Text>
                <Text size="xs" ml={5} testid={"Version-" + id}>
                  <b>Version:</b> {metadataById && metadataById.versionName}
                </Text>
                <Text size="xs" ml={5} testid={"Duration-" + id}>
                  <b>Duration:</b>{" "}
                  {metadataById && metadataById.durationInSeconds} seconds
                </Text>
              </Collapse>
            )}
            {accepted.files.map((file) => (
              <AcceptedColumnFileBox
                key={file.name}
                className={classes.AcceptFileItem}
                file={file}
                id={id}
                pending={false}
              />
            ))}

            {accepted.submasterFiles.length > 0 && (
              <Paper shadow="xs" mt={15} px={10} pt={5} pb={18} withBorder>
                <Text testid={"Submaster-" + id} fw={700} m={0} p={0}>
                  Submaster
                </Text>
                {accepted.submasterFiles.map((file) => (
                  <AcceptedColumnFileBox
                    key={file.name}
                    className={classes.AcceptFileItem}
                    file={file}
                    id={id}
                    pending={false}
                  />
                ))}
              </Paper>
            )}
          </div>
        );
      })}
    </>
  );
};

export default DisplayAcceptedFiles;
