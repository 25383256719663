import { Group, UnstyledButton, useMantineColorScheme } from "@mantine/core";
import { Tooltip } from "../Tooltip";
import { Sun, Moon } from "tabler-icons-react";
import { useTID } from "../../hooks/useTestId";
import { useThemeColors } from "../../hooks/useThemeColors";

interface IButtonThemeProps {
  testid: string;
}

export const ButtonTheme = (props: IButtonThemeProps) => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  const TID = useTID("ButtonTheme", props.testid);
  const { headerIconColor, headerTooltipColor } = useThemeColors();

  return (
    <Group>
      <Tooltip
        label={`Toggle theme to ${dark ? "light" : "dark"}`}
        withArrow={false}
        color={headerTooltipColor}
      >
        <Group>
          <UnstyledButton
            {...TID}
            onClick={() => 
              toggleColorScheme(colorScheme === "dark" ? "light" : "dark")
            }
            mr={40}
          >
            {dark ? (
              <Sun size={24} color={headerIconColor} />
            ) : (
              <Moon size={20} color={headerIconColor} />
            )}
          </UnstyledButton>
        </Group>
      </Tooltip>
    </Group>
  );
};
