import { Box, Group, Space, Text } from '@mantine/core';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Aside } from '../Aside';
import { Button } from '../Button';
import { Switch } from '../Switch';
import { showAsideSelector } from '../Aside/state';
import { showTooltipsAtom } from '../Tooltip/state';
import { navCloseOnSelectAtom } from '../Navbar/state';

interface IAsideSettingsProps {
  testid: string;
}

export const AsideSettings = (props: IAsideSettingsProps) => {
  const [showTooltips, SetShowTooltips] = useRecoilState(showTooltipsAtom);
  const SetShowAside = useSetRecoilState(showAsideSelector);
  const [navCloseOnSelect, SetNavCloseOnSelect] = useRecoilState(navCloseOnSelectAtom);

  return (
    <Aside testid={props.testid} p="md" hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }}>
      <Text>Settings</Text>
      <Space h="xl" />
      <Box>
        <Switch
          checked={navCloseOnSelect}
          label="Close Navbar when clicking a selection."
          testid="close-navbar"
          onChange={() => {
            SetNavCloseOnSelect(!navCloseOnSelect);
          }}
        />
      </Box>
      <Space h="xl" />
      <Box>
        <Switch
          checked={showTooltips}
          label="Show Tooltips."
          testid="show-tooltips"
          onChange={() => {
            SetShowTooltips(!showTooltips);
          }}
        />
      </Box>
      <Space h="xl" />
      <Group position="right">
        <Button
          base="cancel"
          testid="AsideSettingsCancel"
          onClick={() => {
            SetShowAside('');
          }}
        >
          Close
        </Button>
      </Group>
    </Aside>
  );
};

export default AsideSettings;
