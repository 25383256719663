import { httpServices } from "./httpCommon";
import { DevMediaconchProfile } from "../helpers/mediaconch/DevMediaConchProfile";

export const getMediaconchProfile = async (devMediaconchProfileMode: string | undefined | boolean | number) => {  
  
  if(devMediaconchProfileMode !== 'ON') {
    return httpServices.get<any>(`/content/profile`);
    
  } else {
    console.log('WARNING - using dev version of mediaconch')
    return DevMediaconchProfile;
  }

};
