import { Connect, ConnectInstaller } from "@ibm-aspera/connect-sdk-js";
import {
  ConfigurationOptions,
  ConnectClientType,
} from "@ibm-aspera/connect-sdk-js/dist/esm/core/types";

export let asperaWeb: ConnectClientType;

const config: ConfigurationOptions = {
  // minVersion: "5.1.0",
  dragDropEnabled: true,
};

export const initAspera = () => {
  if (asperaWeb === undefined) {
    asperaWeb = new Connect(config);

    const options = {};
    const asperaInstaller = new ConnectInstaller(options);

    const statusEventListener = function (eventType: string, data: string) {
      if (
        eventType === Connect.EVENT.STATUS &&
        data === Connect.STATUS.INITIALIZING
      ) {
        console.log("Aspera Connect Client is initializing");
        asperaInstaller.showLaunching();
      } else if (
        eventType === Connect.EVENT.STATUS &&
        data === Connect.STATUS.FAILED
      ) {
        console.log("Aspera Connect Client failed to load");
        asperaInstaller.showDownload();
      } else if (
        eventType === Connect.EVENT.STATUS &&
        data === Connect.STATUS.OUTDATED
      ) {
        console.log("Aspera Connect Client is Outdated");
        asperaInstaller.showUpdate();
      } else if (
        eventType === Connect.EVENT.STATUS &&
        data === Connect.STATUS.RUNNING
      ) {
        console.log("Aspera Connect Client is now Running");
        asperaInstaller.connected();
      }
    };

    asperaWeb.addEventListener(Connect.EVENT.STATUS, statusEventListener);
    asperaWeb.initSession();
  }
};
