import { Box, Group, Tooltip as MTooltip, TooltipProps } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { showTooltipsAtom } from './state';
// import {useTID} from '../../helpers/useTestId'

// interface ITooltipProps extends TooltipProps {
//   testid: string;
// }

export const Tooltip = (props: TooltipProps) => {
  const showTooltips = useRecoilValue(showTooltipsAtom);
  // const TID = useTID('Tooltip', props.testid)

  const defaultValues = {
    color: 'gray',
    placement: 'center',
    position: 'top',
    withArrow: true
  };
  let combinedProps = Object.assign({}, defaultValues, props);

  if (!showTooltips) {
    return <Box>{props.children}</Box>;
  }

  return (
    <Group>
      <MTooltip {...combinedProps}>{props.children}</MTooltip>
    </Group>
  );
};
