import { LoadingOverlay } from "@mantine/core";
import React from "react";
import AppProviders from "./AppProviders";
import { AppWithConfigAndRouter } from "./AppWithConfigAndRouter";

function App() {
  return (
    <AppProviders>
      <React.Suspense
        fallback={
          <LoadingOverlay
            visible
            loaderProps={{ size: "lg", variant: "dots" }}
          />
        }
      >
        <AppWithConfigAndRouter />
      </React.Suspense>
    </AppProviders>
  );
}

export default App;
