import { Text } from "../../components-ui";

export default function IdRfdHelp() {
  return (
    <>
      <Text size="sm" testid={"Help-CidRfd-Description1"}>
        If the ID is already marked as <b>RFD (Ready For Distribution)</b> a
        user cannot upload more master files to it.
      </Text>
      <Text size="sm" testid={"Help-CidRfd-Description2"}>
        All master files with an ID that is RFD will be rejected.
      </Text>
    </>
  );
}
