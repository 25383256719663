import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

export const http = axios.create({
  baseURL: "http://localhost:8000",
  headers: {
    "Content-type": "application/json",
  },
});


export const httpServices = axios.create({
  headers: {
    "Content-type": "application/json",
  },
});

export function authTokenInterceptorInjector(token: string, servicesUrl: string) {
  return async function (config: InternalAxiosRequestConfig<any>) {
    config.baseURL = servicesUrl;
    config.headers!.Authorization = "Bearer " + token;
    return config;
  };
};

export function responseInterceptorInjector() {
  return async function(response: AxiosResponse<any>) {
    return response;
  };
};

export function responseErrorInterceptorInjector(errorCallback:(params: boolean) => void) {
  return async function(error: AxiosError<any>) {
    if (error?.response?.status === 500) {
      errorCallback(true);
    }
    return Promise.reject(error);
  }
}