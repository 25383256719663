import { createStyles } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";

export const foundryColors = {
  spaceBlue: "#04006C",
  sapphire: "#0022AF",
  blue: "#015CFE",
  cornflowerBlue: "#4D8DFE",
  skyBlue: "#99BEFF",
  aliceBlue: "#E6EFFF",
  indigo: "#5128F2",
  white: "#FFFFFF",
};

export const colors = {
  titan: [
    "#fcfeff",
    "#fafdff",
    "#f7f9fa",
    "#f0f3f5",
    "#e6ecf0",
    "#dde4eb",
    "#cfdae5",
    "#8fa1b2",
    "#6b8299",
    "#596c80",
  ],
  success: [
    "#d4ffcc",
    "#bfffb2",
    "#aaff99",
    "#94ff80",
    "#72e55c",
    "#54cc3d",
    "#3cb224",
    "#26990f",
    "#158000",
    "#116600",
  ],
  warning: [
    "#fff6e5",
    "#fff2d9",
    "#ffeabf",
    "#ffe1a6",
    "#ffd073",
    "#ffbf40",
    "#ffb21a",
    "#e59900",
    "#b27700",
    "#805500",
  ],
  danger: [
    "#ffbfca",
    "#ffa6b5",
    "#ff8ca0",
    "#ff738b",
    "#ff5975",
    "#ff4060",
    "#ff1a40",
    "#cc1433",
    "#990f26",
    "#660a1a",
  ],
  info: [
    "#ccf2ff",
    "#a6e9ff",
    "#8ce2ff",
    "#73dcff",
    "#59d6ff",
    "#3dc5f2",
    "#2badd9",
    "#399dbf",
    "#38778c",
    "#2d4e59",
  ],
};

export default createStyles((theme) => {
  const { height, width } = useViewportSize();

  return {
    body: {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : foundryColors.aliceBlue,
      bottom: 0,
      fontFamily:
        "Inter, -apple-system, BlinkMacSystemFont, Open Sans, sans-serif",
      fontSize: "14px",
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
    base: {
      height: height - 124,
      width: width,
      marginTop: -6,
    },
    baseBox: {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[4]
          : foundryColors.white,
      border: "1px solid",
      borderColor:
        theme.colorScheme === "dark" ? theme.colors.dark[6] : colors.titan[5],
      borderRadius: theme.radius.md,
      padding: 8,
      margin: -4,
      color:
        theme.colorScheme === "dark"
          ? theme.colors.gray[2]
          : theme.colors.gray[9],
    },
    baseContainer: {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[6] : colors.titan[0],
      border: "1px solid",
      borderColor:
        theme.colorScheme === "dark" ? theme.colors.dark[6] : colors.titan[5],
      borderRadius: theme.radius.md,
      padding: 8,
      margin: -4,
    },
    widgetContainer: {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[6] : colors.titan[0],
      border: "1px solid",
      borderColor:
        theme.colorScheme === "dark" ? theme.colors.dark[6] : colors.titan[5],
      borderRadius: theme.radius.md,
      padding: 8,
      margin: -4,
      height: "100%",
    },
    detail: {
      backgroundColor:
        theme.colorScheme === "dark" ? theme.colors.dark[6] : colors.titan[0],
      border: "1px solid",
      borderColor:
        theme.colorScheme === "dark" ? theme.colors.dark[6] : colors.titan[5],
      borderRadius: theme.radius.sm,
    },
  };
});
