import { Container, Grid } from "@mantine/core";
import { Text } from "../../../components-ui";
import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useAuthRestrictions } from "../../../hooks/useAuthRestrictions";
import { filenameFromPath, formatBytes } from "../../../helpers/fileHelper";
import { DeliveryFileObject } from "../../../helpers/upload/deliveryHelperModels";
import {
  bulkMetadataAtom,
  selectedFileMetadataAtom,
} from "../../../recoil/metadata/atoms";
import { DisplayFileMetadataModal } from "../DisplayInfo/DisplayFileMetadataModal";
import { FileIcon } from "../DisplayInfo/FileIcon";
import { LoaderOrIcon } from "../DisplayInfo/LoaderOrIcon";

export const AcceptedColumnFileBox = ({
  className,
  file,
  id,
  pending,
}: {
  className: string;
  file: DeliveryFileObject;
  id: string;
  pending: boolean;
}) => {
  const setSelectedFileMetadata = useSetRecoilState(selectedFileMetadataAtom);
  const { metadataAuth } = useAuthRestrictions();
  const filename = filenameFromPath(file.name);
  const bulkMetadata = useRecoilValue(bulkMetadataAtom);
  const metadata = bulkMetadata.filter((x) => x.id === id)[0];
  const [modalOpen, setModalOpen] = useState(false);

  const onClickShowMetadata = () => {
    if (!pending && metadataAuth) {
      setSelectedFileMetadata(metadata);
      setModalOpen(true);
    }
  };

  return (
    <>
      <DisplayFileMetadataModal
        modalOpened={modalOpen}
        filename={filename}
        setModalOpen={setModalOpen}
      ></DisplayFileMetadataModal>
      <Container className={className} mt={15}>
        <Grid justify="space-between" align="center" columns={48}>
          <Grid.Col span={3}>
            <FileIcon pending={pending} />
          </Grid.Col>
          <Grid.Col
            span={41}
            onClick={onClickShowMetadata}
            style={{ cursor: pending ? "default" : "pointer" }}
            data-testid={":Group-AcceptedFileBox-" + id}
          >
            <Text size="sm" testid={"AcceptedFileBox-" + filename}>
              {filename}
            </Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <LoaderOrIcon pending={pending} completeFileName={file.name} />
          </Grid.Col>
        </Grid>
        <Text size="xs" testid={"AcceptedFileBox-" + filename + "-size"}>
          Size: <b>{formatBytes(file.size)}</b>
        </Text>
      </Container>
    </>
  );
};
