import { Text as MText, TextProps } from "@mantine/core";
import { ReactNode } from "react";
import { useTID } from "../../hooks/useTestId";

interface ITextProps extends TextProps {
  testid: string;
  children: ReactNode;
}

export const Text = (props: ITextProps) => {
  const TID = useTID("Text", props.testid);

  return (
    <MText {...TID} {...props}>
      {props.children}
    </MText>
  );
};
