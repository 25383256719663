import { Modal } from "@mantine/core";
import { Title } from "../../../../components-ui";
import { ReactNode } from "react";
import { ValidationErrorType } from "../../../../helpers/filesValidation/validationHelper";
import { useThemeColors } from "../../../../hooks/useThemeColors";

const ErrorModal = ({
  children,
  modalOpened,
  setModalOpen,
  filename,
  errorType,
}: {
  children: ReactNode;
  modalOpened: boolean;
  setModalOpen: any;
  filename: string;
  errorType: ValidationErrorType;
}) => {
  const { modalOverlayColor } = useThemeColors();

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Modal
      onClose={closeModal}
      opened={modalOpened}
      trapFocus={true}
      closeOnEscape={true}
      closeOnClickOutside={true}
      withCloseButton={true}
      title={
        <Title
          order={3}
          key={"modal_policy_rejection" + filename}
          testid={"error-modal" + errorType}
        >
          {errorType}
        </Title>
      }
      overlayColor={modalOverlayColor}
      size="xl"
      overflow="inside"
    >
      {children}
    </Modal>
  );
};

export default ErrorModal;
