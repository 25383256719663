export function dragDropCallback(
    notProdEnvironments: () => boolean, 
    isSimulatedDragDrop: (event: any) => boolean,
    setSimulatedDragDrop: (params: boolean) => void, 
    setValidateFromDd: (params: any) => void,
    setUnexpectedError: (params: boolean) => void) {
  
      return (dragDropObject: any) => {
        try {
          switch (dragDropObject.event.type) {
            case "dragenter":
              //We use this event only if we detect the files are comming from a simulated drag and drop
              //to workaround the specific implementation of Aspera drop event
              //and integration of Mediaconch with Aspera array buffer
              //Only on not Prod environments
              //For more information read AUTOMATION.MD in root folder
              const automationData = dragDropObject.event;
              if (
                notProdEnvironments() &&
                isSimulatedDragDrop(automationData)
              ) {
                setSimulatedDragDrop(true);
                if (automationData.dataTransfer.files.length > 0) {
                  setValidateFromDd(automationData.dataTransfer.files);
                }
              } else {
                setSimulatedDragDrop(false);
              }
              break;
            case "drop":
              const data = dragDropObject.files;
              if (data && data.dataTransfer.files.length > 0) {
                  setValidateFromDd(data.dataTransfer.files);
              }
              break;
            default:
              break;
          }
        } catch (error) {
          setUnexpectedError(true);
          throw new Error(`Drag and drop error: ${error}`);
        }
      } 
  }