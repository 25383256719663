import {
  IFileRejectedReason,
  IPolicyCheckResult,
  IPolicyNodeResult,
  ITreeNodeResult,
} from "../../models/bulkUpload";
import { DeliveryFileObject } from "../upload/deliveryHelperModels";
import { getFileTypeFromFile, isRelevantPolicyForType } from "./fileTypeHelper";
import {
  getMainElement,
  isPolicyOrRuleNode,
  policyDefinitionOk,
  validReportFormat,
} from "./policyReportFormatHelper";

const unknownErrorMsj: IFileRejectedReason = {
  title: "Invalid policy check report format",
  expected: [],
  actual: "",
};

export const mapToPolicyCheckResult = (
  jsonReport: any,
  file: DeliveryFileObject,
  simulatedDragDrop: boolean
): IPolicyCheckResult => {
  let acceptedFile = false;
  let rejectedReasons: IFileRejectedReason[] = [];

  try {
    if (validReportFormat(jsonReport)) {
      const mainElement = getMainElement(jsonReport);
      acceptedFile = mainElement.attributes.outcome === "pass";
      const nodeResult = acceptedFile
        ? { pass: true, rejectedReasons: [] }
        : getSpecificResultByFileType(mainElement, file, simulatedDragDrop);

      //Report has repeated Policies/Titles with different Expected values
      //We flat by Title and put all expected values together
      rejectedReasons = flatByTitle(nodeResult.rejectedReasons);
    } else {
      rejectedReasons = [unknownErrorMsj];
    }
  } catch (error) {
    rejectedReasons = [unknownErrorMsj];
  }

  let policyCheck: IPolicyCheckResult = {
    accepted: acceptedFile,
    rejectedReasons: rejectedReasons,
  };

  return policyCheck;
};

const getSpecificResultByFileType = (
  mainElement: any,
  file: DeliveryFileObject,
  simulatedDragDrop: boolean
): ITreeNodeResult => {
  let resultsByPolicies: IPolicyNodeResult[] = [];
  const fileType = getFileTypeFromFile(file, simulatedDragDrop);

  //The first policy elements of the tree needs to be filtered
  //to provide relevant information depending on File Type
  mainElement.elements.forEach((element: any) => {
    //Analize only relevant policies
    if (
      isPolicyOrRuleNode(element) &&
      isRelevantPolicyForType(element, fileType, file.name)
    ) {
      const definitionOk = policyDefinitionOk(element);
      resultsByPolicies.push({
        policy: element.attributes.name,
        definitionOk: definitionOk,
        nodeResult: getNodeResult(element),
      });
    }
  });

  return getResultByDefinition(resultsByPolicies);
};

const getResultByDefinition = (
  resultsByPolicies: IPolicyNodeResult[]
): ITreeNodeResult => {
  let rejectedReasons: IFileRejectedReason[] = [];
  const definitionOkPolicy = resultsByPolicies.filter((x) => x.definitionOk);

  // Definition (Wrapper + Codec), if ok show relevant policy
  if (definitionOkPolicy.length === 1) {
    rejectedReasons = definitionOkPolicy[0].nodeResult.rejectedReasons;
  } else {
    resultsByPolicies.forEach((defPolicy) => {
      rejectedReasons.push(...defPolicy.nodeResult.rejectedReasons);
    });
  }

  return { pass: false, rejectedReasons: rejectedReasons };
};

const getNodeResult = (mainElement: any): ITreeNodeResult => {
  let result: ITreeNodeResult = { pass: true, rejectedReasons: [] };
  let childNodesResults: ITreeNodeResult[] = [];

  //Annalize only if failed
  if (
    isPolicyOrRuleNode(mainElement) &&
    mainElement.attributes.outcome === "fail"
  ) {
    //If policy, keep searching on children
    if (mainElement.name === "policy" && mainElement.attributes.type) {
      mainElement.elements.forEach((element: any) => {
        //Only Policy or Rule child nodes
        if (isPolicyOrRuleNode(element)) {
          childNodesResults.push(getNodeResult(element));
        }
      });
      result = joinChildNodesResult(mainElement, childNodesResults);
    }

    //If rule, get rejected reason
    if (mainElement.name === "rule") {
      let ruleRejectedReason: IFileRejectedReason = {
        title: mainElement.attributes.name,
        expected: [mainElement.attributes.requested],
        actual: mainElement.attributes.actual,
      };
      result = { pass: false, rejectedReasons: [ruleRejectedReason] };
    }
  }

  return result;
};

const joinChildNodesResult = (
  mainElement: any,
  childNodesResults: ITreeNodeResult[]
) => {
  let result: ITreeNodeResult = { pass: true, rejectedReasons: [] };

  if (mainElement.attributes.type === "and") {
    result = getAndNodesResult(childNodesResults);
  } else if (mainElement.attributes.type === "or") {
    result = getOrNodesResult(childNodesResults);
  }

  return result;
};

const getAndNodesResult = (nodeResults: ITreeNodeResult[]): ITreeNodeResult => {
  let result: ITreeNodeResult = { pass: true, rejectedReasons: [] };
  const failedNodes = nodeResults.filter((result) => !result.pass);

  //At least one node fail
  if (failedNodes.length > 0) {
    result.pass = false;
    result.rejectedReasons = getRejectedReasons(failedNodes);
  }

  return result;
};

const getOrNodesResult = (nodeResults: ITreeNodeResult[]): ITreeNodeResult => {
  let result: ITreeNodeResult = { pass: true, rejectedReasons: [] };
  const failedNodes = nodeResults.filter((result) => !result.pass);

  //All nodes fail
  if (failedNodes.length === nodeResults.length) {
    result.pass = false;
    result.rejectedReasons = getRejectedReasons(failedNodes);
  }

  return result;
};

const getRejectedReasons = (
  nodes: ITreeNodeResult[]
): IFileRejectedReason[] => {
  const rejectedReasons = nodes.map((node) => {
    return node.rejectedReasons;
  });

  return rejectedReasons.flat();
};

const flatByTitle = (
  allRejectedReasons: IFileRejectedReason[]
): IFileRejectedReason[] => {
  let rejectedReasons: IFileRejectedReason[] = [];
  let addedTitles: Set<string> = new Set([]);

  allRejectedReasons.forEach((fileRejected) => {
    //get correct Title if policies have synonyms, to flat correctly
    let rejectedTitle = getRejectedTitleSynonym(fileRejected.title);

    //Title already added
    if (addedTitles.has(rejectedTitle)) {
      let rejectedElements = rejectedReasons.filter(
        (rejected) => rejected.title === rejectedTitle
      );

      //Add expected to existing Title
      if (rejectedElements && rejectedElements.length === 1) {
        let allExpected = [
          ...rejectedElements[0].expected,
          ...fileRejected.expected,
        ];
        let allExpectedSet = new Set(allExpected);
        rejectedElements[0].expected = Array.from(allExpectedSet.values());
      }
    } else {
      addedTitles.add(rejectedTitle);
      rejectedReasons.push(fileRejected);
    }
  });

  return rejectedReasons;
};

const getRejectedTitleSynonym = (rejectedTitle: string): string => {
  let title = rejectedTitle;
  switch (rejectedTitle) {
    case "Wrapper":
    case "Wrapper MOV":
    case "Wrapper MXF":
      title = "Wrapper";
      break;
  }

  return title;
};

//All actual values blank
//and can't detect Wrapper or Codec of the file
export const allActualValuesEmptyNoWrapperCodec = (
  rejectedReasons: IFileRejectedReason[]
): boolean => {
  const wrapperOrCodecUnknown =
    rejectedReasons.filter(
      (reason) => reason.title === "Wrapper" || reason.title === "Codec"
    ).length > 0;
  const emptyActualValues = rejectedReasons.filter((x) => x.actual === "");

  return (
    wrapperOrCodecUnknown && rejectedReasons.length === emptyActualValues.length
  );
};
