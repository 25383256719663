import { Tooltip } from "../Tooltip";
import { UserCircle } from "tabler-icons-react";
import { useRecoilState } from "recoil";
import { showAsideSelector } from "../Aside/state";
import { useTID } from "../../hooks/useTestId";
import { Group, UnstyledButton, useMantineTheme } from "@mantine/core";
import { useThemeColors } from "../../hooks/useThemeColors";

interface IButtonAccountProps {
  testid: string;
}

const ButtonAccount = (props: IButtonAccountProps) => {
  const [showAside, setShowAside] = useRecoilState(showAsideSelector);
  const TID = useTID("ButtonAccount", props.testid);
  const theme = useMantineTheme();
  const { headerIconColor, headerTooltipColor } = useThemeColors();

  return (
    <Group>
      <Tooltip
        label={`Toggle Account panel ${
          showAside === "AsideAccount" ? "close" : "open"
        }`}
        color={headerTooltipColor}
        position="bottom-end"
        withArrow={false}
      >
        <Group>
          <UnstyledButton
            {...TID}
            onClick={() => setShowAside("AsideAccount")}
            mr={40}
          >
            <UserCircle
              size={24}
              color={
                showAside === "AsideAccount"
                  ? theme.colors.blue[5]
                  : headerIconColor
              }
            />
          </UnstyledButton>
        </Group>
      </Tooltip>
    </Group>
  );
};

export default ButtonAccount;
