import { BrowserRouter as Router } from "react-router-dom";
import AppWithSecurity from "./AppSecurity";
import { useRecoilState, useRecoilValue } from "recoil";
import { configAtom, configQuerySelector } from "./ConfigRecoil";
import { useEffect } from "react";

export const AppWithConfigAndRouter = () => {
  const configQuery = useRecoilValue(configQuerySelector);
  const [config, setConfig] = useRecoilState(configAtom);

  useEffect(() => {
    setConfig({ ...configQuery });
  }, [configQuery, setConfig]);

  if (config && config.oktaDomain) {
    return (
      <Router>
        <AppWithSecurity config={config} />
      </Router>
    );
  } else {
    return <></>;
  }
};
