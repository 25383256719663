import { atom, selector } from "recoil";

export const configAtom = atom<any>({
  key: "config/values",
  default: {},
});

export const configQuerySelector = selector({
  key: "config/query",
  get: async ({ get }) => {
    const response = await fetch("/settings.json");
    const config = await response.json();
    return config;
  },
});
