import { Center, Space } from "@mantine/core";
import { Button } from "../../../components-ui";
import { useRecoilState, useRecoilValue } from "recoil";
import { Upload } from "tabler-icons-react";
import {
  createDeliveryComponentsAndFinalize,
  startFilesUpload,
} from "../../../helpers/upload/deliveryHelper";
import { useAsperaNotifications } from "../../../helpers/upload/useAsperaNotifications";
import {
  filesAcceptedAtom,
  uploadingFilesInProgressAtom,
  filesRejectedAtom,
  filesValidateAtom,
  selectedSlateProfileAtom,
  selectedLanguageAtom,
} from "../../../recoil/bulkupload/atoms";
import { ManifestationType } from "../../../models/delivery";

export const UploadFilesBox = () => {
  const [uploadingFilesInProgress, setUploadingFilesInProgress] =
    useRecoilState(uploadingFilesInProgressAtom);
  const [filesAccepted, setFilesAccepted] = useRecoilState(filesAcceptedAtom);
  const filesValidate = useRecoilValue(filesValidateAtom);
  const filesRejected = useRecoilValue(filesRejectedAtom);
  const selectedSlateProfile = useRecoilValue(selectedSlateProfileAtom);
  const { checkAsperaUploadStatus } = useAsperaNotifications();
  const languageCode = useRecoilValue(selectedLanguageAtom)

  const onlyFilesAccepted = () => {
    return (
      filesAccepted.length > 0 &&
      filesValidate.length === 0 &&
      filesRejected.length === 0
    );
  };

  const disableUploadButton = () => {
    return (
      !languageCode ||
      uploadingFilesInProgress ||
      !selectedSlateProfile ||
      selectedSlateProfile.code === "" ||
      !onlyFilesAccepted()
    );
  };

  const resetBulkUploadState = () => {
    setUploadingFilesInProgress(false);
    setFilesAccepted([]);
  };

  const beginUpload = async () => {
    setUploadingFilesInProgress(true);
    let componentIds: string[] = [];
    await Promise.all(
      filesAccepted.map(async (fileGroup) => {
        const contentGroupingId = fileGroup.id;

        //Master
        if (fileGroup.files.length > 0) {
          const deliveryComponentIds =
            await createDeliveryComponentsAndFinalize(
              contentGroupingId,
              selectedSlateProfile,
              fileGroup.files,
              ManifestationType.Master,
              languageCode
            );
          componentIds.push(...deliveryComponentIds);
        }

        //Submaster
        if (fileGroup.submasterFiles.length > 0) {
          const deliveryComponentIds =
            await createDeliveryComponentsAndFinalize(
              contentGroupingId,
              selectedSlateProfile,
              fileGroup.submasterFiles,
              ManifestationType.Submaster,
              languageCode
            );
          componentIds.push(...deliveryComponentIds);
        }
      })
    );

    if (componentIds.length > 0) {
      await startFilesUpload(componentIds);
      checkAsperaUploadStatus();
      resetBulkUploadState();
    }
  };

  return (
    <Center mt={10}>
      <Space h="xs" />
      <Button
        onClick={beginUpload}
        disabled={disableUploadButton()}
        loading={uploadingFilesInProgress}
        leftIcon={<Upload />}
        testid="UploadFiles"
      >
        Upload Files
      </Button>
    </Center>
  );
};
