import { Space, useMantineTheme } from "@mantine/core";
import { Text } from "../../../components-ui";
import BulkUploadDropZone from "../../../components/BulkUpload/BulkUploadDropZone";
import { MetadataInfoBox } from "../../../components/BulkUpload/ContentBoxes/MetadataBox";
import { SlateProfileBox } from "../../../components/BulkUpload/ContentBoxes/SlateProfileBox";
import { UploadFilesBox } from "../../../components/BulkUpload/ContentBoxes/UploadFilesBox";
import { LanguageBox } from "../../../components/BulkUpload/ContentBoxes/LanguageBox";

export default function CenterColumnContainer({
  height,
  className,
  wrapperStyle,
}: {
  height: number;
  className: string;
  wrapperStyle: React.CSSProperties;
}) {
  const theme = useMantineTheme();

  return (
    <div className={className} style={wrapperStyle}>
      <Text
        size="md"
        weight={700}
        testid="dropzone"
        color={
          theme.colorScheme === "dark"
            ? theme.colors.gray[2]
            : theme.colors.gray[8]
        }
      >
        DropZone
      </Text>
      <Space h="xs" />
      <BulkUploadDropZone />
      <UploadFilesBox />
      <LanguageBox />
      <SlateProfileBox />
      <MetadataInfoBox />
    </div>
  );
}
