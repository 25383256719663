import { atom } from "recoil";
import { DeliveryFileObject } from "../../helpers/upload/deliveryHelperModels";
import {
  IAcceptedFiles,
  IRejectedFiles,
} from "../../models/bulkUpload";
import { IAlerts, ILanguage, ISlateProfile } from "../../models/referenceData";

export const filesAcceptedAtom = atom<IAcceptedFiles[]>({
  key: "bulkupload/filesAccepted",
  default: [],
});

export const filesValidateAtom = atom<DeliveryFileObject[]>({
  key: "bulkupload/filesValidate",
  default: [],
});

export const filesRejectedAtom = atom<IRejectedFiles[]>({
  key: "bulkupload/filesRejected",
  default: [],
});

export const uploadingFilesInProgressAtom = atom<boolean>({
  key: "bulkupload/uploadingFilesInProgress",
  default: false,
});

export const loadingNeededDataAtom = atom<boolean>({
  key: "bulkupload/loadingNeededData",
  default: true,
});

export const unexpectedErrorAtom = atom<boolean>({
  key: "bulkupload/unexpectedError",
  default: false,
});

export const selectedSlateProfileAtom = atom<ISlateProfile>({
  key: "bulkupload/selectedSlateProfile",
  default: { code: "DMI", metadata: { additionalSecondsContentLength: 12 } } as ISlateProfile,
});

export const selectedLanguageAtom = atom<string>({
  key: "bulkupload/selectedLanguage",
  default: "en-US",
});

export const languagesAtom = atom<ILanguage[]>({
  key: "reference/languages",
  default: []
});

export const alertsAtom = atom<IAlerts[]>({
  key: "reference/alerts",
  default: []
});

export const slateProfilesAtom = atom<ISlateProfile[]>({
  key: "reference/slateProfiles",
  default: []
});

export const simulatedDragDropAtom = atom<boolean>({
  key: "bulkupload/simulatedDragDrop",
  default: false,
});

// eslint-disable-next-line no-lone-blocks
{/*droppedFilesWorkaroundAtom can be removed once the Aspera bug is resolved
      https://github.com/IBM/aspera-connect-sdk-js/issues/49*/}
export const droppedFilesWorkaroundAtom = atom<File[]>({
  key: "bulkupload/droppedFilesWorkaround",
  default: [],
});
