import { find } from "lodash";
import {
    IAcceptedFiles,
    IFileRejectedReason,
    IRejectedFiles,
} from "../../models/bulkUpload";
import {
    DeliveryFileObject,
    DeliveryFileRejection,
} from "../upload/deliveryHelperModels";
import {isSubmaster} from "./Submaster";
import { ISlateProfile } from "../../models/referenceData";
import { IPromoMetadata } from "../../models/metadata";

export enum ValidationErrorType {
    IdNotFoundInFile = "ID not found in file name",
    IdNotExist = "ID does not exist",
    IdOnlyOneVideoMaster = "Only 1 video file allowed per ID and Master",
    IdOnlyOneVideoSubmaster = "Only 1 video file allowed per ID and Submaster",
    PolicyCheck = "Policy check invalid",
    MediaInfoNoMetadata = "No metadata generated for the file",
    IdRfd = "This ID has already been uploaded and is RFD (Ready for Distribution)",
    FileFormatNotSupported = "File format not supported",
    DuplicateMediaId = "Duplicate Media ID",
    InvalidLanguage = "The promo language does not match your selection"
}

export interface ISlateProfileValidation {
    isValid: boolean;
    message: string;
}

const generateFileRejection = (
    file: DeliveryFileObject,
    errorType: string,
    errorReasons: IFileRejectedReason[] = []
): DeliveryFileRejection => {
    return {
        file: file,
        error: errorType,
        errorReasons: errorReasons,
    };
};

export function addRejectedFile(
    rejectedFiles: IRejectedFiles[],
    id: string,
    file: DeliveryFileObject,
    errorType: string,
    errorReasons: IFileRejectedReason[] = []
) {
    const rejectionFile = generateFileRejection(file, errorType, errorReasons);
    const index = rejectedFiles.findIndex((item) => item.id === id);
    let allRejectedForId: DeliveryFileRejection[] = [];
    //Add to existing rejected files
    if (index > -1) {
        allRejectedForId = [rejectionFile, ...rejectedFiles[index].files];
        rejectedFiles.splice(index, 1);
    }
    //Create first rejected
    else {
        allRejectedForId = [rejectionFile];
    }
    rejectedFiles.push({id: id, files: allRejectedForId});
}

export function addAcceptedFile(
    acceptedFiles: IAcceptedFiles[],
    id: string,
    file: DeliveryFileObject
) {
    const index = acceptedFiles.findIndex((item) => item.id === id);

    if (isSubmaster(file)) {
        //Add to existing ID
        if (index > -1) acceptedFiles[index].submasterFiles.push(file);
        //Create accepted for ID
        else
            acceptedFiles.push({
                id: id,
                files: [],
                submasterFiles: [file],
            });
    } else {
        //Add to existing ID
        if (index > -1) acceptedFiles[index].files.push(file);
        //Create accepted for ID
        else acceptedFiles.push({id: id, files: [file], submasterFiles: []});
    }
}

export interface FilesRepeatedAndNot {
    nonRepeated: DeliveryFileObject[];
    repeated: string[];
}

export const getFilesRepeteadAndNot = (
    files: DeliveryFileObject[],
    filesAccepted: IAcceptedFiles[],
    filesRejected: IRejectedFiles[]
): FilesRepeatedAndNot => {
    let repeatedAndNot: FilesRepeatedAndNot = {nonRepeated: [], repeated: []};
    let addedFileNames = getAddedFileNames(filesAccepted, filesRejected);
    files.forEach((file) => {
        addedFileNames.includes(file.name)
            ? repeatedAndNot.repeated.push(file.name)
            : repeatedAndNot.nonRepeated.push(file);
    });

    return repeatedAndNot;
};

export const validateContentLengthAgainstSlateProfile = (mediainfoMetadata: any, metadata: IPromoMetadata, slateProfile: ISlateProfile): ISlateProfileValidation => {
    const videoMetadata = find(mediainfoMetadata.media.track, function(track) {
        return track['@type'] === 'Video';
    });

    if (videoMetadata && slateProfile.code) {
        let mediaInfoDuration = videoMetadata['Duration'];
        mediaInfoDuration = parseInt(mediaInfoDuration.substring(0, mediaInfoDuration.indexOf('.')));
        const expectedContentDuration = parseInt(metadata.durationInSeconds) + slateProfile.metadata.additionalSecondsContentLength;

        if (mediaInfoDuration !== expectedContentDuration) {
            return { isValid: false, message: `The expected content duration for ${metadata.id} is ${expectedContentDuration} seconds. However, you are attempting to upload content that is ${mediaInfoDuration} seconds. Did you select the correct Slate Profile?` };
        }
    }

    return { isValid: true, message: '' };
}

const getAddedFileNames = (
    filesAccepted: IAcceptedFiles[],
    filesRejected: IRejectedFiles[]
): string[] => {
    let addedFileNames: string[] = [];
    filesAccepted.forEach((acceptedFile) => {
        addedFileNames = [
            ...addedFileNames,
            ...acceptedFile.files.map((file) => {
                return file.name;
            }),
            ...acceptedFile.submasterFiles.map((file) => {
                return file.name;
            }),
        ];
    });
    filesRejected.forEach((rejectedFile) => {
        addedFileNames = [
            ...addedFileNames,
            ...rejectedFile.files.map((file) => {
                return file.file.name;
            }),
        ];
    });

    return addedFileNames;
};