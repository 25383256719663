import {
  ValidationErrorType,
  addAcceptedFile, addRejectedFile,
} from "../../../helpers/filesValidation/validationHelper";
import { useFilesValidationSteps } from "../../../helpers/filesValidation/useFilesValidationSteps";
import { DeliveryFileObject } from "../../../helpers/upload/deliveryHelperModels";
import { useRecoilState, useSetRecoilState } from "recoil";
import { IAcceptedFiles, IPolicyCheckFiles } from "../../../models/bulkUpload";
import {
  filesAcceptedAtom,
  filesRejectedAtom,
  filesValidateAtom,
} from "../../../recoil/bulkupload/atoms";
import { DeliveryService } from "../../../services/delivery.service";
import { IPromoMetadata } from "../../../models/metadata";
import { bulkMetadataAtom } from "../../../recoil/metadata/atoms";
import { idInFile } from "../../../helpers/filesValidation/IdInFile";
import { find } from "lodash";

export const useValidateFiles = () => {
  const {
    initializeFilesById,
    checkMediaconchPolicy,
    addMediaInfoMetadata,
  } = useFilesValidationSteps();
  const setBulkMetadata = useSetRecoilState(bulkMetadataAtom);
  const setFilesValidate = useSetRecoilState(filesValidateAtom);
  const setFilesAccepted = useSetRecoilState(filesAcceptedAtom);
  const [filesRejected, setFilesRejected] = useRecoilState(filesRejectedAtom);

  const validateFiles = async (files: DeliveryFileObject[]) => {
    let filesById: Map<string, DeliveryFileObject[]> = new Map();
    let acceptedFiles: IAcceptedFiles[] = [];
    let bulkMetadata: IPromoMetadata[] = [];
    //initialize rejected with existing Rejected files
    let rejectedFiles = [...filesRejected];

    if (files && files.length > 0) {
        
      const validationResults = await DeliveryService.validateIds(files)

      if (validationResults.status === 500) {
        files.forEach((file,index) => {
          addRejectedFile(rejectedFiles, idInFile(file), file, ValidationErrorType.DuplicateMediaId);
        });
      }
      else{
        filesById = initializeFilesById(files, validationResults.data, rejectedFiles, bulkMetadata)

        //transform Map into IAcceptedFiles[]
        for await (const fileById of filesById) {
          const [fileId, deliveryFiles] = fileById;
          const fileMetadata = find(bulkMetadata, function(metadata) {
              return metadata.id === fileId;
          });
  
          //Check Mediaconch Policy
          let policyCheck: IPolicyCheckFiles = await checkMediaconchPolicy(
            fileId,
            deliveryFiles
          );
  
          //Analize with MediaInfo and add Metadata to accepted files
          const filesWithMediaInfo = await addMediaInfoMetadata(
            policyCheck.accepted,
            rejectedFiles,
            fileMetadata ?? {} as IPromoMetadata
          );
  
          //Add policy check accepted files
          filesWithMediaInfo.forEach((file) => {
            addAcceptedFile(acceptedFiles, fileId, file);
          });
  
          //Add policy check rejected files
          policyCheck.rejected.forEach((fileRejected) => {
            addRejectedFile(
              rejectedFiles,
              fileId,
              fileRejected.file,
              ValidationErrorType.PolicyCheck,
              fileRejected.rejectedReasons
            );
          });
        }
  
        setBulkMetadata(bulkMetadata);
      }
    }

    setFilesValidate([]);
    setFilesRejected(rejectedFiles);
    setFilesAccepted(acceptedFiles);
  };

  return {
    validateFiles,
  };
};

