import { useRecoilValue } from "recoil";
import { configAtom } from "../app/ConfigRecoil";

enum Environments {
  Dev = "DEV",
  Qa = "QA",
  Sit = "SIT",
  Prod = "PROD",
}

export const useEnvHelper = () => {
  const config = useRecoilValue(configAtom);

  const notProdEnvironments = (): boolean => {
    return config.label !== Environments.Prod;
  };

  const devQaEnvironments = (): boolean => {
    return (
      config.label === Environments.Dev || config.label === Environments.Qa
    );
  };

  return { notProdEnvironments, devQaEnvironments };
};
