import { Group } from "@mantine/core";
import { Text } from "../../../components-ui";
import { ClipboardX, Link } from "tabler-icons-react";
import { DeliveryFileRejection } from "../../../helpers/upload/deliveryHelperModels";
import { useThemeColors } from "../../../hooks/useThemeColors";

export const FileRejectionMessage = ({
  fileRejection,
  linkIcon,
}: {
  fileRejection: DeliveryFileRejection;
  linkIcon?: boolean;
}) => {
  const { rejectedIconColor, grayColor } = useThemeColors();

  return (
    <Group>
      <ClipboardX
        size={18}
        color={rejectedIconColor}
        style={{ marginRight: -12 }}
      />
      <Text size="xs" testid={fileRejection.error} color={grayColor} mr={-8}>
        {fileRejection.error}
      </Text>
      {linkIcon && <Link size={18} color={rejectedIconColor} />}
    </Group>
  );
};
