import { useMantineTheme } from "@mantine/core";
import { foundryColors } from "../theme/globalStyles";

export const useThemeColors = () => {
  const theme = useMantineTheme();

  const greenColor =
    theme.colorScheme === "dark"
      ? theme.colors.green[4]
      : theme.colors.green[8];

  const grayColor =
    theme.colorScheme === "dark" ? theme.colors.gray[4] : theme.colors.gray[8];

  const blueColor =
    theme.colorScheme === "dark" ? theme.colors.blue[3] : theme.colors.blue[6];

  const validatingColor =
    theme.colorScheme === "dark" ? theme.colors.blue[4] : theme.colors.blue[8];

  const rejectedIconColor =
    theme.colorScheme === "dark" ? theme.colors.red[4] : theme.colors.red[6];

  const dividerColor =
    theme.colorScheme === "dark" ? theme.colors.gray[7] : theme.colors.gray[4];

  const modalOverlayColor =
    theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[6];

  const accountBoxColor =
    theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[3];

  const headerFooterBackgroundColor = foundryColors.spaceBlue;

  const headerFooterTextColor = foundryColors.white;

  const headerIconColor = foundryColors.white;

  const headerTooltipColor = foundryColors.blue;

  return {
    greenColor,
    grayColor,
    blueColor,
    validatingColor,
    rejectedIconColor,
    dividerColor,
    modalOverlayColor,
    accountBoxColor,
    headerFooterBackgroundColor,
    headerFooterTextColor,
    headerIconColor,
    headerTooltipColor,
  };
};
