import { Group, Modal } from "@mantine/core";
import { Button, Title } from "../components-ui";
import { useThemeColors } from "../hooks/useThemeColors";

const AuthRequiredModal = ({
  authRequiredModalOpen,
  setAuthRequiredModalOpen,
  triggerLogin,
}: {
  authRequiredModalOpen: boolean;
  setAuthRequiredModalOpen: any;
  triggerLogin: any;
}) => {
  const { modalOverlayColor } = useThemeColors();

  const closeModal = () => {
    setAuthRequiredModalOpen(false);
  };

  const confirmModal = () => {
    setAuthRequiredModalOpen(false);
    triggerLogin();
  };

  return (
    <Modal
      onClose={closeModal}
      opened={authRequiredModalOpen}
      trapFocus={true}
      closeOnEscape={false}
      closeOnClickOutside={false}
      withCloseButton={false}
      title={
        <Title order={3} testid="auth-required">
          Auth required
        </Title>
      }
      overlayColor={modalOverlayColor}
    >
      <Group position="center">
        <p>Do you want to re-authenticate?</p>
      </Group>
      <Group position="center">
        <Button onClick={confirmModal} noIcon testid="auth-required-yes">
          Yes
        </Button>
        <Button onClick={closeModal} noIcon testid="auth-required-no">
          No
        </Button>
      </Group>
    </Modal>
  );
};
export default AuthRequiredModal;
