import { Link, redirect } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
} from "@mantine/core";
import {
  Icon as TablerIcon,
  CalendarStats,
  ChevronLeft,
  ChevronRight,
} from "tabler-icons-react";
import { navBarToggleAtom, navCloseOnSelectAtom } from "../../components-ui/";
import { activeItemAtom, activeLinkAtom } from "./state";
import useStyles from "./NavbarLinksGroupStyles";
import { foundryColors } from "../../theme/globalStyles";

export interface iLinksGroupProps {
  icon: TablerIcon;
  label: string;
  initiallyOpened?: boolean;
  path: string;
  links?: { label: string; link: string }[];
}

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened,
  path,
  links,
}: iLinksGroupProps) {
  const { classes, theme } = useStyles();
  const hasLinks = Array.isArray(links);
  const [activeItem, SetActiveItem] = useRecoilState(activeItemAtom);
  const [activeLink, SetActiveLink] = useRecoilState(activeLinkAtom);
  const navCloseOnSelect = useRecoilValue(navCloseOnSelectAtom);
  const [NavbarOpened, SetNavbarOpened] = useRecoilState(navBarToggleAtom);
  const ChevronIcon = theme.dir === "ltr" ? ChevronRight : ChevronLeft;
  const items = (hasLinks ? links : []).map((subLink) => (
    <Text
      component={Link}
      className={classes.link}
      to={subLink.link}
      key={subLink.label}
      onClick={(event) => {
        event.preventDefault();
        SetActiveLink(subLink.link);
        handleLinkClick(subLink.link);
      }}
      style={{
        color: activeLink === subLink.link ? "blue" : "black",
      }}
    >
      {subLink.label}
    </Text>
  ));

  const handleLinkClick = (link: string) => {
    SetActiveItem(link);
    if (navCloseOnSelect) {
      SetNavbarOpened(false);
    }
    redirect(link);
  };

  const handlePathClick = (path: string) => {
    // redirect(path);
    SetActiveItem(path);
    SetActiveLink("");
    if (!hasLinks && navCloseOnSelect) {
      SetNavbarOpened(false);
    }
  };

  return (
    <>
      {/* <Text component={Link} to={path}>
        {label}
      </Text> */}
      <UnstyledButton
        component={Link}
        to={path}
        onClick={() => handlePathClick(path)}
        className={classes.control}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ThemeIcon
              color={foundryColors.blue}
              variant={activeItem === path ? "filled" : "light"}
              size={30}
            >
              <Icon size={18} />
            </ThemeIcon>
            <Box ml="md">{label}</Box>
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size={14}
              style={{
                transform: NavbarOpened
                  ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                  : "none",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? (
        <Collapse in={NavbarOpened ? true : false}>{items}</Collapse>
      ) : null}
    </>
  );
}

const mockdata = {
  label: "Releases",
  icon: CalendarStats,
  path: "/",
  links: [
    { label: "Upcoming releases", link: "/" },
    { label: "Previous releases", link: "/" },
    { label: "Releases schedule", link: "/" },
  ],
};

export function NavbarLinksGroup() {
  return (
    <Box
      sx={(theme) => ({
        minHeight: 220,
        padding: theme.spacing.md,
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
      })}
    >
      <LinksGroup {...mockdata} />
    </Box>
  );
}
