import { createStyles } from "@mantine/core";
import { useThemeColors } from "../../hooks/useThemeColors";

export default createStyles((theme) => {
  const { headerFooterBackgroundColor, headerFooterTextColor } =
    useThemeColors();

  return {
    footer: {
      backgroundColor: headerFooterBackgroundColor,
      color: headerFooterTextColor,
      borderTop: `1px solid ${headerFooterBackgroundColor}`,
      display: "flex",
      justifyContent: "space-between",
    },
  };
});
