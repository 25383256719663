import { ColDef } from "ag-grid-community";
import { dateSimpleFormatter } from "../../helpers/agGrid/formatters";
import { StateCellRenderer } from "./StateCellRenderer";

export const deliveriesColumnDefs: ColDef[] = [
  {
    field: "cid",
    headerName: "Delivery ID",
    sortable: true,
    sortingOrder: ["asc", "desc"],
    flex: 2,
    filter: "agTextColumnFilter",
    resizable: true,
    filterParams: {
      filterOptions: ["equals"],
      defaultOption: "equals",
      debounceMs: 500,
      trimInput: true,
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
    },
    cellRenderer: "agGroupCellRenderer",
  },
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    sortingOrder: ["asc", "desc"],
    flex: 2,
    filter: false,
    resizable: true,
  },
  {
    field: "state",
    headerName: "State",
    sortable: false,
    flex: 2,
    filter: "agTextColumnFilter",
    resizable: true,
    cellRenderer: StateCellRenderer,
    filterParams: {
      filterOptions: ["equals"],
      defaultOption: "equals",
      debounceMs: 500,
      trimInput: true,
      buttons: ["reset", "apply"],
      suppressAndOrCondition: true,
    },
    cellStyle: _params => {
        return {overflow: 'visible'};
    }
  },
  {
    field: "date",
    headerName: "Date",
    sortable: true,
    sortingOrder: ["asc", "desc"],
    flex: 1,
    resizable: true,
    filter: "agDateColumnFilter",
    valueFormatter: dateSimpleFormatter,
    filterParams: {
      filterOptions: ["greaterThan", "lessThan", "inRange"],
      defaultOption: "greaterThan", //all upload default as inRange
      debounceMs: 500,
      suppressAndOrCondition: true,
      buttons: ["reset", "apply"],
    },
  },
];

export const adminDeliveriesColumnDefs: ColDef[] = [
    {
        colId: 'email',
        field: "uploader.email",
        headerName: "Email Address",
        sortable: true,
        sortingOrder: ["asc", "desc"],
        flex: 2,
        filter: 'agTextColumnFilter',
        resizable: true,
        filterParams: {
            filterOptions: ["equals"],
            defaultOption: "equals",
            debounceMs: 500,
            trimInput: true,
            buttons: ["reset", "apply"],
            suppressAndOrCondition: true,
        },
    },
    {
        colId: 'uploader',
        headerName: "Uploader Name",
        sortable: true,
        sortingOrder: ["asc", "desc"],
        flex: 2,
        filter: 'agTextColumnFilter',
        resizable: true,
        valueGetter: function getName(params) {
            const firstName = params.data.uploader.firstName || ''
            const lastName = params.data.uploader.lastName || ''
            return [firstName, lastName].join((firstName && lastName) ? ' ' : '')
        },
        filterParams: {
            filterOptions: ["equals"],
            defaultOption: "equals",
            debounceMs: 500,
            trimInput: true,
            buttons: ["reset", "apply"],
            suppressAndOrCondition: true,
        },
    }
]

export const deliveryDetailsColumnDefs: ColDef[] = [
  {
    field: "type",
    headerName: "Type",
  },
  {
    field: "filename",
    headerName: "File Name",
  },
  {
    field: "date",
    headerName: "Date",
    valueFormatter: dateSimpleFormatter,
  },
];
