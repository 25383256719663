import moment from "moment";

const PENDING_UPLOAD = 'pending upload';
const DELIVERY_COMPLETE = 'delivery complete';
const QC_FAILED = 'qc failed'

export const fixStateFilter = (stateFilter: any) => {
    let filterText = stateFilter.filter;
    if (filterText === PENDING_UPLOAD) {
        filterText = filterText.replace(PENDING_UPLOAD, "finalized");
    } else if (filterText === DELIVERY_COMPLETE) {
        filterText = filterText.replace(DELIVERY_COMPLETE, "delivered");
    } else if (filterText === QC_FAILED) {
        filterText = filterText.replace(QC_FAILED, "qc_failed");
    }
    stateFilter.filter = filterText;
};

export const fixDateFilter = (dateFilter: any): void => {
  const addDay = (dateStr: string): string => moment(dateStr, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');
  if (dateFilter.type === 'greaterThan') {
    dateFilter.dateFrom = addDay(dateFilter.dateFrom);
  } else if (dateFilter.type === 'inRange') {
    dateFilter.dateTo = addDay(dateFilter.dateTo);
  }
}