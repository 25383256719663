import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist()

export const navCloseOnSelectAtom = atom<boolean>({
  key: 'navbarlocal/navCloseOnSelect',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const navBarToggleAtom = atom<boolean>({
  key: 'navbarlocal/navBarOpened',
  default: false
});

export const previousNavBarStateAtom = atom<boolean>({
  key: 'navbarlocal/previousNavBarState',
  default: false
});

export const navBarItemActiveAtom = atom<string>({
  key: 'navbarlocal/navBarItemActive',
  default: '/'
});

export const navBarItemHoveringAtom = atom<string>({
  key: 'navbarlocal/navBarItemHovering',
  default: ''
});
