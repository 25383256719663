import { ReactNode } from "react";
import {
  Box,
  Breadcrumbs,
  Center,
  Container,
  Group,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { ChevronRight } from "tabler-icons-react";
import useStyles from "../../theme/globalStyles";
import { useNavigate } from "react-router";

import { useTID } from "../../hooks/useTestId";

interface ICrumb {
  title: string;
  path: string;
}

interface IHeaderbar {
  crumbs: ICrumb[];
  rightSection: ReactNode;
  testid: string;
}

export const Headerbar = (props: IHeaderbar) => {
  const { classes } = useStyles();
  const TID = useTID("Headerbar", props.testid);
  let navigate = useNavigate();
  const theme = useMantineTheme();

  const crumbs = props.crumbs.map((item, index) => (
    <Text
      onClick={() => {
        navigate(item.path);
      }}
      key={index}
      mt={2}
      size="sm"
      color={
        theme.colorScheme === "dark"
          ? theme.colors.blue[5]
          : theme.colors.blue[8]
      }
      style={{ cursor: "pointer" }}
    >
      {item.title}
    </Text>
  ));

  return (
    <>
      <Center>
        <Container
          {...TID}
          className={classes.baseBox}
          pl={12}
          size={1900}
          style={{ width: "100%", marginBottom: 24 }}
        >
          <Breadcrumbs
            separator={
              <Box mx={-8}>
                <ChevronRight strokeWidth={1.5} size={18} />
              </Box>
            }
          >
            {crumbs}
          </Breadcrumbs>
          <Group>{props.rightSection}</Group>
        </Container>
      </Center>
    </>
  );
};
