import { Badge } from "../../../components-ui";
import { useRecoilValue } from "recoil";
import { FileLike, Icon as TablerIcon } from "tabler-icons-react";
import { filesAcceptedAtom } from "../../../recoil/bulkupload/atoms";
import useStyles from "../BulkUploadStyles";
import { useMantineTheme } from "@mantine/core";

export const FileLikeIcon = ({
  ...props
}: React.ComponentProps<TablerIcon>) => {
  const filesAccepted = useRecoilValue(filesAcceptedAtom);
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const likeColor =
    theme.colorScheme === "dark"
      ? theme.colors.green[7]
      : theme.colors.green[6];

  if (filesAccepted.length > 0) {
    const numberOfFiles = filesAccepted
      .map((x) => {
        return [...x.files, ...x.submasterFiles];
      })
      .flat();

    return (
      <>
        <FileLike strokeWidth={1} color={likeColor} size={80} {...props} />
        <Badge
          variant="filled"
          color="green"
          size="sm"
          className={classes.BadgePlacer}
          testid="fileLikeIcon"
        >
          {numberOfFiles.length}
        </Badge>
      </>
    );
  }
  return <></>;
};

export default FileLikeIcon;
