import { Button } from "../../components-ui";
import { useEffect, useRef, useState } from "react";
import {
  IServerSideGetRowsRequest,
} from "ag-grid-community";
import { DeliveryService } from "../../services/delivery.service";
import { CSVLink } from "react-csv";
import moment from "moment";
import { Download } from "tabler-icons-react";
import { IDelivery } from "../../models/myUploads";

export interface IDownloadDeliveries {
  request: IServerSideGetRowsRequest,
  totalRows: number
}

export default function DownloadDeliveries({model, admin}: {model: IDownloadDeliveries, admin: boolean}) {
  const [totalData, setTotalData] = useState<string[][]>([]);
  const csvLink = useRef<any>();
  const header = [['Name', 'Delivery ID', 'Last Status', 'Date/Time', 'Uploader']];

  useEffect(() => {
    if (totalData.length) {
      csvLink.current.link.click();
    }
  }, [totalData]);

  const downloadData = async () => {
    const response = await DeliveryService.getDeliveriesWithParams(
      {...model.request, startRow: 0, endRow: model.totalRows}, admin
    );

    const data = header.concat(response.data.map((delivery: IDelivery) => {
      return [delivery.name, delivery.cid, delivery.state, moment(delivery.date).format("MM/DD/YYYY HH:mm"), delivery.uploader.email || ''];
    }));

    setTotalData(data);
  };

  return (
    <>
      <CSVLink
          aria-label="csv-link"
          data={totalData}
          filename={admin ? "all-uploads.csv" : "my-uploads.csv"}
          className="hidden"
          ref={csvLink}
          target="_blank" 
       />
      <Button testid="download-btn" onClick={downloadData} leftIcon={<Download />} size="xs" mb={2}>
        Download Data
      </Button>
    </>
  );
}
