import { Aside as MAside, AsideProps } from "@mantine/core";
import { useTID } from "../../hooks/useTestId";

interface IAsideProps extends AsideProps {
  testid: string;
}

export const Aside = (props: IAsideProps) => {
  const TID = useTID("Aside", props.testid);
  return (
    <MAside {...TID} {...props}>
      {props.children}
    </MAside>
  );
};
