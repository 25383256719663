import { Footer as MantineFooter, Image, Text } from "@mantine/core";
import useStyles from "./FooterStyles";
import { useTID } from "../../hooks/useTestId";
import packagejson from "../../../package.json";

interface IFooterProps {
  testid: string;
}

export const Footer = (props: IFooterProps) => {
  const { classes } = useStyles();
  const TID = useTID("Footer", props.testid);

  return (
    <MantineFooter className={classes.footer} height={50} p={8} {...TID}>
      <Image src="/foundry-wb-logo-footer.svg" width={100} pt={3} pl={15} />
      <Text size={10} pr={15} data-testid=":Text-Footer">
        Warner Brothers Discovery, Inc. Promo Exchange: Delivery Manager{" "}
        {packagejson.version}
        <br />
        Copyright © 2023 Warner Brothers Discovery, Inc. All rights reserved.
      </Text>
    </MantineFooter>
  );
};
