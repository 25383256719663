import { Accordion } from "@mantine/core";
import { useState } from "react";
import IdNotExistsHelp from "../../components/Help/IdNotExists";
import IdOnlyOneVideoHelp from "../../components/Help/IdOnlyOneVideo";
import IdRfdHelp from "../../components/Help/IdRfd";
import FileFormatNotSupportedHelp from "../../components/Help/FileFormatNotSupported";
import NamingConventionHelp from "../../components/Help/NamingConvention";
import PolicyCheckHelp from "../../components/Help/PolicyCheck";
import { ValidationErrorType } from "../../helpers/filesValidation/validationHelper";

export default function FileRejectionContainer({
  item,
}: {
  item?: string | null;
}) {
  const [value, setValue] = useState<string | null>(
    item ? item : "IdNotFoundInFile"
  );

  return (
    <Accordion
      variant="contained"
      chevronPosition="left"
      value={value}
      onChange={setValue}
    >
      <Accordion.Item
        value="IdNotFoundInFile"
        data-testid={":CidNotFoundInFile"}
      >
        <Accordion.Control>
          {ValidationErrorType.IdNotFoundInFile}
        </Accordion.Control>
        <Accordion.Panel>
          <NamingConventionHelp />
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="IdNotExists" data-testid={":CidNotExists"}>
        <Accordion.Control>{ValidationErrorType.IdNotExist}</Accordion.Control>
        <Accordion.Panel>
          <IdNotExistsHelp />
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item
        value="FileFormatNotSupported"
        data-testid={":FileFormatNotSupported"}
      >
        <Accordion.Control>
          {ValidationErrorType.FileFormatNotSupported}
        </Accordion.Control>
        <Accordion.Panel>
          <FileFormatNotSupportedHelp />
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item
        value="IdOnlyOneVideoMaster"
        data-testid={":CidOnlyOneVideoMaster"}
      >
        <Accordion.Control>
          {ValidationErrorType.IdOnlyOneVideoMaster}
        </Accordion.Control>
        <Accordion.Panel>
          <IdOnlyOneVideoHelp master={true} />
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item
        value="IdOnlyOneVideoSubmaster"
        data-testid={":CidOnlyOneVideoSubmaster"}
      >
        <Accordion.Control>
          {ValidationErrorType.IdOnlyOneVideoSubmaster}
        </Accordion.Control>
        <Accordion.Panel>
          <IdOnlyOneVideoHelp master={false} />
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="IdRfd" data-testid={":CidRfd"}>
        <Accordion.Control>{ValidationErrorType.IdRfd}</Accordion.Control>
        <Accordion.Panel>
          <IdRfdHelp />
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="PolicyCheck" data-testid={":PolicyCheck"}>
        <Accordion.Control>{ValidationErrorType.PolicyCheck}</Accordion.Control>
        <Accordion.Panel>
          <PolicyCheckHelp />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}
