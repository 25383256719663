import moment from "moment";

const dateFormat = "MM/DD/YYYY HH:mm";

export const dateSimpleFormatter = (params: any) => {
  return moment(params.data.date).format(dateFormat);
};

export const nameFormatter = (params: any) => {
    const firstName = params.data.uploader.firstName || ''
    const lastName = params.data.uploader.lastName || ''
    return [firstName, lastName].join((firstName && lastName) ? ' ' : '')
}