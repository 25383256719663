import { Group } from "@mantine/core";
import { SortAscending, SortDescending } from "tabler-icons-react";
import { Tooltip } from "../../../components-ui";

export const SortFilesButton = ({
  ascending,
  color,
  acceptedColumn,
  toggleOrder,
}: {
  ascending: boolean;
  color: string;
  acceptedColumn: boolean;
  toggleOrder: () => void;
}) => {
  return (
    <Tooltip
      label={ascending ? "Ascending" : "Descending"}
      color={color}
      position="top"
      withArrow
      key="Order-Button"
    >
      <Group
        data-testid={
          acceptedColumn
            ? ":Icon-OrderAcceptedColumn"
            : ":Icon-OrderRejectedColumn"
        }
        style={{ cursor: "pointer" }}
        onClick={() => {
          toggleOrder();
        }}
      >
        {ascending ? (
          <SortAscending size={20} color={color} />
        ) : (
          <SortDescending size={20} color={color} />
        )}
      </Group>
    </Tooltip>
  );
};
