import { FC } from "react";
import { Alien, Check, Plus, Search, TrashX, X } from "tabler-icons-react";
import { ButtonBaseName } from "../Button";

export interface ButtonIconProps {
  icon: ButtonBaseName;
  size?: number;
  strokeWidth?: number;
  color?: string;
}

export type ButtonIconType = FC<ButtonIconProps>;

export const ButtonIcon = (props: ButtonIconProps) => {
  const defaultValues = {
    size: 20,
  };
  const componentIconProps = Object.assign({}, defaultValues, props);

  const icons: Record<string, React.FC> = {
    button: Alien,
    accept: Check,
    add: Plus,
    cancel: X,
    remove: TrashX,
    search: Search,
  };

  const Icon: React.FC<ButtonIconProps> = icons[props.icon];

  return <Icon {...componentIconProps} />;
};

export default ButtonIconType;
