import { TitleIdentifier } from "../helpers/upload/deliveryHelperModels";

// @types.metadata.ts
export const MapPromoMetadata = (
    metadata: TitlePromoMetadataResult,
    requestIdentifier: TitleIdentifier
): IPromoMetadata => {
  const getDuration = (approximateRuntime: string): string => {
    if (!approximateRuntime) {
      return '';
    }

    let duration = approximateRuntime.replace("PT", "").replace("S", "");

    if (duration.indexOf('M') > -1) {
      const minutes = parseInt(duration.substring(0, duration.indexOf('M')));
      const seconds = parseInt(duration.substring(duration.indexOf('M') + 1, duration.length));

      duration = ((minutes * 60) + seconds).toString();
    }

    return duration;
  };

  return {
    id: requestIdentifier.id,
    name: metadata.promo ? metadata.promo.title : "",
    contentType: metadata.promo ? metadata.promo.promoType : "",
    isciCode: "",
    campaign: metadata.promo ? metadata.promo.campaignInfo?.campaignName : "",
    project: "",
    description: metadata.promo ? metadata.promo.description : "",
    versionName: metadata.editTitle,
    flightDateBegin: metadata.availabilities
      ? metadata.availabilities[0]?.starts
      : "",
    flightDateEnd: metadata.availabilities
      ? metadata.availabilities[0]?.ends
      : "",
    network: metadata.promo ? metadata.promo.promotedNetwork : "",
    durationInSeconds: getDuration(metadata.approximateRuntime),
    resolution: "",
    readyForDistribution: metadata.readyForDistribution ? "Yes" : "No",
  };
};

export interface AlternateId {
  id: string;
  type: string;
}

export interface Availability {
  starts: string;
  ends: string;
  channels: string[];
}

export interface CampaignInfo {
  campaignId: string;
  campaignName: string;
  subCampaignId: string;
  subCampaignName: string;
}

export interface Promo {
  id: string;
  namespace: string;
  promoType: string;
  promoTypeCode: string;
  title: string;
  promotedProducts: string[];
  restrictions: string[];
  campaignInfo: CampaignInfo;
  promotedNetwork: string;
  description: string;
}

export interface Rating {
  system: string;
  rating: string;
  reasons: string[];
}

export interface Title {
  language: string;
  short: string;
  full: string;
}

export interface PromoMetadataResult {
  requestIdentifier: {
    id: string;
    namespace: string;
  };
  title: TitlePromoMetadataResult;
}

export interface TitlePromoMetadataResult {
  alternateIds: AlternateId[];
  approximateRuntime: string;
  availabilities: Availability[];
  brands: string[];
  contentAdvisories: string[];
  createdDate: string;
  editTitle: string;
  genres: string[];
  id: string;
  keywords: string[];
  modifiedDate: string;
  namespace: string;
  originalLanguage: string;
  promo: Promo;
  ratings: Rating[];
  readyForDistribution: boolean;
  sourceType: string;
  status: string;
  synopses: string[];
  titles: Title[];
  usageTags: string[];
}

export interface IPromoMetadata {
  id: string;
  name: string;
  contentType: string;
  isciCode: string;
  campaign: string;
  project: string;
  description: string;
  versionName: string;
  flightDateBegin: string;
  flightDateEnd: string;
  network: string;
  durationInSeconds: string;
  resolution: string;
  readyForDistribution: string;
}

export interface IPromoMetadataIds {
  ids: string[];
  idsRfd: string[];
  bulkMetadata: IPromoMetadata[];
}
