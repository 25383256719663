import { atom } from "recoil";

export interface iItemType {
  id: string;
  label: string;
  value: string;
}

export const listSortableListAtom = atom<iItemType[]>({
  key: "listSortable/list",
  default: [] as iItemType[],
});

export const draggableAtom = atom<boolean>({
  key: "listSortable/draggable",
  default: false,
});
