import { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import { Center, Loader } from "@mantine/core";
import { useRecoilState } from "recoil";
import {
  OktaAuth,
  TokenManagerInterface,
  AccessToken,
  IDToken,
  UserClaims,
} from "@okta/okta-auth-js";
import { userDataAtom } from "./state";

export const getUser = async (authClient: OktaAuth) => {
  const tokenManager: TokenManagerInterface = authClient.tokenManager;
  const accessToken: AccessToken = (await tokenManager.get(
    "accessToken"
  )) as AccessToken;
  const idToken: IDToken = (await tokenManager.get("idToken")) as IDToken;
  const userInfo: UserClaims = await authClient.token.getUserInfo(
    accessToken,
    idToken
  );
  return userInfo;
};

export const SecureRoute = () => {
  const { oktaAuth, authState } = useOktaAuth();

  const [userData, setUserData] = useRecoilState(userDataAtom);

  useEffect(() => {
    if (!authState) {
      return;
    } else if (
      authState.isAuthenticated &&
      !Object.values(userData).every((item) => item !== undefined)
    ) {
      (async () => {
        const userInfo = await getUser(oktaAuth);
        if (
          userData.name !== userInfo.given_name ||
          userData.surName !== userInfo.family_name ||
          userData.email !== userInfo.email
        ) {
          setUserData({
            name: userInfo.given_name,
            surName: userInfo.family_name,
            email: userInfo.email,
          });
        }
      })();
    }

    if (!authState || !authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return <Outlet />;
};
