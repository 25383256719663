import { Divider, Group, Radio } from "@mantine/core";
import { Tooltip } from "../../../components-ui";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  languagesAtom,
    selectedLanguageAtom
} from "../../../recoil/bulkupload/atoms";
import { useThemeColors } from "../../../hooks/useThemeColors";

export const LanguageBox = () => {
  const languages = useRecoilValue(languagesAtom);
  const [selectedLanguage, setSelectedLanguage] = useRecoilState(
    selectedLanguageAtom
  );
  const { dividerColor } = useThemeColors();

  return languages.length > 0 ? (
    <>
      <Divider my="sm" color={dividerColor} />
      <Radio.Group
        value={selectedLanguage}
        onChange={setSelectedLanguage}
        label="Select your Promo Language"
        description="This is required to enable Upload"
        spacing="xs"
        size="sm"
        required
      >
        {languages.map((language) => {
          return (
            <Tooltip
              label={language.description}
              color="blue"
              position="top"
              withArrow
              key={language.code}
            >
              <Group>
                <Radio
                  key={language.code}
                  value={language.code}
                  data-testid={":Radio-Language-" + language.code}
                  label={language.label}
                />
              </Group>
            </Tooltip>
          );
        })}
      </Radio.Group>
    </>
  ) : (
    <></>
  );
};
