import { BulkInfoLoader } from "./LoaderMessage/BulkInfoLoader";
import { BulkInfoRemoveFile } from "./LoaderMessage/BulkInfoRemoveFile";

export const LoaderOrIcon = ({
  pending,
  completeFileName,
}: {
  pending: boolean;
  completeFileName: string;
}) => {
  return pending ? (
    BulkInfoLoader()
  ) : (
    <BulkInfoRemoveFile
      completeFileName={completeFileName}
      rejectedColumn={false}
    ></BulkInfoRemoveFile>
  );
};
