import { IGridComponent, IWidgets, ILayout } from './models';

// Dashboard/state/actions.ts
export enum ActionType {
  AddWidgetToGrid,
  SetBreakpoint,
  SetGridComponent,
  SetLayout,
  SetLayoutLocked,
  SetWidgets,
  RemoveWidgetFromGrid,
  SetOnLoad
}

interface IAddWidgetToGrid {
  type: ActionType.AddWidgetToGrid;
  payload: string;
}

interface ISetBreakpoint {
  type: ActionType.SetBreakpoint;
  payload: string;
}

interface ISetGridComponent {
  type: ActionType.SetGridComponent;
  payload: IGridComponent;
}

interface ISetLayout {
  type: ActionType.SetLayout;
  payload: ILayout;
}

interface ISetLayoutLocked {
  type: ActionType.SetLayoutLocked;
  payload: boolean;
}

interface ISetWidgets {
  type: ActionType.SetWidgets;
  payload: IWidgets;
}

interface IRemoveWidgetFromGrid {
  type: ActionType.RemoveWidgetFromGrid;
  payload: string;
}

interface ISetOnLoad {
  type: ActionType.SetOnLoad;
  payload: boolean;
}

export type DashboardActions = IAddWidgetToGrid | ISetLayout | ISetBreakpoint | ISetGridComponent | ISetLayoutLocked | ISetWidgets | IRemoveWidgetFromGrid | ISetOnLoad;

export interface IDashboardActions {
  AddWidgetToGrid: (value: string) => void;
  SetBreakpoint: (value: string) => void;
  SetGridComponent: (value: { key: string; component: React.ReactNode }) => void;
  SetLayout: (value: ILayout) => void;
  SetLayoutLocked: (value: boolean) => void;
  SetOnLoad: (value: boolean) => void;
  SetWidgets: (value: IWidgets) => void;
  RemoveWidgetFromGrid: (value: string) => void;
}
