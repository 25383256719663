import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

interface IUserData {
  name?: string;
  surName?: string;
  email?: string;
}

const initialUserData: IUserData = {
  name: undefined,
  surName: undefined,
  email: undefined,
};

export enum ColorTheme {
  light = "light",
  dark = "dark",
}

export const colorThemeAtom = atom<string>({
  key: "app/colorTheme",
  default: ColorTheme.light,
});

export const layoutEditableAtom = atom<boolean>({
  key: "app/layoutEditable",
  default: false,
});

export const moduleAtom = atom<string>({
  key: "app/module",
  default: "Demo Module",
});

export const userDataAtom = atom<IUserData>({
  key: "app/userData",
  default: initialUserData,
});

export const isConfigLoadingAtom = atom<boolean>({
  key: "app/configLoading",
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const panelStateAtom = atom<string>({
  key: "app/panel",
  default: "CLOSED",
  effects_UNSTABLE: [persistAtom],
});

export const appLoadedAtom = atom<boolean>({
  key: "app/loaded",
  default: false
})
