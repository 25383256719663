import { createStyles } from "@mantine/core";
import { useThemeColors } from "../../hooks/useThemeColors";

export const HEADER_HEIGHT = 60;
export const NAV_SLIM_WIDTH = 48;
export const HEADER_BREAKPOINT = 860;
export const HEADER_WIDTH = 260;

export default createStyles((theme) => {
  const { headerFooterBackgroundColor, headerFooterTextColor } =
    useThemeColors();

  return {
    header: {
      top: 0,
      left: 0,
      right: 0,
      paddingLeft: 16,
      height: HEADER_HEIGHT,
      zIndex: 6,
      position: "fixed",
      backgroundColor: headerFooterBackgroundColor,
      color: headerFooterTextColor,
      borderBottom: `1px solid ${headerFooterBackgroundColor}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    leftSection: {
      [`@media (max-width: ${HEADER_BREAKPOINT}px)`]: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 16,
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
        pointerEvents: "none",
      },
    },

    rightSection: {
      flex: 1,
      marginLeft: theme.spacing.xl,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      paddingRight: theme.spacing.md,
    },
  };
});
