import packagejson from "../../../package.json";
import mantinejson from "../../../node_modules/@mantine/core/package.json";
import reactjson from "../../../node_modules/react/package.json";
import recoiljson from "../../../node_modules/recoil/package.json";
import aggridjson from "../../../node_modules/ag-grid-community/package.json";
import { Anchor, Text, useMantineTheme } from "@mantine/core";
import { Code } from "../";

export const Versions = () => {
  const theme = useMantineTheme();

  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <td>
            <Text
              size="sm"
              style={{
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.gray[4]
                    : theme.colors.gray[8],
              }}
            >
              <b>Versions</b>
            </Text>
          </td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Anchor
              href="https://github.com/wbd-msc/deliveryportal-ui"
              target={"_blank"}
              size="sm"
            >
              Promo Exchange
            </Anchor>
          </td>
          <td>
            <Code
              testid="HeaderVersionsPackage"
              style={{
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.blue[9]
                    : theme.colors.gray[9],
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.gray[4]
                    : theme.colors.blue[0],
              }}
            >
              {packagejson.version}
            </Code>
          </td>
        </tr>
        <tr>
          <td>
            <Anchor href="https://mantine.dev" target={"_blank"} size="sm">
              Mantine
            </Anchor>
          </td>
          <td>
            <Code
              testid="HeaderVersionsMantine"
              style={{
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.blue[9]
                    : theme.colors.gray[9],
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.gray[4]
                    : theme.colors.blue[0],
              }}
            >
              {mantinejson.version}
            </Code>
          </td>
        </tr>
        <tr>
          <td>
            <Anchor href="https://reactjs.org" target={"_blank"} size="sm">
              React
            </Anchor>
          </td>
          <td>
            <Code
              testid="HeaderVersionsReact"
              style={{
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.blue[9]
                    : theme.colors.gray[9],
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.gray[4]
                    : theme.colors.blue[0],
              }}
            >
              {reactjson.version}
            </Code>
          </td>
        </tr>
        <tr>
          <td>
            <Anchor href="https://recoiljs.org" target={"_blank"} size="sm">
              Recoil
            </Anchor>
          </td>
          <td>
            <Code
              testid="HeaderVersionsRecoil"
              style={{
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.blue[9]
                    : theme.colors.gray[9],
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.gray[4]
                    : theme.colors.blue[0],
              }}
            >
              {recoiljson.version}
            </Code>
          </td>
        </tr>
        <tr>
          <td>
            <Anchor
              href="https://www.ag-grid.com/react-data-grid/"
              target={"_blank"}
              size="sm"
            >
              Ag-Grid
            </Anchor>
          </td>
          <td>
            <Code
              testid="HeaderVersionsAgGrid"
              style={{
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.blue[9]
                    : theme.colors.gray[9],
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.gray[4]
                    : theme.colors.blue[0],
              }}
            >
              {aggridjson.version}
            </Code>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
